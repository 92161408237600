/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ContactDoc } from '../../schema/3/schema-ops';
import { DialogSendSmsComponent } from './dialog-send-sms.component';

@Injectable({
  providedIn: 'root'
})
export class DialogSendSmsService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialog(contact: Partial<ContactDoc>) {
    let dialogRef: MatDialogRef<DialogSendSmsComponent>;
    dialogRef = this.dialog.open(DialogSendSmsComponent, {
      data: {
        contact
      }
    });

    return dialogRef;
  }
}
