/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class DebugAuthService {

  constructor() { }

  logUserMetadata(metadata: firebase.auth.UserMetadata) {
    console.log(`creationTime: ${metadata.creationTime}`);
    console.log(`lastSignInTime: ${metadata.lastSignInTime}`);
  }

  logUser(user: firebase.User) {
    console.log('---- User');
    console.log(`uid: ${user.uid}`);
    console.log(`isAnonymous: ${user.isAnonymous}`);
    console.log(`email: ${user.email}`);
    console.log(`emailVerified: ${user.emailVerified}`);
    console.log(`refreshToken: ${user.refreshToken}`);
    console.log(`providerId: ${user.providerId}`);
    this.logUserMetadata(user.metadata);
    console.log('providerData:↴');
    user.providerData.forEach((userInfo: firebase.UserInfo, i) => {
      console.log(`[${i}]UserInfo.uid: ${userInfo.uid}`);
      console.log(`[${i}]UserInfo.email: ${userInfo.email}`);
      console.log(`[${i}]UserInfo.providerId: ${userInfo.providerId}`);
      console.log(`[${i}]UserInfo.displayName: ${userInfo.displayName}`);
    });
  }

  logAdditionalUserInfo(additionalUserInfo: firebase.auth.AdditionalUserInfo) {
    console.log(`AdditionalUserInfo.isNewUser: ${additionalUserInfo.isNewUser}`);
    console.log(`AdditionalUserInfo.username: ${additionalUserInfo.username}`);
    console.log(`AdditionalUserInfo.providerId: ${additionalUserInfo.providerId}`);
    console.log(`AdditionalUserInfo.profile: ${additionalUserInfo.profile}`);
  }

  logAuthCredential(logAuthCredential: firebase.auth.AuthCredential) {
    console.log(`AuthCredential.providerId: ${logAuthCredential.providerId}`);
    console.log(`AuthCredential.signInMethod: ${logAuthCredential.signInMethod}`);
  }

  logUserCredential(userCredential: firebase.auth.UserCredential) {
    console.log('---- Start of UserCredential');
    console.log(`operationType: ${userCredential.operationType}`);
    this.logAdditionalUserInfo(userCredential.additionalUserInfo);
    if (userCredential.credential) {
      this.logAuthCredential(userCredential.credential);
    }
    this.logUser(userCredential.user);
    console.log('---- End of UserCredential');
  }
}
