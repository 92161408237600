<div class="order-container-background" [ngClass]="cssClass" [class.shortHeight]="false">
    <!-- 제목 배경 용도로 자리만 잡는다.  -->
    <div></div>
  </div>


  <div class="order-container" [ngClass]="cssClass">
    <h3>
      <span class="date"><span *ngIf="!isTodayOrder">{{ order.orderDate | date:'M-d':'+0900' }} ({{ weekdayKR(order.orderDate) }}) </span>{{ order.orderDate | date:'HH:mm':'+0900' }}</span>

      <span>{{ order.address_dong }} {{ order.address_jibun }}</span>

      <span class="status">{{ statusString }}</span>
    </h3>

    <table class="delivery">
      <tr >
        <td>{{ order.shopName }} / {{ trimOrganization(roomService.rooms[order.room].name) }}</td>
      </tr>
    </table>

    <div *ngIf="order.deliveryType === 'TAKEOUT'" class="tags">
      <span>포장</span>
    </div>

    <!-- 연락처, 지불방법 등 -->
    <app-order-contact [order]="order"></app-order-contact>

    <div class="dl">
      <div class="dt" *ngIf="order.simpleNo">간단 번호: {{ order.simpleNo }}</div>
      <div class="dt" *ngIf="order.orderMsg && order.orderMsg.length > 0">요청 사항 :</div>
      <div class="dd" *ngIf="order.orderMsg && order.orderMsg.length > 0"><span class="copyable" (click)="copyToClipboard($event)">{{ order.orderMsg }}</span></div>
      <div class="dt" *ngIf="order.contextStatusCode >= 15 && order.posRequestedPickupMinutes">POS 요청픽업시간: <span style="font-weight: normal; font-size:16px">{{ order.posRequestedPickupMinutes }}분</span></div>
      <div class="dt" *ngIf="order.contextStatusCode >= 15 && order.posDeliveryMinutes">POS 예상배달시간: <span style="font-weight: normal; font-size:16px">{{ order.posDeliveryMinutes }}분</span></div>
      <div class="dt" *ngIf="order.contextStatusCode >= 15 && (!order.posRequestedPickupMinutes && order.cookMinutes)">조리 시간: <span style="font-weight: normal; font-size:16px">{{ order.cookMinutes }}분</span></div>
      <div class="dt" *ngIf="order.contextStatusCode >= 20 && order.deliveryMinutes">예상 배달 시간: <span style="font-weight: normal; font-size:16px">{{ order.deliveryMinutes }}분</span></div>
      <div class="dt">주문 채널: <span style="font-weight: normal; font-size:16px">{{ channel }}</span></div>
      <div *ngIf="order.cancelReason" class="dt">취소 사유: <span style="font-weight: normal; font-size:16px">{{ order.cancelReason }}</span></div>
    </div>

    <!-- menu -->
    <app-menu [order]="order"></app-menu>

    <div class="order-actions">
      <!-- CEO에서 처리할 경우에는 여기에서는 배달의 경우에만 처리하면 된다. -->
      <!-- 결국에는 같은 값을 변경하는 것이지만 배달의 경우에는 '예상 배달 시간', 배민1의 경우에는 '조리 시간', 포장의 경우에는 '예상 픽업 시간'이라고 표시한다. -->
      <div *ngIf="order.contextStatusCode >= 10 && order.contextStatusCode < 20" class="deliveryExpTime">{{ deliveryMinutesTitle }}<span *ngIf="!order.posRequestedPickupMinutes && order.cookMinutes"> (조리시간 : {{ order.cookMinutes }}분)</span></div>
      <div *ngIf="order.contextStatusCode >= 10 && order.contextStatusCode < 20">
        <mat-radio-group [value]="deliveryMinutes" (change)="onChangeDeliveryExpTime($event)">
          <mat-radio-button *ngFor="let option of deliveryMinutesOptions" [value]="option" [checked]="option === deliveryMinutes">{{ option + '분' }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="buttons">
        <button *ngIf="isDialog" mat-raised-button cdkFocusInitial (click)="onOk()">닫기</button>

        <!-- 취소이면서 아직 확인되지 않은 주문에서만 나타나는 버튼-->
        <button *ngIf="order.contextStatusCode == 80 && !order.cancelConfirmed"
          mat-raised-button color="accent"
          (click)="onAction('CANCEL_CONFIRMED')">취소 사유 확인</button>

        <button *ngIf="order.contextStatusCode >= 10 && order.contextStatusCode < 20" mat-raised-button class="ACCEPTED" (click)="onAction('ACCEPT')" [disabled]="buttonPressed.ACCEPT" color="accent">주문 접수</button>
        <!-- <button                                      mat-raised-button class="ACCEPTED" (click)="onAction('ACCEPT')" [disabled]="buttonPressed.ACCEPT" color="accent">주문 접수</button> -->
        <button *ngIf="(order.deliveryType === 'DELIVERY' || order.deliveryType === 'TAKEOUT') && order.contextStatusCode >= 20 && order.contextStatusCode < 70" mat-raised-button class="COMPLETED" (click)="onAction('COMPLETE')" [disabled]="buttonPressed.COMPLETE" color="accent">배달 완료</button>
        <!-- <button                                                                 mat-raised-button class="COMPLETED" (click)="onAction('COMPLETE')" [disabled]="buttonPressed.COMPLETE" color="accent">배달 완료</button> -->
        <button *ngIf="(order.deliveryType === 'DELIVERY' || order.deliveryType === 'TAKEOUT') && order.contextStatusCode < 70" mat-raised-button (click)="onAction('CANCEL')" [disabled]="buttonPressed.CANCEL" class="cancel" color="accent">주문 {{ order.orderStatusCode === 10 ? '거부' : '취소' }}...</button>
      </div>
      <div class="buttons">
        <button *ngIf="userService.user.role === 'admin'" mat-raised-button (click)="onAction('DELETE')" [disabled]="buttonPressed.DELETE" class="delete" color="accent">주문 삭제</button>
      </div>
    </div>
    <div class="orderId">{{ order.orderNo }}</div>
  </div>

