/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { map, timeout, take, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { AngularFirestore } from '@angular/fire/firestore';

import { WHERE } from '../../schema/1/schema-common';
import { BaeminCancelReasonCode } from '../../schema/1/schema-baemin-common';
import {
  Message,
  MessagePeer,
  MessageBodyMap,
  RequestMessageBodyCreateVroongDelivery,
} from '../../schema/2/schema-message';

import { instanceId } from '../1/common';
import { UtilService } from '../1/util.service';
import { UserService } from '../2/user.service';
import { LogService } from '../3/log.service';
import { FirebaseManagerService } from '../4/firebase-manager.service';

const collectionPath = 'message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(
    private db: AngularFirestore,
    private utilService: UtilService,
    private userService: UserService,
    private logService: LogService,
    private firebaseManager: FirebaseManagerService,
  ) {
    console.log(`message instance ID = ${instanceId}`);
  }

  /**
   * 지정 시각 이후의 메시지 전체를 받는다.
   */
  public observeMessage() {
    const now = new Date();
    // const from = now.getTime() - 10 * 24 * 3600 * 1000;

    console.log(`${this.constructor.name}::observeMessage from ${now}`);
    const organization = this.userService.organization;
    const wheres: WHERE[] = [
      ['organization', '==', organization],
      ['channel', '==', 'message'],
      ['type', '==', 'response'],
      ['to.class', '==', 'omc'],
      ['to.instanceNo', '==', instanceId],
      ['_timeCreate', '>', now]
    ];

    this.firebaseManager.makeDocChangesObservable<Message<'response', any>>(collectionPath, wheres, { changeTypes: ['added'] }).subscribe(docChanges => {
      for (const docChange of docChanges) {
        console.log(`message response for '${docChange.doc.name}' received`);
        this.handleResponse(docChange.doc);
      }
    });
  }

  /**
   * requestId로 요청한 메시지의 응답을 지정 시간까지 기다린다.
   *
   * @param requestId request document의 ID
   * @param msec 밀리초
   */
  private observeResponseWithTimeout(requestId: string, msec = 10000) {
    // 복합 색인을 피하기 위해서 requestId에 대해서만 조건을 주었다.
    const wheres: WHERE[] = [['requestId', '==', requestId]];

    return this.firebaseManager.makeDocChangesObservable<Message<'response', any>>(collectionPath, wheres, { changeTypes: ['added'] }).pipe(
      map(docChanges => docChanges[0].doc), // 요청에 대해서 응답은 하나가 정상이다.
      // promise를 종결시키기 위해서 필요하다.
      take(1),
      // rxjs v7의 timeout은 with 인자를 사용하면 catchError를 따로 사용하지 않아도 된다.
      timeout(msec),
      // timeout error로 가정한다.
      catchError(error => {
        // node 버전에서는 false를 응답한다.
        // return of(false) as Observable<false>;

        // timeout인 경우에는 다음의 형식을 리턴
        // {
        //   message: "Timeout has occurred"
        //   name: "TimeoutError"
        //   stack: "
        // }
        if (error.name === 'TimeoutError') {
          error.message = `응답 대기 시간이 ${msec / 1000}초를 초과했습니다.`;
          console.log(`observeResponseWithTimeout(${requestId}) Timeout`);
        } else {
          console.dir(error);
          this.logService.withToastrError(`observeResponseWithTimeout에서 에러 발생 : ${error}`);
        }
        throw error;
      })
    ).toPromise();
  }

  private handleResponse(message: Message<'response', any>) {
    let toastrMessage = '';
    switch (message.name) {
      // 대화 상자에서 결과를 바로 확인할 수 있고 에러는 별도로 표시하므로 toastr를 보여주지 않는다.
      case 'getBaeminBlock':
      case 'postBaeminBlock':
      case 'postBaeminUnblock':
      case 'estimateVroongDelivery':
      case 'saveBaeminDeliveryRegion':
      case 'loadBaeminDeliveryRegion':
      case 'reload':
        return;

      case 'requestBaeminCertNo':
        toastrMessage = '배민 인증 요청';
        break;
      case 'acceptBaeminOrder':
        toastrMessage = `배민 주문 접수 : ${message.body.orderNo}`;
        break;
      case 'completeBaeminOrder':
        toastrMessage = `배민 주문 완료 : ${message.body.orderNo}`;
        break;
      case 'cancelBaeminOrder':
        toastrMessage = `배민 주문 취소 : ${message.body.orderNo}`;
        break;

      case 'acceptCoupangeatsOrder':
        toastrMessage = `쿠팡이츠 주문 접수 : ${message.body.orderId}`;
        break;

      case 'cancelCoupangeatsOrder':
        toastrMessage = `쿠팡이츠 주문 취소 : ${message.body.orderId}`;
        break;

      case 'readyCoupangeatsOrder':
        toastrMessage = `쿠팡이츠 조리 완료 : ${message.body.orderId}`;
        break;

      case 'requestYogiyoRegister':
        toastrMessage = '요기요 재인증 성공';
        break;

      case 'acceptYogiyoOrder':
        toastrMessage = `요기요 주문 접수 : ${message.body.orderNo}`;
        break;

      case 'syncOrder':
        toastrMessage = `주문 동기화 : ${message.body.orderVendor}/${message.body.orderNo}`;
        break;

      case 'createVroongDelivery':
        toastrMessage = `배차 요청 : ${message.body.client_order_no}`;
        break;
      case 'cancelVroongDelivery':
        toastrMessage = `배송 취소 : ${message.body.delivery_id}`;
        break;
      case 'preparedCargoVroongDelivery':
        toastrMessage = `조리 완료 : ${message.body.deliveryId}`;
        break;

      case 'printOrder':
        toastrMessage = `주문 인쇄 : ${message.body.orderId}`;
        break;
      case 'playSound':
        toastrMessage = '알림 재생 성공';
        break;
      case 'linkInstance':
        toastrMessage = `개별 연동 요청(${message.body.command}) : ${message.body.instanceNo}`;
        break;
      case 'linkRoom':
        toastrMessage = `호실 연동 요청(${message.body.command}) : ${message.body.roomKey}`;
        break;
      default:
        toastrMessage = `알 수 없는 메시지 : ${message.name}`;
        break;
    }

    if (message.result === 'success') {
      this.utilService.toastrInfo(toastrMessage, '성공', 5000);
    } else if (message.result === 'error') {
      if (message.reason?.startsWith('이미')) {
        // 이미 취소 된 주문입니다. 이미 접수 된 주문입니다. 의 경우에는 #toe로 알림을 보내지 않기
        this.logService.withToastrWarn(`${toastrMessage}\n${message.reason ? message.reason : '원인 불명'}`, '실패', 30000);
      } else {
        this.logService.withToastrError(`${toastrMessage}\n${message.reason ? message.reason : '원인 불명'}`, '실패', 30000);
      }
    } else {
      this.logService.withToastrError(`이런 result : ${message.result}`, '실패', 600000);
    }
  }

  private request<N extends keyof MessageBodyMap['request']>(
    name: N,
    to: MessagePeer,
    body: MessageBodyMap['request'][N],
    msec = 10000
  ): Promise<Message<'response', N>> {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;

    const organization = this.userService.organization;
    const cmd: Message<'request', N> = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      organization,
      channel: 'message',
      from: {
        class: 'omc',
        instanceNo: instanceId,
        account: this.userService.user.email
      },
      to,
      type: 'request',
      name,
      body,
    };

    this.db.doc<Message<'request', N>>(docRef).set(cmd);
    return this.observeResponseWithTimeout(docId, msec) as Promise<Message<'response', N>>;
  }

  /**
   * 요청 message를 보내고 이에 대응하는 응답 message가 추가될 때마다 알려주는 observable을 리턴한다.
   *
   * 하나의 요청에 대해서 여러 곳에서 응답할 경우에 주로 사용한다.
   *
   * 예)
   * - 동일 호실에 로그인한 여러 POS가 응답을 할 수 있다.
   */
  public requestWithObservingResponses<N extends keyof MessageBodyMap['request']>(
    name: N,
    to: MessagePeer,
    body: MessageBodyMap['request'][N],
  ) {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const requestId = docRef.id;

    const organization = this.userService.organization;
    const cmd: Message<'request', N> = {
      _id: requestId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      organization,
      type: 'request',
      channel: 'message',
      from: {
        class: 'omc',
        instanceNo: instanceId,
        account: this.userService.user.email
      },
      name,
      to,
      body,
    };

    this.db.doc<Message<'request', N>>(docRef).set(cmd);

    // 복합 색인을 피하기 위해서 requestId에 대해서만 조건을 주었다.
    const wheres: WHERE[] = [['requestId', '==', requestId]];
    return this.firebaseManager.makeDocChangesObservable<Message<'response', N>>(collectionPath, wheres, { changeTypes: ['added'] }).pipe(
      map(docChanges => docChanges.map(docChange => docChange.doc)), // 요청에 대해서 응답은 하나가 정상이다.
    );
  }

  private notification<N extends keyof MessageBodyMap['notification']>(
    name: N,
    body: MessageBodyMap['notification'][N],
    email?: string
  ) {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;

    const organization = this.userService.organization;
    const cmd: Message<'notification', N> = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      organization,
      channel: 'message',
      from: {
        class: 'omc',
        instanceNo: instanceId,
        account: email ? email : this.userService.user.email
      },
      // to,
      type: 'notification',
      name,
      body,
    };

    return this.db.doc<Message<'notification', N>>(docRef).set(cmd);
  }

  /**
   * @param msec baemin-app-proxy가 30초 timeout이므로 5초 더 기다린다.
   */
  public requestBaeminCertNo(instanceNo: string, msec = 35000) {
    return this.request('requestBaeminCertNo', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
    }, msec);
  }

  public requestAcceptBaeminOrder(instanceNo: string, orderNo: string, deliveryMinutes: number) {
    return this.request('acceptBaeminOrder', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      orderNo,
      deliveryMinutes
    });
  }

  public requestCancelBaeminOrder(instanceNo: string, orderNo: string, cancelReasonCode: BaeminCancelReasonCode) {
    return this.request('cancelBaeminOrder', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      orderNo,
      cancelReasonCode
    });
  }

  public requestCompleteBaeminOrder(instanceNo: string, orderNo: string) {
    return this.request('completeBaeminOrder', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      orderNo
    });
  }

  /**
   * 배민의 영업운영중지 상태를 조회환다.
   */
  public requestGetBaeminBlock(instanceNo: string) {
    return this.request('getBaeminBlock', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
    });
  }
  /**
   * 배민 영업운영중지 설정
   * @param temporaryBlockTime 분
   */
  public requestPostBaeminBlock(instanceNo: string, temporaryBlockTime: number) {
    return this.request('postBaeminBlock', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      temporaryBlockTime
    });
  }
  /**
   * 배민 영업운영중지 해제
   */
  public requestPostBaeminUnblock(instanceNo: string) {
    return this.request('postBaeminUnblock', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
    });
  }

  /**
   * 쿠팡이츠
   */
  public requestAcceptCoupangeatsOrder(instanceNo: string, orderId: string, duration: string) {
    return this.request('acceptCoupangeatsOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderId,
      duration
    });
  }

  public requestCancelCoupangeatsOrder(instanceNo: string, orderId: string, cancelReasonId: string, cancelType: 'DECLINE' | 'CANCEL') {
    return this.request('cancelCoupangeatsOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderId,
      cancelReasonId,
      cancelType
    });
  }

  public requestReadyCoupangeatsOrder(instanceNo: string, orderId: string) {
    return this.request('readyCoupangeatsOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderId
    });
  }

  /**
   * 요기요 재등록을 요청한다. 신규 업소를 추가하면 필요한 과정이다.
   */
  public requestYogiyoRegister() {
    const organization = this.userService.organization;

    return this.request('requestYogiyoRegister', {
      class: 'yogiyo-app-proxy',
      // TODO: 마음에 들지 않지만 일단 이렇게 한다.
      instanceNo: organization === 'ghostkitchen' ? 'default' : organization
    }, {
    }, 35000);
  }

  public requestAcceptYogiyoOrder(orderNo: string, deliveryMinutes: string) {
    const organization = this.userService.organization;

    return this.request('acceptYogiyoOrder', {
      class: 'yogiyo-app-proxy',
      // TODO: 마음에 들지 않지만 일단 이렇게 한다.
      instanceNo: organization === 'ghostkitchen' ? 'default' : organization
    }, {
      orderNo,
      deliveryMinutes
    });
  }

  /**
   * 개별 주문에 대한 동기화를 요청한다.
   *
   * @param orderId unifiedOrder id
   * @param orderNo 벤더 orderNo
   */
  public requestSyncCoupangeatsOrder(instanceNo: string, orderId: string, orderNo: string) {
    return this.request('syncOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderVendor: 'coupangeats',
      orderId,
      orderNo
    });
  }

  /**
   * 부릉 배차를 메시지를 통해 명령한다.
   */
  public requestCreateVroongDelivery(instanceNo: string, body: RequestMessageBodyCreateVroongDelivery) {
    return this.request('createVroongDelivery', {
      class: 'vroong-pos-proxy',
      instanceNo
    }, body);
  }

  /**
   * 부릉 배차 취소를 메시지를 통해 명령한다.
   */
  public requestCancelVroongDelivery(instanceNo: string, deliveryId: string) {
    return this.request('cancelVroongDelivery', {
      class: 'vroong-pos-proxy',
      instanceNo
    }, {
      deliveryId
    });
  }

  /**
   * 부릉 조리 완료를 메시지를 통해 명령한다.
   */
  public requestPreparedCargoVroongDelivery(instanceNo: string, deliveryId: string) {
    return this.request('preparedCargoVroongDelivery', {
      class: 'vroong-pos-proxy',
      instanceNo
    }, {
      deliveryId
    });
  }

  /**
   * 부릉 조리 완료를 메시지를 통해 명령한다.
   */
  public requestEstimateVroongDelivery(instanceNo: string, deliveryId: string) {
    return this.request('estimateVroongDelivery', {
      class: 'vroong-pos-proxy',
      instanceNo
    }, {
      deliveryId
    });
  }

  public notificationLogin(email: string, body: any = null) {
    return this.notification('login', body, email);
  }

  public notificationLogout() {
    return this.notification('logout', null);
  }

  public notificationLog(msg: string, context: any = null) {
    return this.notification('log', {
      msg,
      context
    });
  }

  public requestPrintOrder(instanceNo: string, orderId: string, printerKey: string, what: 'customer' | 'cook' | 'all', printCookOption: 'normal' | 'double', beep: boolean, autoPrint: boolean) {
    return this.request('printOrder', {
      class: 'printer-agent',
      instanceNo
    }, {
      orderId,
      printerKey,
      what,
      printCookOption,
      beep,
      autoPrint
    });
  }

  public requestMonit(command: 'start' | 'stop' | 'restart' | 'reload', siteKey: string, roomKey: string) {
    return this.request('monit', {
      class: 'site-agent',
      instanceNo: siteKey
    }, {
      command,
      siteKey,
      roomKey
    });
  }

  /**
   * monit 명령을 사용하지만 requestMonit과 다르게 정의했다.
   */
  public requestRestartProcess(siteKey: string, program: string, instanceNo: string) {
    return this.request('monit', {
      class: 'site-agent',
      instanceNo: siteKey
    }, {
      command: 'restart',
      siteKey,
      program,
      instanceNo
    });
  }

  /**
   * POS에 사운드 파일 출력 명령을 보낸다.
   * @param roomKey ex) 'gk-kangnam-28'
   */
  public playSoundForPos(roomKey: string, src: string[]) {
    return this.request('playSound', {
      class: 'pos',
      instanceNo: roomKey
    }, {
      src
    });
  }

  /**
   * 배민 배달 지역 백업 명령을 보낸다.
   */
  public requestSaveBaeminDeliveryRegion(instanceNo: string, shopNo: string, desc?: string) {
    return this.request('saveBaeminDeliveryRegion', {
      class: 'baemin-ceo-proxy',
      instanceNo
    }, {
      shopNo,
      desc
    }, 20000);
  }

  /**
   * 배민 배달 지역 백업본을 적용하는 명령을 보낸다.
   */
  public requestLoadBaeminDeliveryRegion(instanceNo: string, shopNo: string, docId: string) {
    return this.request('loadBaeminDeliveryRegion', {
      class: 'baemin-ceo-proxy',
      instanceNo
    }, {
      shopNo,
      docId
    });
  }

  /**
   * 연동 관련 명령을 보낸다.
   */
  public requestLinkRoom(
    command: MessageBodyMap['request']['linkRoom']['command'],
    siteKey: string,
    roomKey: string
  ) {
    return this.request('linkRoom', {
      class: 'site-agent',
      instanceNo: siteKey
    }, {
      command,
      siteKey,
      roomKey
    }, 60000); // 간혹 연동이 많은 업소가 있으므로 최대 값을 늘린다.
  }

  /**
   * 연동 관련 명령을 보낸다.
   */
  public requestLinkInstance(
    command: MessageBodyMap['request']['linkInstance']['command'],
    siteKey: string,
    roomKey: string,
    vendor: string,
    instanceNo: string
  ) {
    return this.request('linkInstance', {
      class: 'site-agent',
      instanceNo: siteKey
    }, {
      command,
      siteKey,
      roomKey,
      vendor,
      instanceNo
    }, 20000);
  }
}
