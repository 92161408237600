/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import cloneDeep from 'lodash-es/cloneDeep';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { UnifiedOrderDoc } from '../../schema/3/schema';
import { UnifiedOrderDocUI } from '../../schema/4/schema-ui';

import { UtilService } from '../../core/1/util.service';
import { UnifiedDeliveryService } from '../../core/2/unified-delivery.service';
import { UserService } from '../../core/2/user.service';

import { DialogLogOrderService } from '../dialog-log-order/dialog-log-order.service';
import { DialogPrintOrderService } from '../dialog-print-order/dialog-print-order.service';
import { DialogRawDataService } from '../dialog-raw-data/dialog-raw-data.service';
import { DialogOrderHistoryService } from './dialog-order-history/dialog-order-history.service';

@Component({
  selector: 'app-dialog-order-center',
  templateUrl: './dialog-order-center.component.html',
  styleUrls: ['./dialog-order-center.component.scss']
})
export class DialogOrderCenterComponent implements OnInit {
  public isAdmin = false;

  public order: UnifiedOrderDocUI;

  constructor(
    public dialogRef: MatDialogRef<DialogOrderCenterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { order: UnifiedOrderDocUI },
    private deliveryService: UnifiedDeliveryService,
    private dialogLogOrderService: DialogLogOrderService,
    private dialogPrintOrderService: DialogPrintOrderService,
    private dialogRawDataService: DialogRawDataService,
    private dialogOrderHistoryService: DialogOrderHistoryService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private utilService: UtilService
  ) {
    this.isAdmin = this.userService.user.role === 'admin';
  }

  async ngOnInit() {
    // 1. relatedXXXXDeliveries 없는 주문 먼저 반영하고
    this.order = this.data.order;
    const order = cloneDeep(this.data.order);

    if (order.deliveryType === 'DELIVERY') {
      if (order._ui?.relatedDeliveries) {
        // 이미 관련배차가 있는 경우
      } else {
        // 불필요한 경우에는 this.order를 변경하지 않도록 한다.
        let isChanged = false;

        // 관련 배차가 없다면 찾아서 추가한다.
        {
          const deliverySnapshot = await this.deliveryService.getDeliveryByRelatedOrder([], order._id, 'desc').toPromise();
          const deliveries = deliverySnapshot.docs.map(doc => doc.data());

          if (deliveries.length > 0) {
            const { _ui = {} } = order;
            // 관련 배차가 있는 경우를 위에서 이미 거르고 있으므로 deliveries를 그대로 할당한다.
            _ui.relatedDeliveries = deliveries;
            order._ui = _ui;

            isChanged = true;
          }
        }

        // 2. relatedXXXXDeliveries 포함된 주문 반영한다.
        if (isChanged) {
          this.order = order;
        }
      }
    }
  }

  public statusActionDone(result: boolean) {
    this.dialogRef?.close();
    this.dialogRef = undefined;
  }

  /**
   * 관리자 메모에서 '저장을 눌렀을 때' 호출
   */
  public memoActionDone(result: boolean) {
    this.dialogRef?.close();
    this.dialogRef = undefined;
  }

  public openLogOrderDialog() {
    this.dialogLogOrderService.openDialog(this.order);
  }

  public openOrderHistory() {
    if (this.order.orderVendor === 'coupangeats') {
      this.utilService.toastrWarning('주문 이력 조회가 불가한 주문입니다.');
      return;
    }
    this.dialogOrderHistoryService.openDialog(this.order);
  }

  public openPrintOrderDialog() {
    this.dialogPrintOrderService.openDialog(this.order);
  }

  public openRawDataDialog() {
    this.dialogRawDataService.openDialog('unifiedOrder', this.order._id);
  }

  public modifyOrder() {
    // 다른 다이얼로그는 닫는다.
    this.dialog.closeAll();
    this.router.navigate(['manual-order', this.order.room], {
      state: {
        order: this.order
      }
    });
  }

  public openDestMap() {
    const order: UnifiedOrderDoc = this.order;

    // tslint:disable-next-line: max-line-length
    const name = encodeURI(`${order.address_dong} ${order.address_jibun} ${order.address_detail} (${order.address_road}${order.address_building_name ? ' ' + order.address_building_name : ''})${order.orderMsg ? ' [' + order.orderMsg + ']' : ''}`)
      .replace(/,/g, ''); // COMMA 제거
    const url = 'http://map.daum.net/link/map/' + name + `,${order.address_location.lat},${order.address_location.lon}`;

    // refer : http://apis.map.daum.net/web/guide/#bigmapurl
    // http://map.daum.net/?urlX=506255.0&urlY=1113450.0&name=%EB%85%BC%ED%98%84%EB%8F%99%2B170-14
    window.open(
      // tslint:disable-next-line: max-line-length
      url,
      order.address_jibun, // 번지가 다르면 새 창에서 열리도록 하기 위함
      'centerscreen,width=1600,height=1080,alwaysRaised,noreferrer'
    );
  }
}
