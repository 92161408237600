/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { ContactDoc } from '../../../schema/3/schema-ops';
import { UnifiedOrderDoc } from '../../../schema/3/schema';
import { UnifiedOrderDocUI } from '../../../schema/4/schema-ui';

import { UtilService } from '../../../core/1/util.service';
import { CurrentTelephone } from '../../../core/1/current-telephone';
import { telFormatter } from '../../../core/1/ag-util';
import { TelephoneService } from '../../../core/2/telephone.service';
import { ContactService } from '../../../core/3/contact.service';
import { LogService } from '../../../core/3/log.service';
import { DialogSendSmsService } from '../../dialog-send-sms/dialog-send-sms.service';

@Component({
  selector: 'app-fast-contact',
  templateUrl: './fast-contact.component.html',
  styleUrls: ['./fast-contact.component.scss']
})

export class FastContactComponent implements OnInit, OnDestroy {
  private order: UnifiedOrderDocUI;

  // 통합주문조회 화면에서 _ui.relatedXXXDeliveries를 나중에 추가할 수 있다.
  @Input('order') set _order(order: UnifiedOrderDoc) {
    this.order = order;

    this.setContact();
  }

  fastContacts: Partial<ContactDoc>[] = [];
  // html 표시용
  telFormatter = telFormatter;
  private phoneNo: string;
  telephoneSubscription: Subscription;

  constructor(
    private contactService: ContactService,
    private dialogSendSmsService: DialogSendSmsService,
    private telephoneService: TelephoneService,
    private currentTelephone: CurrentTelephone,
    private utilService: UtilService,
    private logService: LogService
  ) { }

  ngOnInit() {
    this.setContact();

    this.telephoneSubscription = this.currentTelephone.telephoneSubject.subscribe(phoneNo => {
      this.phoneNo = phoneNo;
    });
  }

  ngOnDestroy() {
    if (this.telephoneSubscription) {
      this.telephoneSubscription.unsubscribe();
    }
  }

  async setContact() {
    // a, b, c 먼저 표시하고 d는 나중에 추가했으나 UI 깜박임이 심해서 변경
    this.importRoomContact().then(c => {
      const a = this.importCustomerContact();
      const b = this.importRiderContact();

      this.fastContacts = [...a, ...b, ...c];
    });
  }

  private async importRoomContact() {
    const roomContactSnapshot = await this.contactService.getRoomContact(this.order.room).toPromise();
    return roomContactSnapshot.docs.map(doc => {
      const contact = doc.data() as ContactDoc;
      return {
        category: '업소',
        name: contact.name,
        telNo: contact.telNo
      };
    });
  }

  private importRiderContact() {
    return this.order._ui?.relatedDeliveries?.filter(delivery => delivery.riderName)
      .map(delivery => ({
        category: '라이더',
        name: delivery.riderName,
        telNo: delivery.riderTel
      })) ?? [];
  }

  private importCustomerContact() {
    return [{
      category: '고객',
      name: '',
      telNo: this.order.userTel
    }];
  }

  openDialogSendSms(contact: Partial<ContactDoc>) {
    this.dialogSendSmsService.openDialog(contact);
  }

  async dial(contact: Partial<ContactDoc>) {
    const destination = contact.telNo;
    if (this.phoneNo === 'X') {
      this.utilService.toastrWarning('사용하실 전화기를 선택해주세요.');
      return;
    }

    try {
      const response = await this.telephoneService.dialByUser(this.phoneNo, destination);
      const dialByUserResponse: any = await response.json();
      if (dialByUserResponse.SVC_RT === '0000') {
        this.utilService.toastrInfo(`${dialByUserResponse.DATAS.DESTNUMBER}로 연결 중 입니다.`);
      } else {
        this.utilService.toastrError('전화 연결에 실패했습니다. 다시 시도해 주세요.');
        this.logService.warn(`dial ${this.phoneNo}=>${destination}으로 전화연결 실패 : ${JSON.stringify(dialByUserResponse)}`);
      }
    } catch (error) {
      this.utilService.toastrCatch(error, '전화 연결에 실패했습니다. 다시 시도해 주세요.');
      this.logService.warn(`dial ${this.phoneNo}=>${destination}으로 전화연결 실패 : ${JSON.stringify(error)}`);
    }
  }

}
