/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm-options',
  templateUrl: './dialog-confirm-options.component.html',
  styleUrls: ['./dialog-confirm-options.component.scss']
})
export class DialogConfirmOptionsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmOptionsComponent>,
  ) {
  }

  ngOnInit(): void {
  }

  public onCloseDialog(result: string) {
    this.dialogRef?.close(result);
    this.dialogRef = undefined;
  }
}
