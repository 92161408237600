<h2 mat-h2>JSON 편집기</h2>
<form [formGroup]="jsonEditorForm" class="container">
  <div class="half-section">
    <mat-form-field class="editor-container" floatLable="always">
      <mat-label>{{collectionName}}/{{docId}}</mat-label>
      <textarea matInput class="editor" formControlName="textJsonDoc"></textarea>
      <mat-error *ngIf="jsonEditorForm.get('textJsonDoc').invalid">{{ jsonEditorForm.get('textJsonDoc').errors['reason']}}</mat-error>
    </mat-form-field>
    <div>
      <button mat-raised-button color="primary" (click)="goToConsole('gcp')">GCP Console</button>
      <button mat-raised-button color="primary" (click)="goToConsole('firebase')">Firebase Console</button>
      <button mat-raised-button color="warn" (click)="openConfirmDialog()">변경...</button>
      <button mat-raised-button (click)="onCloseDialog()">닫기</button>
    </div>
  </div>
  <div class="half-section">
    <pre [innerHTML]="jsonDocToHtml"></pre>
  </div>
</form>
