<h2>주문서 인쇄</h2>

<div>
  <mat-label>주문 정보</mat-label>
  <p>{{roomName}} {{data.order.shopName}}</p>
  <p>{{orderDate}}</p>
  <p>{{data.order._id}}</p>
</div>

<mat-divider></mat-divider>

<div>
  <mat-label>주문서 유형: </mat-label>
  <mat-radio-group [formControl]="whatControl">
    <mat-radio-button value="cook">주방용</mat-radio-button>
    <mat-radio-button value="customer">고객용</mat-radio-button>
    <mat-radio-button value="all">모두</mat-radio-button>
  </mat-radio-group>
</div> 

<div>
  <mat-label>주방용 인쇄형식: </mat-label>
  <mat-radio-group [formControl]="printCookOptionControl">
    <mat-radio-button value="normal">기본</mat-radio-button>
    <mat-radio-button value="double">2배</mat-radio-button>
  </mat-radio-group>
</div> 

<mat-divider></mat-divider>

<div>
  <mat-label>프린터: </mat-label>
  <mat-select *ngIf="selectedPrinter" [value]="selectedPrinter" (selectionChange)="selectedPrinterChanged($event)">
    <mat-option *ngFor="let printer of printers" [value]="printer">{{ printer._id }}</mat-option>
  </mat-select>

  <p>사이트: {{ selectedPrinter ? selectedPrinter.proxyInstanceNo : ''}}</p>
  <p>프린터 IP주소: {{ selectedPrinter ? selectedPrinter.printerAddress : '' }}</p>
</div>

<mat-divider></mat-divider>

<div>
  <mat-label>옵션: </mat-label>
  <mat-checkbox [formControl]="beepControl">beep</mat-checkbox>
  <mat-checkbox [formControl]="autoPrintControl">autoPrint</mat-checkbox>
</div>

<div class="buttons">
  <button mat-raised-button cdkFocusInitial (click)="onCancel()">닫기</button>
  <button mat-raised-button [disabled]="!selectedPrinter" (click)="requestPrintOrder()" color="accent">인쇄</button>
</div>