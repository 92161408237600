/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UnifiedOrderDoc } from '../../../schema/3/schema';

@Component({
  selector: 'app-order-action',
  templateUrl: './order-action.component.html',
  styleUrls: ['./order-action.component.scss']
})
export class OrderActionComponent implements OnInit {
  @Input() order: UnifiedOrderDoc;
  @Output() actionDone = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
    this.order = this.order;
  }

  // 자식으로부터 받은 이벤트를 부모인 DialogOrderCenterComponent까지 이벤트가 전달되어야한다.
  actionDone0(result: boolean) {
    this.actionDone.emit(result);
  }
}
