/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import cloneDeep from 'lodash-es/cloneDeep';  // refer: https://medium.com/@armno/til-importing-lodash-into-angular-the-better-way-aacbeaa40473
import { merge } from 'lodash-es';
import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, ValidatorFn, FormControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/functions';

import { AugmentedAddress } from '../../schema/1/schema-common';
import { SiteDoc, UnifiedOrder } from '../../schema/3/schema';
import { CallInputAugmentAddress, CallOutputAugmentAddress } from '../../schema/4/schema-functions-call';

import { UtilService } from '../../core/1/util.service';
import { NotificationCenterService } from '../../core/1/notification-center.service';
import { UserService } from '../../core/2/user.service';
import { SiteService } from '../../core/3/site.service';
import { RoomService } from '../../core/4/room.service';

import { DialogSpinnerService } from '../dialog-spinner/dialog-spinner.service';
import { DialogSpinnerComponent } from '../dialog-spinner/dialog-spinner.component';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnDestroy {
  public selectedIndex = 0; // 0: 번지 주소, 1: 도로명/주소

  // Data model
  private order: Partial<UnifiedOrder>;
  private addressTyping = '';
  private dongDB: { [sido: string]: { [sigungu: string]: string[] } } = {};

  private destroySignal = new Subject<boolean>();

  private organization = 'NA';
  private site = 'NA';
  private room = 'NA';
  @Input('room') set _room(value: string) {
    this.room = value;
    this.organization = this.roomService.rooms[value].organization;
    this.site = this.roomService.rooms[value].site;
  }

  @Input('order') set _order(value: Partial<UnifiedOrder>) {
    console.log('AddressFormComponent::order changed');
    // cloneDeep을 이용해서 @Input과의 레퍼런스 관계를 끊는다.
    // 주소와 관련되지 않은 다른 필드가 영향을 미치지 않도록 관련 필드만 취한다.
    const order0 = cloneDeep(value);
    this.order = {
      address_key: order0.address_key ?? '',
      address_detail: order0.address_detail ?? '',
      address_sido: order0.address_sido ?? '서울특별시',
      address_sigungu: order0.address_sigungu ?? '강남구',
      address_dong: order0.address_dong ?? '논현동',
      address_jibun: order0.address_jibun ?? '',
      address_dongH: order0.address_dongH ?? '',
      address_road: order0.address_road ?? '',
      address_building_name: order0.address_building_name ?? '',
      address_location: order0.address_location ?? {
        lat: 0,
        lon: 0
      },
      vroong: order0.vroong ?? {
        dest_sigungu: '',
        dest_legal_eupmyeondong: '',
        dest_admin_eupmyeondong: '',
        dest_ri: '',
        dest_beonji: '',
        dest_road: '',
        dest_building_number: ''
      },
    };

    // 주소 찾기를 수행한 order
    if (this.order.address_key) {
      this.augmented = true;
    }

    this.rebuild_form();
    // TODO: observeXXX()가 여러 번 실행될 수 있는데 문제는 없는가?
    this.observeSido();
    this.observeSigungu();
  }

  @Output() addressConfirmed = new EventEmitter<Partial<UnifiedOrder>>();

  dialogRef: MatDialogRef<DialogSpinnerComponent, any>;

  // Form Control
  addressForm: FormGroup;

  // 한 번이라도 주소확인을 했으면 true
  augmented = false;

  constructor(
    private fb: FormBuilder,
    private fns: AngularFireFunctions,
    private dialogSpinnerService: DialogSpinnerService,
    private notificationCenterService: NotificationCenterService,
    private siteService: SiteService,
    private utilService: UtilService,
    private userService: UserService,
    private roomService: RoomService,
  ) { }

  get sidos() {
    return Object.keys(this.dongDB);
  }

  get sigungus() {
    const sido = this.addressForm.get('address_sido').value;

    return Object.keys(this.dongDB[sido]);
  }

  get dongs() {
    const sido = this.addressForm.get('address_sido').value;
    const sigungu = this.addressForm.get('address_sigungu').value;

    return this.dongDB[sido][sigungu];
  }

  ngOnInit() {
    this.dongDB = this.getDongDB();
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }

    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  rebuild_form() {
    this.addressForm = this.fb.group({
      address_sido: [{ value: this.order.address_sido, disabled: false }],
      address_sigungu: this.order.address_sigungu,
      address_dong: this.order.address_dong,
      address_jibun: [this.order.address_jibun, this.addressJibunValidator()],
      address_detail: this.order.address_detail,

      address_key: [{ value: this.order.address_key, disabled: true }],
      address_dongH: [{ value: this.order.address_dongH, disabled: true }],
      address_road: [{ value: this.order.address_road, disabled: true }],
      address_building_name: [{ value: this.order.address_building_name, disabled: true }],
      address_location_lat: [{ value: this.order.address_location.lat, disabled: true }],
      address_location_lon: [{ value: this.order.address_location.lon, disabled: true }],

      address_typing: [this.addressTyping, this.addressTypingValidator()]
    }, { validators: this.formValidator() });

    // 처음부터 에러 메시지를 표시하기 위함이다.
    // this.addressForm.get('address_jibun').markAsTouched();
    // this.addressForm.get('address_typing').markAsTouched();
  }

  observeSido() {
    this.addressForm.get('address_sido').valueChanges
      .pipe(takeUntil(this.destroySignal)).forEach(newSidoValue => {
        const oldSido = this.addressForm.value.address_sido;

        if (oldSido !== newSidoValue) {
          const newSigungu = Object.keys(this.dongDB[newSidoValue])[0];
          const newDong = this.dongDB[newSidoValue][newSigungu][0];
          this.addressForm.get('address_sigungu').setValue(newSigungu);
          this.addressForm.get('address_dong').setValue(newDong);
        }
      });
  }

  /**
   * sigungu가 변경이 되었을 때 가능하지 않은 기존 동이 남아 있게 된다.
   * 가능한 동으로 변경을 한다.
   */
  async observeSigungu() {
    this.addressForm.get('address_sigungu').valueChanges
      .pipe(takeUntil(this.destroySignal)).subscribe(sigunguValue => {

        const sido = this.addressForm.get('address_sido');
        const dong = this.addressForm.get('address_dong');

        if (!this.dongDB[sido.value][sigunguValue].includes(dong.value)) {
          const newDong = this.dongDB[sido.value][sigunguValue][0];
          dong.setValue(newDong);
        }
      });
  }

  /**
   * augmentAddress callable 응답을 반영한다.
   */
  private updateAddress(augmentedAddress: AugmentedAddress) {
    const sido = augmentedAddress.sido;
    const sigungu = augmentedAddress.sigungu;
    const dong = augmentedAddress.dong;
    const jibun = augmentedAddress.jibun;

    this.order = {
      ...this.order, ...{
        address_key: augmentedAddress.key,
        address_dongH: augmentedAddress.dongH,
        address_road: augmentedAddress.road,
        address_building_name: augmentedAddress.building_name,
        address_location: augmentedAddress.location,
        address_sido: sido,
        address_sigungu: sigungu,
        address_dong: dong,
        address_jibun: jibun,
        vroong: augmentedAddress.vroong,
      }
    };

    // update dongDB
    let dongs: string[] = get(this.dongDB, [sido, sigungu], []);
    const dongSet = new Set(dongs);
    dongSet.add(dong);
    dongs = Array.from(dongSet);
    set(this.dongDB, [sido, sigungu], dongs);

    this.addressForm.patchValue({
      address_key: augmentedAddress.key,
      address_dongH: augmentedAddress.dongH,
      address_road: augmentedAddress.road,
      address_building_name: augmentedAddress.building_name,
      address_location_lat: augmentedAddress.location.lat,
      address_location_lon: augmentedAddress.location.lon,

      address_sido: sido,
      address_sigungu: sigungu,
      address_dong: dong,
      address_jibun: jibun,
    });
    this.augmented = true;
    // 이 값은 기준으로 변경이 되면 다시 주소 확인을 해야 한다.
    this.addressForm.markAsPristine();

    // 확인이 되면 다른 곳에도 알려준다.
    this.addressConfirmed.emit(this.order);

    this.notificationCenterService.latestAddressSubject.next({
      address_key: augmentedAddress.key,
      address_detail: this.order.address_detail,
      address_sido: this.order.address_sido,
      address_sigungu: this.order.address_sigungu,
      address_dong: this.order.address_dong,
      address_jibun: this.order.address_jibun,
      address_dongH: augmentedAddress.dongH,
      address_road: augmentedAddress.road,
      address_building_name: augmentedAddress.building_name,
      address_location: augmentedAddress.location,
    });
  }

  /**
   * 위도, 경도, 도로명 주소
   */
  public async augmentAddress() {
    // address_typing 값은 UI에서만 사용하는 필드이므로 제거
    delete this.addressForm.value.address_typing;

    // UI와 동기화
    // this.addressForm.value는 disabled가 false인 4개의 필드에 대해서만 응답
    this.order = { ...this.order, ...this.addressForm.value };
    const { address_sido, address_sigungu, address_dong, address_jibun, address_detail } = this.order;

    this.dialogRef = this.dialogSpinnerService.openSpinnerDialog('응답 대기 중');

    if (this.selectedIndex !== 0) {
      // this.order의 변화에 의해서 rebuild_form이 불리더라도 이전 상태를 유지하기 위함이다.
      this.addressTyping = this.addressForm.get('address_typing').value;
    }

    const rawAddress = (this.selectedIndex === 0) ?
      `${address_sido} ${address_sigungu} ${address_dong} ${address_jibun} ${address_detail}` :
      this.addressTyping;

    const callInput: CallInputAugmentAddress = {
      organization: this.organization,
      site: this.site,
      room: this.room,
      rawAddress,
      from: `omc/${this.userService.user?.email}`
    };

    const callable = this.fns.httpsCallable<CallInputAugmentAddress, CallOutputAugmentAddress>('callAugmentAddress');
    const callOutput = await callable(callInput).toPromise();

    try {
      if (this.dialogRef) {
        this.dialogRef.close(false);
        this.dialogRef = undefined;
      }

      if (callOutput.result === 'success') {
        this.updateAddress(callOutput.augmentedAddress);
      } else {
        Swal.fire(`주소 확인 실패 : ${callOutput.reason}`);
      }
    } catch (error) {
      console.error(error);

      if (this.dialogRef) {
        this.dialogRef.close(false);
        this.dialogRef = undefined;
      }

      Swal.fire(`에러 : ${error.message}`);
    }
  }

  /**
   * inputAddressMethod 값에 따라서 결과가 다른다.
   */
  addressJibunValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      // 초기에 undefined로 되는 경우가 있다.
      if (control.parent === null) {
        return null;
      }

      const addressJibun = control.value;
      const addressForm = control.parent;
      // 번지 주소 탭을 선택한 경우에만 validation 확인을 한다.
      if (this.selectedIndex === 0) {
        if (addressJibun.length === 0) {
          return { reason: '내용이 없네요.' };
        } else {
          if (addressForm.get('address_jibun').errors) {
            if (addressForm.get('address_jibun').errors.pattern) {
              return { reason: '형식이 맞지 않습니다!' };
            } else if (addressForm.get('address_jibun').errors.outOfRegion) {
              return addressForm.get('address_jibun').errors.outOfRegion;
            }
          }
        }
      }

      return null;
    };
  }

  /**
   * inputAddressMethod 값에 따라서 결과가 다른다.
   */
  addressTypingValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      // 초기에 undefined로 되는 경우가 있다.
      if (control.parent === null) {
        return null;
      }

      const addressTyping = control.value;

      if (this.selectedIndex === 1) {
        if (addressTyping === '') {
          return { reason: '내용이 없네요.' };
        }
      }

      return null;
    };
  }

  /**
   * 모든 control이 변경될 때마다 호출된다.
   */
  formValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {

      const addressForm = control;
      if (addressForm) {
        // onlySelf를 false로 하면 무한반복한다.
        addressForm.get('address_typing').updateValueAndValidity({ onlySelf: true });
        addressForm.get('address_jibun').updateValueAndValidity({ onlySelf: true });
      }

      return null;
    };
  }

  public onSelectedIndexChange(index) {
    this.selectedIndex = index;
    // 탭이 바뀌면 validation 확인 대상애 변경된다.
    this.addressForm.updateValueAndValidity({ onlySelf: true });
  }

  /**
   * 모든 site의 dongDB를 하나로 취합한다.
   */
  private getDongDB() {
    if (this.siteService.sites === undefined || Object.keys(this.siteService.sites).length === 0) {
      this.utilService.toastrError(`동 주소 취합에 필요한 지점별 정보가 없습니다. 개발자 3호에게 알려주세요.`);
      // 행여 dongDB취합에 실패한 경우 다른 UI에서 에러가 발생하지 않도록 샘플 값을 반환한다.
      return { 서울특별시: { 강남구: ['논현동'] } };
    }

    // 각 site의 dongDB를 하나로 취합한다.
    const mergedDongDB = Object.values(this.siteService.sites).reduce((dongDB, site) => {
      merge(dongDB, site.dongDB);
      return dongDB;
    }, {} as SiteDoc['dongDB']);

    // UI에서 사용하기 위해 sigungu의 하위 dong object를 array로 변경한다.
    // { [dong: string]: boolean } => string[]
    return Object.entries(mergedDongDB).sort().reduce((dongDBWithArrayDongs, [sido, sigungus]) => {
      dongDBWithArrayDongs[sido] = Object.entries(sigungus).sort().reduce((acc, [sigungu, dongs]) => {
        acc[sigungu] = Object.keys(dongs).sort();
        return acc;
      }, {} as { [sigungu: string]: string[] });
      return dongDBWithArrayDongs;
    }, {} as { [sido: string]: { [sigungu: string]: string[] } });
  }
}
