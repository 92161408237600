/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { Howl } from 'howler';

type SoundName = 'new' | 'twominute' | 'wait' | 'accept';

@Injectable({
  providedIn: 'root'
})
export class SoundService {

  //
  // 사운드 파일을 반복적으로 재생한다. (soundCanceled는 제외)
  // loop을 사용할 경우에 쉼 없이 나올 수 있어서 onend와 setTimeout()을 이용해서 쉬는 시간을 두고 있다.
  // 1. 재생 중에 사운드 파일이 변경될 수 있다.
  // 2. 동시에 2개 이상의 사운드 재생이 되지 않아야 한다.
  // 3. stop()은 재생중인 모든 사운드을 멈추어야 한다.
  private readonly soundNames: SoundName[] = ['new', 'twominute', 'wait', 'accept'];
  private playing = false;
  private currentSoundName: string;
  private sounds: {
    [soundName: string]: {
      howl: Howl;
      timeoutId: any;
    }
  } = {};

  readonly soundCanceled = new Howl({
    src: ['/assets/canceled.mp3'],
    loop: false,
    preload: true,
    volume: 1.0
  });

  constructor() {
    this.soundNames.forEach(soundName => {
      this.sounds[soundName] = {
        howl: new Howl({
          src: [`/assets/${soundName}.mp3`],
          loop: false,
          preload: true,
          volume: 1.0,
          onend: () => {
            this.sounds[soundName].timeoutId = setTimeout(() => {
              this.sounds[soundName].howl.play();
            }, 5000);
          }
        }),
        timeoutId: null
      };
    });
  }

  public playDefault() {
    this.play('new');
  }

  /**
   * 2022-06-01
   * - 운영팀이 없으므로 재생하지 않는다.
   */
  public play(soundName: SoundName) {
    console.log(`play(${soundName})지만 재생하지 않는다.`);
    return;

    // if (this.playing) {
    //   if (this.currentSoundName === soundName) {
    //     // Do nothing
    //   } else {
    //     // 1. stop()
    //     this.stop();
    //     // 2. play()
    //     this.play(soundName);
    //   }
    // } else {
    //   // playSound
    //   this.currentSoundName = soundName;
    //   this.playing = true;
    //   this.sounds[soundName].howl.play();
    // }
  }

  public stop() {
    if (this.playing) {
      this.playing = false;
      this.soundNames.forEach(soundName => {
        this.sounds[soundName].howl.stop();
      });

      this.soundNames.forEach(soundName => {
        if (this.sounds[soundName].timeoutId) {
          window.clearTimeout(this.sounds[soundName].timeoutId);
          this.sounds[soundName].timeoutId = null;
        }
      });
    }
  }

  /**
   * '취소된 주문이 있습니다.' 재생
   * loop가 false라서 별도의 stop은 필요하지 않다.
   */
  public playCanceled() {
    console.log('playCanceled를 재생하지 않는다.');
    // this.soundCanceled.play();
  }
}
