/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UnifiedOrderDoc } from '../../../schema/3/schema';
import { DialogOrderHistoryComponent } from './dialog-order-history.component';

@Injectable({
  providedIn: 'root'
})
export class DialogOrderHistoryService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialog(order: UnifiedOrderDoc) {
    let dialogRef: MatDialogRef<DialogOrderHistoryComponent>;
    dialogRef = this.dialog.open(DialogOrderHistoryComponent, {
      data: {
        order
      },
      hasBackdrop: true,
      width: '850px',
    });
  }

}
