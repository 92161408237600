/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Message } from '../../../schema/2/schema-message';
import { UnifiedOrder, UnifiedOrderDoc } from '../../../schema/3/schema';

import { UtilService } from '../../../core/1/util.service';
import { riderNames } from '../../../core/1/ghostrider';
import { trimOrganization } from '../../../core/2/util';
import { LogService } from '../../../core/3/log.service';
import { UnifiedOrderService } from '../../../core/3/unified-order.service';
import { SiteService } from '../../../core/3/site.service';
import { MessageService } from '../../../core/5/message.service';

@Component({
  selector: 'app-order-memo',
  templateUrl: './order-memo.component.html',
  styleUrls: ['./order-memo.component.scss']
})
export class OrderMemoComponent implements OnInit {
  @Input() order: UnifiedOrderDoc;
  @Output() memoActionDone = new EventEmitter<boolean>();

  public readonly ghostriderNames = [...riderNames, '---'];

  // template에서 사용
  trimOrganization = trimOrganization;

  orderMemoForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    public utilService: UtilService,
    private unifiedOrderService: UnifiedOrderService,
    private siteService: SiteService,
    private logService: LogService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    const order: UnifiedOrderDoc = this.order;

    const cherrypickStatus = order.deliveryCherrypickStatus ? order.deliveryCherrypickStatus : '??';

    this.orderMemoForm = this.fb.group({
      adminMemo: order.adminMemo ? order.adminMemo : '',
      posMemo: order.posMemo ? order.posMemo : '',
      cherrypickStatus,
      ghostriderName: order.ghostriderName ? order.ghostriderName : null
    });
  }

  /**
   * @param commit 닫기나 취소의 경우에는 false가 되어 바로 리턴하면 된다.
   */
  public async updatePartialOrder(commit = true) {
    // 닫기, 취소 등에 의해서 호출된 경우에는 취할 액션이 없다.
    if (commit === false) {
      return;
    }

    if (this.orderMemoForm.pristine) {
      this.logService.info(`[${this.order._id}] OrderMemoComponent에서 updatePartialOrder(${commit}) 수행 => 변경된 내용이 없어서 무시`);
      console.log('orderMemoForm에 변경된 내용이 없다.');
      return;
    }

    this.logService.info(`[${this.order._id}] OrderMemoComponent에서 updatePartialOrder(${commit}) 수행`);

    const adminMemo = this.orderMemoForm.get('adminMemo').value;
    const posMemo = this.orderMemoForm.get('posMemo').value;
    const deliveryCherrypickStatus = this.orderMemoForm.get('cherrypickStatus').value;
    const ghostriderName = deliveryCherrypickStatus === 'ghostrider' ? this.orderMemoForm.get('ghostriderName').value : null;

    const partialOrder: Partial<UnifiedOrder> = {
      _id: this.order._id,
      adminMemo,
      posMemo,
      deliveryCherrypickStatus,
      ghostriderName
    };

    try {
      const order = this.order;

      // 고스트라이더인 경우에 픽업 시간을 설정한다.
      // TODO: 다른 곳(GhostriderOrderActionRenderer)에서도 동일 로직을 사용하고 있어서 정리해야 한다.
      if (deliveryCherrypickStatus === 'ghostrider') {
        if (order.ghostriderPickupAt == null) {
          let cookMinutes = order.cookMinutes;
          if (cookMinutes == null) {
            this.utilService.toastrWarning('조리시간이 없어서 20분으로 간주합니다.');
            cookMinutes = 20;
          }

          const siteKey = order.site;
          const site = this.siteService.sites;
          if (site[siteKey]) {
            const minPickupOption = parseInt(site[siteKey].ghostrider.minPickupOption, 10);
            const pickupMinutes = Math.max(cookMinutes, minPickupOption);
            const orderDate = parseISO(order.orderDate);
            const pickupDate = orderDate.getTime() + (pickupMinutes * 60 * 1000);

            // 주문시각 + MAX(조리시간, 최소픽업시간)
            partialOrder.ghostriderPickupAt = format(new Date(pickupDate), `yyyy-MM-dd'T'HH:mm:ss+0900`);
          } else {
            this.logService.withToastrError(`${siteKey}에 대한 site 컬렉션 정보가 없습니다. 개발자에게 알려주세요.`);
          }
        } else {
          console.log(`ghostriderPickupAt(${order.ghostriderPickupAt})이 이미 존재해서 변경하지 않습니다.`);
        }
      } else {
        partialOrder.ghostriderPickupAt = null;
      }

      await this.unifiedOrderService.mergeOrder(partialOrder);
      this.logService.logOrder(order, `메모를 변경했습니다. [${adminMemo}][${posMemo}]`);
    } catch (error) {
      this.logService.logOrder(this.order, `order-memo에서 예외가 발생했습니다.\n${JSON.stringify(error)}`, 'error');
      this.utilService.toastrCatch(error, 'order-memo에서 예외 발생했습니다.');
    }
  }

  public async onSubmit(playSound: boolean) {
    await this.updatePartialOrder();

    if (playSound) {
      this.playSoundForPos(this.order.room);
    }

    this.memoActionDone.emit(true);
  }

  public onCancel() {
    this.memoActionDone.emit(false);
  }

  private async playSoundForPos(roomKey: string) {
    try {
      // 알림이 있습니다. 운영팀 메모를 확인해 주세요.
      const src = ['https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/sound/notify-memo.mp3'];
      const resMessage = await this.messageService.playSoundForPos(roomKey, src) as Message<'response', 'playSound'>;

      if (resMessage.result === 'error') {
        this.utilService.toastrInfo(`${roomKey} 알림 재생 실패 : ${resMessage.reason}`);
      }
      // 성공의 경우에는 message.service.ts에서 toastr 표시
    } catch (error) {
      if (error.name === 'TimeoutError') {
        this.utilService.toastrError(`${roomKey} 알림 재생 실패: 시간 초과`);
      } else {
        this.utilService.toastrCatch(error, `${roomKey} 알림 재생 실패`);
      }
    }
  }
}
