<div class="order-container-background" [ngClass]="cssClass" [class.shortHeight]="false">
  <!-- 제목 배경 용도로 자리만 잡는다.  -->
  <div></div>
</div>


<div class="order-container" [ngClass]="cssClass">
  <h3>
    <span class="date"><span *ngIf="!isTodayOrder">{{ order.orderDate | date:'M-d':'+0900' }} ({{ weekdayKR(order.orderDate) }}) </span>{{ order.orderDate | date:'HH:mm':'+0900' }}</span>

    <span>{{ order.address_dong }} {{ order.address_jibun }}</span>

    <span class="status">{{ statusString }}</span>
  </h3>

  <table class="delivery">
    <tr >
      <td>{{ order.shopName }} / {{ trimOrganization(roomService.rooms[order.room].name) }}</td>
    </tr>
  </table>

  <!-- 연락처, 지불방법 등 -->
  <app-order-contact [order]="order"></app-order-contact>

  <div class="dl">
    <div class="dt" *ngIf="order.simpleNo">간단 번호: {{ order.simpleNo }}</div>
    <div class="dt" *ngIf="order.orderMsg && order.orderMsg.length > 0">요청 사항 :</div>
    <div class="dd" *ngIf="order.orderMsg && order.orderMsg.length > 0"><span class="copyable" (click)="copyToClipboard($event)">{{ order.orderMsg }}</span></div>
    <div class="dt" *ngIf="order.contextStatusCode >= 15 && order.cookMinutes">조리 시간: <span class="inline-dd">{{ order.cookMinutes }}분</span></div>
    <div class="dt">주문 채널: <span style="font-weight: normal; font-size:16px">{{ channel }}</span></div>
  </div>

  <!-- menu -->
  <app-menu [order]="order"></app-menu>

  <div class="order-actions">
    <div *ngIf="order.contextStatusCode >= 10 && order.contextStatusCode < 20" class="deliveryExpTime">조리시간<span *ngIf="order.cookMinutes"> (업소접수조리시간 : {{ order.cookMinutes }}분)</span></div>
    <div *ngIf="order.contextStatusCode >= 10 && order.contextStatusCode < 20">
      <mat-radio-group [value]="cookMinutes" (change)="onChangeCookMinutes($event)">
        <mat-radio-button *ngFor="let option of cookMinutesOptions" [value]="option" [checked]="option === cookMinutes">{{ option + '분' }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="buttons">
      <button *ngIf="isDialog" mat-raised-button cdkFocusInitial (click)="onOk()">닫기</button>
      <!-- 취소이면서 아직 확인되지 않은 주문에서만 나타나는 버튼-->
      <button *ngIf="order.contextStatusCode == 80"
        mat-raised-button color="accent"
        (click)="onAction('CANCEL_CONFIRMED')">취소 사유 확인</button>

      <button *ngIf="order.contextStatusCode >= 10 && order.contextStatusCode < 20" mat-raised-button class="ACCEPTED" (click)="onAction('ACCEPT')" [disabled]="buttonPressed.ACCEPT" color="accent">주문 접수</button>
      <button *ngIf="order.contextStatusCode < 60" mat-raised-button (click)="onAction('CANCEL')" [disabled]="buttonPressed.CANCEL" class="cancel" color="accent">주문 {{ order.orderStatusCode === 10 ? '거부' : '취소' }}...</button>
      <button mat-raised-button (click)="onAction('SYNC')" [disabled]="buttonPressed.SYNC" color="warn">주문 동기화</button>
    </div>
    <div class="buttons">
      <button *ngIf="userService.user.role === 'admin'" mat-raised-button (click)="onAction('DELETE')" [disabled]="buttonPressed.DELETE" class="delete" color="accent">주문 삭제</button>
    </div>
  </div>
  <div class="orderId">{{ order.orderNo }}</div>
</div>

