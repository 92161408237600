<form [formGroup]="orderMemoForm" class="formGroup">
  <div class="deliveryVendor">배송 벤더</div>
  <div>
    <mat-select disableRipple formControlName="cherrypickStatus" class="cherrypickStatus" placeholder="&lt;선별&gt;">
      <mat-option value="??">&lt;선별&gt;</mat-option>
      <mat-option value="ghostrider">고스트</mat-option>
      <mat-option value="3rd">부릉</mat-option>
      <mat-option value="self">셀프</mat-option>
      <mat-option value="preorder">예약</mat-option>
    </mat-select>
    <mat-select  disableRipple formControlName="ghostriderName" class="ghostriderName" panelClass="longSelectPanel" placeholder="라이더명">
      <mat-option *ngFor="let name of ghostriderNames" [value]="name">{{ name }}</mat-option>
    </mat-select>
  </div>
  <mat-form-field class="orderMemo" appearance="outline" [hideRequiredMarker]="true" floatLabel="always">
    <mat-label>관리자 메모</mat-label>
    <textarea matInput formControlName="adminMemo" placeholder="이슈 등을 기록한다"></textarea>
  </mat-form-field>

  <mat-form-field class="orderMemo" appearance="outline" [hideRequiredMarker]="true" floatLabel="always">
    <mat-label>POS 메모</mat-label>
    <textarea matInput formControlName="posMemo" placeholder="POS에 노출되는 메모를 기록한다."></textarea>
  </mat-form-field>

  <div *ngIf="orderMemoForm.dirty" class="note">왼편의 '주문 접수', '취소 사유 확인' 등의 버튼을 눌러도 함께 저장됩니다.</div>
  <div class="buttons">
    <button mat-raised-button (click)="onCancel()">취소</button>
    <button mat-raised-button (click)="onSubmit(false)" type="submit" color="accent" [disabled]="orderMemoForm.pristine">저장</button>
    <button mat-raised-button (click)="onSubmit(true)" type="submit" color="accent">저장 후 알림</button>
  </div>
</form>