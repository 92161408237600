/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { DialogRawDataComponent } from './dialog-raw-data.component';

@Injectable({
  providedIn: 'root'
})
export class DialogRawDataService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialog(collectionName: string, docId: string) {
    // 다른 다일로그는 닫는다.
    this.dialog.closeAll();

    // 쿠팡이츠는 _id 값을 파싱하여 orderNo를 조회해야 한다.
    const dialogRef = this.dialog.open(DialogRawDataComponent, {
      data: {
        collectionName,
        docId
      },
      minWidth: '200px',
      maxWidth: '800px',
    });

    return dialogRef;
  }
}
