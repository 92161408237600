/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
// tslint:disable: no-console
import { Moment } from 'moment';
import { BehaviorSubject } from 'rxjs';

import { Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSelectionListChange } from '@angular/material/list';

/**
 * Angular material에서 date picker만 있고 calendar는 따로 없다.
 * Data picker가 사용하는 calendar를 이용하는 방법을 소개한 아래의 문서를 참고했다.
 * refer: https://onthecode.co.uk/angular-material-calendar-component/
 */

export const DATE_TIME = new InjectionToken<{}>('DATE_TIME');
@Component({
  selector: 'app-date-time-range-picker',
  templateUrl: './date-time-range-picker.component.html',
  styleUrls: ['./date-time-range-picker.component.scss'],
})
export class DateTimeRangePickerComponent implements OnInit {
  public selectedDateTime: {
    date: string;
    time: string;
    dateInput: 'start' | 'end';
    useTimeSelector: boolean;
  } = {
    date: '2020-01-01',
    time: '00:00:00',
    dateInput: 'start',
    useTimeSelector: false
  };

  public hours: string[];
  public selectedHour: string;

  /** startDate, endDate 중 관련된 input을 구분하는 값 */
  public dateInput: 'start' | 'end';

  constructor(
    private dateAdapter: DateAdapter<Moment>,
    @Inject(DATE_TIME) public data: BehaviorSubject<{
      date: string,
      time: string,
      dateInput: 'start' | 'end',
      useTimeSelector: boolean
    }>
  ) {
    // 일, 월, 화, 수, 목, 금, 토
    this.dateAdapter.setLocale('toe-kr');
    this.hours = Array(24).fill(0).map((x, y) => String(x + y).padStart(2, '0'));   // 0 ~ 24
  }

  ngOnInit(): void {
    this.selectedDateTime = this.data.getValue();
    this.selectedHour = this.selectedDateTime.time.substring(3, 8) === '00:00' ? this.selectedDateTime.time.substring(0, 2) : this.selectedDateTime.time;
  }

  public selectedChange(d: Moment) {
    this.selectedDateTime.date = d.format('YYYY-MM-DD');

    // 시간은 변경하지 않는다.
    // if (!this.selectedDateTime.useTimeSelector) {
    //   this.selectedDateTime.time = '00:00:00';
    // }

    this.data.next(this.selectedDateTime);
  }

  public onChangeTime(event: MatSelectionListChange) {
    const selectedTime = event.source.selectedOptions.selected[0].value;
    // 시간 선택을 통해서는 항상 00분 00초로 설정된다.
    this.selectedDateTime.time = `${selectedTime}:00:00`;
    this.data.next(this.selectedDateTime);
  }
}
