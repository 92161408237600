/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { takeWhile } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Route } from '@angular/compiler/src/core';

import { AccountService } from './core/3/account.service';

@Injectable({
  providedIn: 'root'
})
export class AccountInitGuardService {

  constructor(
    private accountService: AccountService
  ) { }


  /**
   * promise는 onetime이기 때문에 take operator를 이용해서 1개만 취한다.
   * 처음에는 서버에서 받아온 값이 아니기 때문에 filter를 이용해서 서버의 값만 넘긴다.
   */
  promiseForInit() {
    return new Promise<boolean>(async (resolve, reject) => {
      const accountSubject = this.accountService.latestAccountSubject;

      try {
        await accountSubject
          .pipe(
            takeWhile(accounts => {
              return accounts.length > 0 ? false : true;
            })
          ).toPromise();
      } catch (err) {
        reject(err);
      }

      resolve(true);
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(`canActivate: '${route.url}'`);

    return this.promiseForInit();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(`canActivateChild: '${childRoute.url}'`);

    return this.promiseForInit();
  }

  canLoad(route: Route) {
    // console.log(`canLoad: '${route.path}'`);

    return this.promiseForInit();
  }
}
