<div class="container">
  <h1>{{ status }}</h1>
  <!-- Time Table -->
  <table class="timetable">
    <thead>
      <tr><th></th> <th class="timefield">절대시각</th> <th class="timefield">신청기준</th> <th class="timefield">현재기준</th> </tr>
    </thead>
    <tbody>
      <tr *ngFor="let timestamp of sortedTimes; let last = last"
        [class.last]="last"
        [class.notice]="timestamp.class === 'notice'"
        [class.blink]="timestamp.class === 'blink'">
        <th>{{ timestamp.name }}</th>
        <td class="center">{{ timestamp.date | date:'HH:mm:ss':'+09:00' }}</td>
        <td class="right">{{ timestamp.relativeToCreatedAt }}</td>
        <td class="right">{{ timestamp.relativeToNow }}</td>
      </tr>
    </tbody>
  </table>

  <!-- Delivery Form -->
  <form [formGroup]="deliveryForm">
    <app-address-form [order]="address" [room]="delivery.room" *ngIf="isShowAddressForm" (addressConfirmed)="addressConfirm($event)"></app-address-form>
    <mat-form-field floatLabel="always">
      <mat-label>번지주소</mat-label>
      <input matInput type="text" [class.changed]="changedField('address_key')" formControlName="address_key">
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>도로명주소</mat-label>
      <input matInput type="text" [class.changed]="changedField('address_road')" formControlName="address_road">
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>상세주소</mat-label>
      <input matInput type="text" [class.changed]="changedField('address_detail')" formControlName="address_detail">
    </mat-form-field>
    <div class="flex-row counter-field">
    <mat-form-field floatLabel="always">
      <mat-label>픽업요청</mat-label>
      <input matInput type="text" [class.changed]="changedField('requestedPickupTime')" formControlName="requestedPickupTime">
    </mat-form-field>
    <div class="counter">
      <button mat-button [disabled]="!enableRequestedPickupTime" (click)="subMinute()">-</button>
      <input matInput value="0" formControlName="requestedPickupTimeMinute">
      <button mat-button [disabled]="!enableRequestedPickupTime" (click)="addMinute()">+</button>
    </div>
  </div>
  <mat-form-field floatLabel="always">
      <mat-label>고객전화</mat-label>
      <input matInput type="text" [class.changed]="changedField('userTel')" formControlName="userTel" required>
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>요청사항</mat-label>
      <input matInput type="text" [class.changed]="changedField('orderMsg')" formControlName="orderMsg" required>
    </mat-form-field>
    <div class="flex-row select-field">
      <mat-form-field floatLabel="always">
        <mat-label>결제방법</mat-label>
        <mat-select formControlName="initialPaymentMethod" [class.changed]="changedField('initialPaymentMethod')" required>
          <mat-option value="선불">선불</mat-option>
          <mat-option value="후불카드">후불카드</mat-option>
          <mat-option value="후불현금">후불현금</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field floatLabel="always">
        <mat-label>결제금액</mat-label>
        <input matInput type="number" [class.changed]="changedField('initialPaymentAmount')"  formControlName="initialPaymentAmount" required>
      </mat-form-field>
    </div>
  </form>

  <!-- delivery info -->
  <table>
    <tbody>
      <tr><th>배달대행사</th><td>{{ deliveryVendor }}</td></tr>
      <tr><th>상점명</th><td>{{ shopName }}</td></tr>
      <tr><th>정산배송비</th><td>{{ delivery.totalFee | number }}원</td></tr>
      <tr *ngIf="delivery.riderName"><th>라이더</th><td>{{ delivery.riderName }} ({{ riderTel }})</td></tr>
      <tr class="dim"><th>상점번호</th><td>{{ delivery.storeNo }}</td></tr>
      <tr class="dim"><th>배차번호</th><td>{{ delivery._id }}</td></tr>
      <tr class="dim"><th>인스턴스ID</th><td>{{ delivery.instanceNo }}</td></tr>
    </tbody>
  </table>

  <!-- button -->
  <div class="buttons">
    <button mat-raised-button (click)="onClose()">닫기</button>
    <button mat-raised-button color="primary" *ngIf="delivery.deliveryStatusCode <= 30" [disabled]="deliveryForm.invalid" (click)="modifyDelivery()">변경</button>
    <button mat-raised-button color="primary" *ngIf="delivery?.address_location?.lat" (click)="openDestMap()">지도 열기</button>
    <button mat-raised-button color="warn" *ngIf="delivery.deliveryStatusCode < 40" (click)="cancelDelivery()">배송 취소</button>
  </div>
  <div class="buttons" *ngIf="userService.user.role === 'admin'">
    <button mat-raised-button color="warn" (click)="openRawDataDialog()">관련 raw 데이터 보기 및 변경</button>
  </div>
</div>
