/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalConfigurationService {
  durationSubject = new BehaviorSubject<number>(1);
  siteSubject = new BehaviorSubject<string[]>([]);
  hideSelfDeliverySubject = new BehaviorSubject<boolean>(false);

  constructor() { }
}
