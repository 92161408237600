/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
/**
 * refer: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
 *
 * @export
 */
@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor() { }

  private fallbackCopyTextToClipboard(text, tempContainer: HTMLElement) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    if (tempContainer) {
      tempContainer.appendChild(textArea);
    } else {
      document.body.appendChild(textArea);
    }
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    if (tempContainer) {
      tempContainer.removeChild(textArea);
    } else {
      document.body.appendChild(textArea);
    }
  }

  copyTextToClipboard(text, tempContainer: HTMLElement = null) {
    console.log(text);
    if (!(navigator as any).clipboard) {
      this.fallbackCopyTextToClipboard(text, tempContainer);
      return;
    }
    (navigator as any).clipboard.writeText(text).then(() => {
      console.log('Async: Copying to clipboard was successful!');
    }, (err) => {
      console.error('Async: Could not copy text: ', err);
    });
  }
}
