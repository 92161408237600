/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';

// import { debugLog } from '../../core/1/common';
import { diffTime, toDate } from '../../core/2/util';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
  // baseDate는 현재 기준 미래 시각이다.
  // 지원하는 형식은 toDate() 함수를 참조하라.
  @Input() public isFuture = false;  // 현재 기준 남은 시간(true)이냐? 경과한 시간(false)이냐?
  @Input() public isDim = false; // css class에 영향을 준다.
  @Input() public onlyMinute = false;
  @Input() public thresholdM = 99999999; // 현재 시간 기준 차이가 thresholdM 분을 넘으면 CSS class 설정
  @Input() public suffix = '';

  private baseDate0: string | number | Date;
  @Input('baseDate0') set _baseDate0(value: string | number | Date) {
    this.baseDate0 = value;

    this.updateBaseDate();
  }

  // 분 단위가 변결될 때 마다 알림을 보낸다.
  @Output() public signalMinute = new EventEmitter<number>();

  public timeM: number;
  public timeS: string;

  private baseDate: Date;
  private timerId: any;
  private prevSignalM = 0;
  private baseSecond: number; // reschedule 계산에 사용

  constructor() { }

  ngOnInit() {
    this.updateBaseDate();
  }

  ngOnDestroy() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = undefined;
    }

    // 디버깅용
    // debugLog(`TimerComponent::ngOnDestroy() for ${this.baseDate0}`);
  }

  updateBaseDate() {
    if (this.baseDate0 === undefined) {
      console.error(`baseDate0@TimeComponent === undefined`);
      return;
    }
    // 반복적인 변환을 막기 위해 Date 포맷으로 변환한다.
    this.baseDate = toDate(this.baseDate0);
    this.baseSecond = this.baseDate.getSeconds();

    // debugLog(`TimerComponent::updateBaseDate() to ${this.baseDate}`);

    // onlyMinute은 baseDate 기준으로 60초 간격으로
    // 그렇지 않은 경우에는 1초 간격으로
    this.reschedule(this.onlyMinute ? 60 : 1);
  }

  /**
   * 1분 간격으로 업데이트하는 경우에 사용한다.
   * 밀치초 단위의 정확한 간격을 요구하지 않는다.
   */
  reschedule(stepSecond = 60) {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = undefined;
    }

    this.updateTimeDisplay();

    const now = new Date();
    const nowSecond = now.getSeconds();

    // nowSecond가 다음번 baseSecond가 되도록 한다.
    if (this.onlyMinute) {
      // 현재 시간 기준으로 몇 초가 지나야 다음번 baseSecond와 같은 시간 혹은 지나서가 되느냐
      let delay = ((60 + this.baseSecond) - nowSecond) % stepSecond;
      if (delay === 0) {
        delay = stepSecond;
      }

      // 디버깅용
      // debugLog(`baseSecond = ${this.baseSecond}, nowSecond = ${nowSecond}, delay = ${delay} for ${this.baseDate0}`);

      this.timerId = setTimeout(() => this.reschedule(), delay * 1000);
    }
  }

  updateTimeDisplay() {
    const now = new Date();
    const d = this.isFuture ? diffTime(now, this.baseDate) : diffTime(this.baseDate, now);
    this.timeM = d.m;
    this.timeS = d.sStr;

    // 분이 변경되었으므로 알림을 보낸다.
    if (this.prevSignalM < d.m) {
      this.signalMinute.emit(d.m);
      this.prevSignalM = d.m;

      // debugLog(`TimerComponent::updateTimeDisplay() emit ${d.m}`);
    }
  }
}
