<table class="contact">
  <thead>
    <tr>
      <th>유형</th>
      <th>이름</th>
      <th>전화번호</th>
      <th>명령</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let contact of fastContacts">
      <td>{{ contact.category }}</td>
      <td>{{ contact.name }}</td>
      <td>{{ telFormatter(contact.telNo) }}</td>
      <td>
        <button mat-raised-button color="primary" (click)="dial(contact)"><mat-icon>call</mat-icon></button>
        <button mat-raised-button color="primary" (click)="openDialogSendSms(contact)"><mat-icon>sms</mat-icon></button>
      </td>
    </tr>
  </tbody>
</table>
