<div class="order-container-background" [ngClass]="cssClass" [class.shortHeight]="false">
    <!-- 제목 배경 용도로 자리만 잡는다.  -->
    <div></div>
  </div>


  <div class="order-container" [ngClass]="cssClass">
    <h3>
      <span class="date"><span *ngIf="!isTodayOrder">{{ order.orderDate | date:'M-d':'+0900' }} ({{ weekdayKR(order.orderDate) }}) </span>{{ order.orderDate | date:'HH:mm':'+0900' }}</span>

      <span *ngIf="order.address_dong && order.address_jibun">{{ order.address_dong }} {{ order.address_jibun }}</span>
      <span *ngIf="!order.address_dong || !order.address_jibun">{{ unifiedDeliveryTypeMappings[order.deliveryType] }}</span>

      <span class="status">{{ statusString }}</span>
    </h3>

    <table class="delivery">
      <tr >
        <td>{{ order.shopName }} / {{ trimOrganization(roomService.rooms[order.room].name) }}</td>
      </tr>
    </table>

    <div *ngIf="order.deliveryType === 'TAKEOUT'" class="tags">
      <span>포장</span>
    </div>
    <div *ngIf="order.deliveryType === 'HERE'" class="tags">
      <span>매장식사</span>
    </div>

    <!-- 연락처, 지불방법 등 -->
    <app-order-contact [order]="order"></app-order-contact>

    <div class="dl">
      <div class="dt" *ngIf="order.simpleNo">간단 번호: {{ order.simpleNo }}</div>
      <div class="dt" *ngIf="order.orderMsg && order.orderMsg.length > 0">요청 사항 :</div>
      <div class="dd" *ngIf="order.orderMsg && order.orderMsg.length > 0"><span class="copyable" (click)="copyToClipboard($event)">{{ order.orderMsg }}</span></div>
      <div class="dt" *ngIf="order.contextStatusCode >= 15 && order.posRequestedPickupMinutes">POS 요청픽업시간: <span style="font-weight: normal; font-size:16px">{{ order.posRequestedPickupMinutes }}분</span></div>
      <div class="dt" *ngIf="order.contextStatusCode >= 15 && order.posDeliveryMinutes">POS 예상배달시간: <span style="font-weight: normal; font-size:16px">{{ order.posDeliveryMinutes }}분</span></div>
      <div class="dt" *ngIf="order.contextStatusCode >= 15 && (!order.posRequestedPickupMinutes && order.cookMinutes)">조리 시간: <span style="font-weight: normal; font-size:16px">{{ order.cookMinutes }}분</span></div>
      <div class="dt" *ngIf="order.contextStatusCode >= 20 && order.deliveryMinutes">예상 배달 시간: <span style="font-weight: normal; font-size:16px">{{ order.deliveryMinutes }}분</span></div>
      <div class="dt">주문 채널: <span style="font-weight: normal; font-size:16px">{{ channel }}</span></div>
    </div>

    <!-- menu -->
    <app-menu [order]="order"></app-menu>

    <div class="order-actions">
      <!-- 조리 시간을 설정하는 것은 의미없다. -->
      <div *ngIf="order.contextStatusCode >= 10 && order.contextStatusCode < 20" class="deliveryExpTime">예상 배달 시간<span *ngIf="!order.posRequestedPickupMinutes && order.cookMinutes"> (조리시간 : {{ order.cookMinutes }}분)</span></div>
      <div *ngIf="order.contextStatusCode >= 10 && order.contextStatusCode < 20">
        <mat-radio-group [value]="deliveryMinutes" (change)="onChangeDeliveryExpTime($event)">
          <mat-radio-button *ngFor="let option of deliveryMinutesOptions" [value]="option" [checked]="option === deliveryMinutes">{{ option + '분' }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="buttons">
        <button *ngIf="isDialog" mat-raised-button cdkFocusInitial (click)="onOk()">닫기</button>

        <!-- 취소이면서 아직 확인되지 않은 주문에서만 나타나는 버튼-->
        <button *ngIf="order.contextStatusCode == 80"
        mat-raised-button color="accent"
        (click)="onAction('CANCEL_CONFIRMED')">취소 사유 확인</button>

        <!-- 결제 대기 중인 주문은 취소가 아닌 삭제 대상 -->
        <button *ngIf="order.createdBy === 'face' && order.contextStatusCode == 95"
          mat-raised-button [disabled]="buttonPressed.DELETE" class="delete" color="accent"
          (click)="onAction('DELETE')">주문 삭제</button>

        <!-- 취소 주문은 삭제 가능 (보통 테스트 주문) -->
        <button *ngIf="order.contextStatusCode == 80"
          mat-raised-button [disabled]="buttonPressed.DELETE" class="delete" color="accent"
          (click)="onAction('DELETE')">주문 삭제</button>

        <button *ngIf="order.contextStatusCode >= 10 && order.contextStatusCode < 20"
          mat-raised-button class="ACCEPTED" [disabled]="buttonPressed.ACCEPT" color="accent"
          (click)="onAction('ACCEPT')">주문 접수</button>

        <button *ngIf="order.contextStatusCode >= 20 && order.contextStatusCode < 70"
          mat-raised-button class="COMPLETED" [disabled]="buttonPressed.COMPLETE" color="accent"
          (click)="onAction('COMPLETE')">배달 완료</button>

        <button *ngIf="order.contextStatusCode < 80"
          mat-raised-button [disabled]="buttonPressed.CANCEL" class="cancel" color="accent"
          (click)="onAction('CANCEL')">주문 {{ order.orderStatusCode === 10 ? '거부' : '취소' }}...</button>
      </div>
      <div class="buttons">
        <button *ngIf="userService.user.role === 'admin'"
          mat-raised-button
          [disabled]="buttonPressed.DELETE" class="delete" color="accent"
          (click)="onAction('DELETE')">주문 삭제</button>
        <button *ngIf="userService.user.role === 'admin'"
          mat-raised-button
          [disabled]="buttonPressed.STAGING" class="delete" color="accent"
          (click)="onAction('STAGING')">결제 대기</button>
        <button *ngIf="userService.user.role === 'admin' && order.createdBy === 'manual'"
          mat-raised-button
          [disabled]="buttonPressed.CANCEL" class="delete" color="accent"
          (click)="onAction('CANCEL')">주문 취소</button>
      </div>
    </div>
    <div class="orderId">{{ order.orderNo }}</div>
  </div>

