/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { UtilService } from '../1/util.service';


// TODO: 나중에 전화기 부자되면 DB로 옮긴다.
// authKey는 비번을 sha512를 적용한다.
//
// echo -n 'lguplus1!' | shasum -a 512
// 67c543b817a71b79435c0172a9128a850946edc325bedd63165aea94e31d783e7d9ea5b483a37f6f6cc7608ff867b32d3b42ce2ba1c9ba154c4b5fed7ddc8fcf
//
// echo -n 'lguplus5!' | shasum -a 512
// ffa6dd1d19e4e45923173c66477a603101c62a017553fb50bd4a2027912f03a67dd868442cb46b1319097a118904f0573cb8a05855045bcc6eba070edbbc1d09
//
const authKeys = {
  '07048482567': {
    user: '0',
    // telNo: '07048482567',
    // 2021-03-19 lguplus7!
    authKey: 'b9814ab5dff2d6761085bd9e5ef7ced80124fe946327e37a0c31b685e3fee910d42032b69500259f85c8db60ddfd6d69a09077eb500dc9955ae1f56c64ab911b'
  },
  '07048482568': {
    user: '1',
    // telNo: '07048482568',
    // 2021-03-19 qkfrkfkr2!
    // 2021-06-16 qkfrkfkr3!
    authKey: '8c109fc6457c7498b255198a4fdeb3afd2f208610e408a5603c42e249b824df59eb1bcca029ce8609a9679d34797f72919d74084ce27fa6997a0807563f2baf9'
  },
  '07048482569': {
    user: '2',
    // telNo: '07048482569',
    // 2021-03-19 qkfrkfkr2!
    authKey: '2bd58f049508bbea0d19feffdda6eaf546c1194d9be11c3e64a800b629a1734b2822747ff8592624f02558b2c5567f07f6b398ac94aee3f8e81d68a5ed5aba2e'
  },
  '07048482563': {
    user: '3',
    // 2021-06-16 qkfrkfkr3!
    authKey: '8c109fc6457c7498b255198a4fdeb3afd2f208610e408a5603c42e249b824df59eb1bcca029ce8609a9679d34797f72919d74084ce27fa6997a0807563f2baf9'
  },
  '07048482744': {
    user: '4',
    authKey: '67c543b817a71b79435c0172a9128a850946edc325bedd63165aea94e31d783e7d9ea5b483a37f6f6cc7608ff867b32d3b42ce2ba1c9ba154c4b5fed7ddc8fcf'
  },
  '07048482745': {
    user: '5',
    authKey: '67c543b817a71b79435c0172a9128a850946edc325bedd63165aea94e31d783e7d9ea5b483a37f6f6cc7608ff867b32d3b42ce2ba1c9ba154c4b5fed7ddc8fcf'
  },
  // 송파점
  // {
  //   telNo: '07048482208',
  //   authKey: '67c543b817a71b79435c0172a9128a850946edc325bedd63165aea94e31d783e7d9ea5b483a37f6f6cc7608ff867b32d3b42ce2ba1c9ba154c4b5fed7ddc8fcf'
  // },
};

export interface Telephone {
  number: string;
  user: string;
}

@Injectable({
  providedIn: 'root'
})
export class TelephoneService {

  constructor(
    private utilService: UtilService
  ) { }

  /**
   * 전화기 리스트를 가져온다.
   * - 네비바 상단 전화기 버튼에 보여줄 값
   */
  public getTelephoneList(): Telephone[] {
    return Object.entries(authKeys).map(([key, value]) => ({ number: key, user: value.user }));
  }

  /**
   * LG Uplus 전화기에 다이얼 명령을 내린다.
   *
   * 응답 예
   *
   * 에러 예
   * 200 OK
   * {
   *   "SVC_RT": "1002",
   *   "SVC_MSG": "PARAM_ERR",
   *   "DATAS": " ID: or pass Paramerer length ERROR0/0/"
   * }
   *
   * 성공 예
   * {
   *   "SVC_RT": "0000",
   *   "SVC_MSG": "OK",
   *   "DATAS": {
   *   "STATUS": "OK",
   *   "DESTNUMBER": "01034675530"
   *   }
   * }
   *
   * @param srcNo 2020-03-02일 현재 사용하고 있는 전화번호는 3개
   */

  async dial(srcNo: string, destNo: string) {
    srcNo = srcNo.replace(/[- ]/g, '');
    destNo = destNo.replace(/[- ]/g, '');

    if (authKeys[srcNo] === undefined) {
      this.utilService.toastrError(`${srcNo}가 처음보는 전화번호입니다. 추가가 필요합니다. K에게 알려주세요.`);
      return undefined;
    }

    const formData = [
      `id=${encodeURIComponent(srcNo)}`,
      `pass=${encodeURIComponent(authKeys[srcNo].authKey)}`,
      `destnumber=${encodeURIComponent(destNo)}`
    ].join('&');

    const requestInit: RequestInit = {
      method: 'POST',
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData
    };

    // return fetch('https://us-central1-toe-dev-2019.cloudfunctions.net/centrex/dial', requestInit);
    return fetch('https://us-central1-toe-prod.cloudfunctions.net/centrex/dial', requestInit);
  }

  /**
   * user는 사용자 정의 값으로 편의상 붙인 전화기 이름이나 번호가 된다.
   * authKeys에 등록되어 있어야 한다.
   */
  async dialByUser(user: string, destNo: string) {
    if (user === 'X') {
      throw new TypeError('전화기 사용안함 상태입니다.');
    }

    for (const [srcNo, value] of Object.entries(authKeys)) {
      if (value.user === user) {
        return this.dial(srcNo, destNo);
      }
    }

    throw new Error(`등록된 user(${user})가 없습니다.`);
  }
}
