/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, Input, OnInit } from '@angular/core';

import { CallOutputRequestDeliveryInformation } from '../../schema/4/schema-functions-call';

import { deliveryVendorMappings } from '../../core/1/string-map';

@Component({
  selector: 'app-delivery-information',
  templateUrl: './delivery-information.component.html',
  styleUrls: ['./delivery-information.component.scss']
})
export class DeliveryInformationComponent implements OnInit {
  @Input() deliveryInfo: (CallOutputRequestDeliveryInformation & {
    deliveryVendor: string;
    instanceNo: string;
  });

  public deliveryVendorMappings = deliveryVendorMappings;

  constructor() { }

  ngOnInit(): void { }
}
