/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { CellClassParams, ColDef, ColGroupDef, Module, ValueGetterParams } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MenuModule } from '@ag-grid-enterprise/menu';

import { UnifiedDeliveryDoc, UnifiedDeliveryStatusCode, UnifiedOrderDoc } from '../../../schema/3/schema';

import { addressFormatter, forDecimal, forDynamicDecimal, forDynamicPlain, forPlain, forTime, telFormatter } from '../../../core/1/ag-util';
import { deliveryStatusCodeMappings, deliveryVendorMappings } from '../../../core/1/string-map';
import { trimOrganization, diffTime } from '../../../core/2/util';
import { FirebaseManagerService } from '../../../core/4/firebase-manager.service';
import { RoomService } from '../../../core/4/room.service';

import { CustomTooltipComponent } from '../../custom-tooltip/custom-tooltip.component';
import { UnifiedDeliveryTimerRendererComponent } from '../../../pages/unified-delivery/unified-delivery-timer-renderer/unified-delivery-timer-renderer.component';

@Component({
  selector: 'app-related-delivery',
  templateUrl: './related-delivery.component.html',
  styleUrls: ['./related-delivery.component.scss']
})
export class RelatedDeliveryComponent implements OnInit, OnDestroy {
  @Input() order: UnifiedOrderDoc;

  public modules: Module[] = [
    MenuModule,             // 우클릭할 때 표시되는 메뉴 UI
    ClipboardModule,        // 행 전체 복사 등의 기능 제공
    ExcelExportModule,
    ColumnsToolPanelModule, // 개별 컬럼을 on/off 하는 UI가 tool panel에 추가
  ];

  public defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    tooltipComponentFramework: CustomTooltipComponent, // custom tooltip 사용
  };

  public columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: '기본',
      children: [
        forPlain('_id', '_id', 280, { columnGroupShow: 'open' }),
        forPlain('관련주문', 'relatedOrderId', 280, { columnGroupShow: 'open' }),
        forPlain('상태', 'deliveryStatusCode', 72, { refData: deliveryStatusCodeMappings }),
        forPlain('벤더', 'deliveryVendor', 72, { refData:  deliveryVendorMappings }),
        forTime('요청시각', 'timeSubmitted', 90, 'HH:mm'),
        forTime('요청/예상픽업', 'requestedPickupTime', 110, 'HH:mm', {
          valueGetter: (params: ValueGetterParams) => {
            const doc = params.data as UnifiedDeliveryDoc;
            if (doc) {
              const pickupTime = doc.adjustedPickupTimes?.length > 0 ? doc.adjustedPickupTimes[doc.adjustedPickupTimes.length - 1].adjustedTime : doc.requestedPickupTime;
              return doc.deliveryVendor === 'barogo' ? doc.barogoPickupExpectedAt : pickupTime;
            }
            return false;
          }
        }),
        forPlain('라이더명', 'riderName', 116),
        forPlain('라이더전화', 'riderTel', 130, { valueFormatter: params => telFormatter(params.value) }),
        forDecimal('거리', 'distance', 70),
        forDecimal('배달비', 'totalFee', 75, 0, { headerTooltip: 'totalFee\nVAT별도', tooltipValueGetter: params => `${params.value}(VAT별도)` }),
        forDynamicDecimal('주문금액', 88, params => {
          const doc: UnifiedDeliveryDoc = params.data;
          if (doc) {
            return doc.finalPaymentAmount ? doc.finalPaymentAmount : doc.initialPaymentAmount;
          }
          return false;
        }),
        forDynamicPlain('결제수단', 88, params => {
          const doc: UnifiedDeliveryDoc = params.data;
          if (doc) {
            return doc.finalPaymentMethod ? doc.finalPaymentMethod : doc.initialPaymentMethod;
          }
          return false;
        }, { enableRowGroup: true }),
      ]
    },
    {
      headerName: '상세정보',
      children: [
        forDynamicPlain('업소명', 160, params => {
          const doc: UnifiedDeliveryDoc = params.data;
          // 바로고는 없어서 roomDoc의 이름으로 채운다.
          return doc?.storeName ? doc.storeName : this.roomService.rooms[doc?.room]?.shopName;
        }, { headerTooltip: 'storeName', columnGroupShow: 'open' }),
        forDynamicPlain('조직', 86, params => {
          const delivery: UnifiedDeliveryDoc = params.data;
          return delivery?.organization ? this.roomService.rooms[delivery.room]?.organizationName : '';
        }, { hide: true, headerTooltip: 'organization' }),
        forDynamicPlain('지점', 124, params => {
          const delivery: UnifiedDeliveryDoc = params.data;
          return delivery ? trimOrganization(this.roomService.rooms[delivery.room]?.siteName) : '';
        }, { hide: true, headerTooltip: 'site', columnGroupShow: 'open' }),
        forDynamicPlain('호실', 124, params => {
          const delivery: UnifiedDeliveryDoc = params.data;
          return delivery ? trimOrganization(this.roomService.rooms[delivery.room]?.name) : '';
        }, { headerTooltip: 'room', columnGroupShow: 'open' }),
        forTime('요청시각', 'timeSubmitted', 152, 'dd ccc HH:mm:ss'),
        forTime('요청픽업시각', 'requestedPickupTime', 152, 'dd ccc HH:mm:ss'),
        forTime('바로고예상픽업시각', 'timeSubmibarogoPickupExpectedAttted', 152, 'dd ccc HH:mm:ss'),
        forTime('최종픽업조정', 'lastAdjustedPickupTime', 152, 'dd ccc HH:mm:ss', {
          headerTooltip: '마지막 픽업조정시각: adjustedPickupTimes[LAST].adjustedTime',
          valueGetter: (params: ValueGetterParams) => {
            const doc: UnifiedDeliveryDoc = params.data;

            if (doc?.adjustedPickupTimes && doc.adjustedPickupTimes.length > 0) {
              return doc.adjustedPickupTimes[doc.adjustedPickupTimes.length - 1].adjustedTime;
            }
            return undefined;
          },
        }),
        forDecimal('픽업까지', 'pickupRemaining', 88, 0, {
          colId: 'pickupRemaining', headerTooltip: '픽업까지 남은 시간으로 POS에 보이는 시간과 일치', cellRendererFramework: UnifiedDeliveryTimerRendererComponent,
        }),
        forDynamicDecimal('조리시간', 74, params => {
          const doc: UnifiedDeliveryDoc = params.data;

          if (doc) {
            const { m: actual } = diffTime(doc.timeSubmitted, doc.requestedPickupTime, true);
            return actual;
          }
          return undefined;
        }),
        forDynamicDecimal('픽업오차', 88, params => {
          const doc: UnifiedDeliveryDoc = params.data;

          if (doc?.requestedPickupTime && doc.timePickedUp) {
            const pickupTime = doc.adjustedPickupTimes && doc.adjustedPickupTimes.length > 0 ?
              doc.adjustedPickupTimes[doc.adjustedPickupTimes.length - 1].adjustedTime : doc.requestedPickupTime;
            const { m: actual } = diffTime(pickupTime, doc.timePickedUp, true);
            // 양수면 늦은 것
            return actual;
          }
          return undefined;
        }, 0, { colId: 'pickupOffset', headerTooltip: '픽업시각 - (요청시각 + 조리시간)' }),
        forDynamicDecimal('이동시간', 88, params => {
          const delivery: UnifiedDeliveryDoc = params.data;

          if (delivery) {
            if (delivery.timeDelivered && delivery.timePickedUp) {
              const { m: elapsedMinutes } = diffTime(delivery.timePickedUp, delivery.timeDelivered);
              return elapsedMinutes;
            }
          }
          return undefined;
        }, 0, { colId: 'movingMinutes', headerTooltip: '완료시각 - 픽업시각' }),
        forTime('배차시각', 'timeAssigned', 60, 'HH:mm'),
        forTime('픽업시각', 'timePickedUp', 60, 'HH:mm'),
        forTime('완료시각', 'timeDelivered', 60, 'HH:mm'),
        forTime('취소시각', 'timeCanceled', 60, 'HH:mm'),
        forPlain('실결제수단', 'finalPaymentMethod', 96, {
          headerTooltip: 'finalPaymentMethod: 최종 결제 수단',
          cellClassRules: {
            notice: (params: CellClassParams) => {
              const doc: UnifiedDeliveryDoc = params.data;
              if (doc) {
                return doc.deliveryStatusCode === UnifiedDeliveryStatusCode.DELIVERED && doc.initialPaymentMethod !== doc.finalPaymentMethod;
              }
              return false;
            }
          }
        }),
        forDecimal('실결제금액', 'finalPaymentAmount', 95),
        forDecimal('후불카드', 'cardAmount', 95, 0, {
          cellClassRules: {
            notice: (params: CellClassParams) => {
              const doc: UnifiedDeliveryDoc = params.data;
              if (doc) {
                // 복합 결제
                return doc.cardAmount > 0 && doc.cashAmount > 0;
              }
              return false;
            }
          }
        }),
        forDecimal('후불현금', 'cashAmount', 95, 0, {
          cellClassRules: {
            notice: (params: CellClassParams) => {
              const doc: UnifiedDeliveryDoc = params.data;
              if (doc) {
                // 복합 결제
                return doc.cardAmount > 0 && doc.cashAmount > 0;
              }
              return false;
            }
          }
        }),
        forPlain('업소코드', 'storeNo', 88, { columnGroupShow: 'open' }),
        forPlain('관련주문ID', 'relatedOrderId', 262, { columnGroupShow: 'open' }),
        forPlain('고객전화번호', 'userTel', 126, { valueFormatter: params => telFormatter(params.value) }),
        forPlain('주소', 'address_key', 146, { valueFormatter: params => addressFormatter(params.value) }),
        forPlain('도로명주소', 'address_road', 170, { valueFormatter: params => addressFormatter(params.value) }),
        forPlain('상세주소', 'address_detail', 146),
        forPlain('위도', 'address_location.lat', 156, { columnGroupShow: 'open' }),
        forPlain('경도', 'address_location.lon', 156, { columnGroupShow: 'open' }),
        forDecimal('주문금액', 'initialPaymentAmount', 88),
        forPlain('요청결제수단', 'initialPaymentMethod', 106, {
          headerTooltip: 'initialPaymentMethod: 최초에 요청한 결제 수단', enableRowGroup: true,
          cellClassRules: {
            notice: (params: CellClassParams) => {
              const doc: UnifiedDeliveryDoc = params.data;
              if (doc) {
                return doc.deliveryStatusCode === UnifiedDeliveryStatusCode.DELIVERED && doc.initialPaymentMethod !== doc.finalPaymentMethod;
              }
              return false;
            }
          }
        }),
        forPlain('주문메모', 'orderMsg', 295),
      ]
    },
    {
      headerName: '배달비(VAT별도)',
      children: [
        forDecimal('기본요금', 'baseFee', 88, 0, {
          headerTooltip: 'baseFee\nVAT별도', tooltipValueGetter: params => `${params.value}(VAT별도)`
        }),
        forDynamicDecimal('할증요금', 88, params => {
          const doc: UnifiedDeliveryDoc = params.data;
          if (doc?.totalFee !== undefined && doc?.baseFee !== undefined) {
            return doc.totalFee - doc.baseFee;
          }
          return undefined;
        }, 0, {
          headerTooltip: 'extraFee\nVAT별도',
          tooltipValueGetter: params => `${params.value}(VAT별도)`,
        }),
        forDecimal('전체요금', 'totalFee', 88, 0, {
          headerTooltip: 'totalFee\nVAT별도', tooltipValueGetter: params => `${params.value}(VAT별도)`
        }),
      ]
    },
  ];

  rowData: UnifiedDeliveryDoc[];

  private destroySignal = new Subject<boolean>();

  constructor(
    private roomService: RoomService,
    private firebaseManager: FirebaseManagerService,
  ) { }

  ngOnDestroy() {
    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  ngOnInit() {
    this.firebaseManager.observe<UnifiedDeliveryDoc>('unifiedDelivery', [['relatedOrderId', '==', this.order._id]], { sortKey: 'timeSubmitted', orderBy: 'asc' })
      .pipe(
        takeUntil(this.destroySignal)
      )
      .subscribe(deliveries => this.rowData = deliveries);
  }

  public getRowNodeId(data: UnifiedDeliveryDoc) {
    return data._id;
  }
}
