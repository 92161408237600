/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { ConfVendor } from '../../schema/1/schema-common';
import { UnifiedAccountDoc } from '../../schema/2/schema-account';
import { RoomDoc } from '../../schema/3/schema';

import { UtilService } from '../1/util.service';
import { UserService } from '../2/user.service';
import { FirebaseManagerService } from '../4/firebase-manager.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public latestAccountSubject = new BehaviorSubject<UnifiedAccountDoc<ConfVendor>[]>([]);

  public unifiedAccount: { [id: string]: UnifiedAccountDoc<ConfVendor> };

  constructor(
    private utilService: UtilService,
    private userService: UserService,
    private firebaseManagerService: FirebaseManagerService,
  ) { }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  public observeAccounts() {
    const organization = this.userService.organization;

    this.firebaseManagerService.observe<UnifiedAccountDoc<ConfVendor>>('unifiedAccount', [['organization', '==', organization]]).subscribe(accounts => {
      this.unifiedAccount = Object.fromEntries(accounts.map(account => [account._id, account]));

      this.latestAccountSubject.next(accounts);
    });
  }

  public getNextInstanceNo(room: RoomDoc, vendor: string) {
    const matches = room.room.match(/^([A-Za-z]{2})-([^-]+)-([0-9]{2})$/);
    if (matches) {
      const organizationCode = matches[1];
      const roomNo = matches[3];

      const roomKey = `${organizationCode}${room.siteNo}${roomNo.padStart(3, '0')}`;

      // 실제로 어디까지 번호가 붙어있는지 확인해야한다.
      const instanceNumbers = Object.values(this.unifiedAccount)
        .filter(account => account.vendor === vendor)
        .map($account => $account.instanceNo)
        .reduce((acc, cur) => {
          const regExp = new RegExp(`^${roomKey}(\\d{2})$`);
          const exec = regExp.exec(cur);
          if (exec) {
            acc.push(Number(exec[1]));
          }

          return acc;
        }, [0]);

      return `${roomKey}${String(Math.max(...instanceNumbers) + 1).padStart(2, '0')}`;
    } else {
      // 에러 메시지
      this.utilService.toastrError('getNextInstanceNo _roomKey 형식 오류');
      return undefined;
    }
  }
}
