/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { parseISO, addMinutes, subMinutes } from 'date-fns';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { LogOrderDoc, UnifiedOrderDoc } from '../../schema/3/schema';

const collectionPath = 'logOrder';

@Injectable({
  providedIn: 'root'
})
export class LogOrderService {

  constructor(private db: AngularFirestore) { }

  /**
   * '주문 처리 이력'에 표시한 logOrder를 추출한다.
   * 그 당시의 'room' 로그를 조회한다.
   *
   */
  public observeRoom(order: UnifiedOrderDoc) {
    const orderDate = order.orderDate;
    const before10M = subMinutes(parseISO(orderDate), 10);
    const after3H = addMinutes(parseISO(orderDate), 180);

    const queryFn: QueryFn = ref => {
      const query1 = ref
        .where('orderId', '==', 'room')
        .where('room', '==', order.room)
        .where('_timeCreate', '>=', before10M)
        .where('_timeCreate', '<=', after3H);
      const query = query1.orderBy('_timeCreate', 'desc');
      return query;
    };

    const collectionRef = this.db.collection<LogOrderDoc>(collectionPath, queryFn);

    console.log(`${this.constructor.name}::observe ${collectionPath}`);

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = collectionRef.valueChanges();

    return observable;
  }

  /**
   * '주문 처리 이력'에 표시한 logOrder를 추출한다.
   */
  public observeOrder(order: UnifiedOrderDoc) {
    const queryFn: QueryFn = ref => {
      const query = ref
        .where('orderId', '==', order._id)
        .where('room', '==', order.room);
      return query;
    };

    const collectionRef = this.db.collection<LogOrderDoc>(collectionPath, queryFn);

    console.log(`${this.constructor.name}::observe ${collectionPath}`);

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = collectionRef.valueChanges();

    return observable;
  }
}
