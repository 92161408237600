/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { RoomDoc, RoomDocs, MemoDoc } from '../../schema/3/schema';
import { UserService } from '../2/user.service';
import { SiteService } from '../3/site.service';

const collectionPath = 'room';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class RoomService {
  public rooms: RoomDocs = {};
  public latestSubject = new BehaviorSubject<RoomDocs>({});

  public latestSelfDeliveryRoomKeysSubject = new BehaviorSubject<string[]>([]);

  private selfDeliveryRoomKeys: string[] = [];

  constructor(
    private db: AngularFirestore,
    private siteService: SiteService,
    private userService: UserService
  ) {
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  public observe() {
    const organization = this.userService.organization;
    const queryFn: QueryFn = ref => ref.where('organization', '==', organization);

    const collection = this.db.collection<RoomDoc>(collectionPath, queryFn);
    collection.valueChanges().subscribe(docs => {
      this.rooms = docs.reduce((acc, doc) => { acc[doc._id] = doc; return acc; }, {});
      this.latestSubject.next(this.rooms);
      this.checkSelfDeliveryChange();
    });
  }

  public updateRoomField(roomKey: string, fieldPath: string, value: any) {
    return this.db.doc(`${collectionPath}/${roomKey}`).update({
      [fieldPath]: value
    });
  }

  /**
   * 해당 room에 대해서 vroong instance No(계정) 배열을 리턴한다.
   */
  public vroongInstanceNosForRoom(roomKey: string) {
    return this.rooms[roomKey]?.account?.vroong ?? [];
  }

  /**
   * 해당 room에 대해서 run2u instance No(계정) 배열을 리턴한다.
   * schema에서는 account.combinenet이 배열이지만 현재 기준으로는 1개만 사용한다.
   */
  public run2uInstanceNosForRoom(roomKey: string): string[] {
    return this.rooms[roomKey]?.account?.run2u ?? [];
  }

  /**
   * 해당 room에 대해서 spidor instance No(계정) 배열을 리턴한다.
   * schema에서는 account.combinenet이 배열이지만 현재 기준으로는 1개만 사용한다.
   */
  public spidorInstanceNosForRoom(roomKey: string): string[] {
    return this.rooms[roomKey]?.account?.spidor ?? [];
  }

  /**
   * 해당 room에 대해서 barogo instance No(계정) 배열을 리턴한다.
   * schema에서는 account.barogo 배열이지만 현재 기준으로는 1개만 사용한다.
   */
  public barogoInstanceNosForRoom(roomKey: string): string[] {
    return this.rooms[roomKey]?.account?.barogo ?? [];
  }

  /**
   * 해당 room에 대해서 logiall instance No(계정) 배열을 리턴한다.
   * schema에서는 account.logiall 배열이지만 현재 기준으로는 1개만 사용한다.
   */
  public logiallInstanceNosForRoom(roomKey: string): string[] {
    return this.rooms[roomKey]?.account?.logiall ?? [];
  }

  /**
   * room의 quiet 설정에만 관심이 있다
   * quiet 설정이 있고 true가 아닌 경우에는 false
   */
  public quietForRoomKey(roomKey: string): boolean {
    return this.rooms[roomKey]?.quiet ? true : false;
  }

  public setRoomMemo(roomNo: string, memo: Partial<MemoDoc>) {
    const id = this.db.firestore.collection('memos').doc().id;
    const timestamp = firestore.FieldValue.serverTimestamp() as firestore.Timestamp;

    const doc: Partial<MemoDoc> = {
      ...memo,
      _timeCreate: timestamp
    };

    return this.db.doc(`${collectionPath}/${roomNo}`).update({
      [`memos.${id}`]: doc
    });
  }

  public updateRoomMemo(roomNo: string, id: string, memo: Partial<MemoDoc>) {
    if (!id) {
      throw new Error('id field must exist');
    }

    const docRef = this.db.firestore.collection('room').doc(roomNo);
    const timestamp = firestore.FieldValue.serverTimestamp() as firestore.Timestamp;

    return this.db.doc(docRef).update({
      [`memos.${id}.email`]: memo.email,
      [`memos.${id}._timeUpdate`]: timestamp,
      [`memos.${id}.content`]: memo.content,
    });
  }

  public deleteRoomMemo(roomNo: string, id: string) {
    const docRef = this.db.firestore.collection('room').doc(roomNo);
    return docRef.update({
      [`memos.${id}`]: firestore.FieldValue.delete()
    });
  }

  private checkSelfDeliveryChange() {
    const newSelfDeliveryRoomKeys = Object.values(this.rooms)
      .filter(room => room.live && room.selfDelivery === true)
      .map(room => room.room);

    if (this.selfDeliveryRoomKeys.join(',') !== newSelfDeliveryRoomKeys.join(',')) {
      console.log(`${this.selfDeliveryRoomKeys.join(',')} => ${newSelfDeliveryRoomKeys.join(',')}`);
      this.selfDeliveryRoomKeys = newSelfDeliveryRoomKeys;
      this.latestSelfDeliveryRoomKeysSubject.next(this.selfDeliveryRoomKeys);
    }
  }
}
