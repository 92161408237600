<app-manual-order-action *ngIf="order.createdBy === 'manual' || order.orderVendor === 'ghostkitchen'"
  [order]="order" [isDialog]="true" (actionDone)="actionDone($event)"></app-manual-order-action>

<app-baemin-order-action *ngIf="order.createdBy !== 'manual' && order.orderVendor === 'baemin'"
  [order]="order" [isDialog]="true" (actionDone)="actionDone($event)"></app-baemin-order-action>

<app-yogiyo-order-action *ngIf="order.createdBy !== 'manual' && order.orderVendor === 'yogiyo'"
  [order]="order" [isDialog]="true" (actionDone)="actionDone($event)"></app-yogiyo-order-action>

<app-coupangeats-order-action *ngIf="order.createdBy !== 'manual' && order.orderVendor === 'coupangeats'"
  [order]="order" [isDialog]="true" (actionDone)="actionDone($event)"></app-coupangeats-order-action>