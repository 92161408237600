/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { ContactDoc } from '../../schema/3/schema-ops';

import { UserService } from '../2/user.service';


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private collectionPath = 'contact';

  constructor(
    private db: AngularFirestore,
    private userService: UserService
  ) { }

  public async setContact(contact: Partial<ContactDoc>) {
    const docRef0 = this.db.firestore.collection(this.collectionPath).doc();
    const id = docRef0.id;
    const timestamp = firestore.FieldValue.serverTimestamp() as firestore.Timestamp;

    const organization = this.userService.organization;

    const doc: Partial<ContactDoc> = { ...contact,
      _id: id,
      _timeCreate: timestamp,
      _organization: organization // ghostkitchen
    };

    const docRef = this.db.firestore.doc(`${this.collectionPath}/${id}`);

    return await this.db.doc<ContactDoc>(docRef).set(doc as ContactDoc);
  }

  public async updateContact(contact: Partial<ContactDoc>) {
    if (contact._id == null) {
      throw new Error('_id field must exist');
    }

    const docRef = this.db.firestore.collection(this.collectionPath).doc(contact._id);
    const timestamp = firestore.FieldValue.serverTimestamp() as firestore.Timestamp;

    const doc: Partial<ContactDoc> = { ...contact,
      _timeUpdate: timestamp,
    };

    return await this.db.doc<Partial<ContactDoc>>(docRef).update(doc);
  }

  public async deleteContact(id: string) {
    if (id == null) {
      throw new Error('id field must exist');
    }

    return await this.db.collection(this.collectionPath).doc(id).delete();
  }

  public getRoomContact(roomKey: string) {
    const queryFn: QueryFn = ref => {
      return ref.where('_room', '==', roomKey);
    };

    const contactCollection = this.db.collection<ContactDoc>(this.collectionPath, queryFn);

    return contactCollection.get();
  }
}
