/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import Swal from 'sweetalert2';
import get from 'lodash-es/get';
import cloneDeep from 'lodash-es/cloneDeep';
import { addMinutes, format } from 'date-fns';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/functions';

import { UnifiedDeliveryDoc, UnifiedDeliveryStatusCode, UnifiedOrder } from '../../schema/3/schema';
import {
  CallInputCancelDelivery,
  CallInputModifyDelivery,
  CallOutputCancelDelivery,
  CallOutputModifyDelivery
} from '../../schema/4/schema-functions-call';

import { telFormatter } from '../../core/1/ag-util';
import { UtilService } from '../../core/1/util.service';
import { barogoOrderCancelTypeMapping, deliveryStatusCodeMappings, deliveryVendorMappings } from '../../core/1/string-map';
import { toDate, diffTime, trimOrganization, normalizingTel } from '../../core/2/util';
import { UserService } from '../../core/2/user.service';
import { LogService } from '../../core/3/log.service';
import { RoomService } from '../../core/4/room.service';

import { DialogSpinnerService } from '../dialog-spinner/dialog-spinner.service';
import { DialogRawDataService } from '../dialog-raw-data/dialog-raw-data.service';

interface TIMESTAMP {
  name: string;
  path?: string;
  date?: Date;
  relativeToCreatedAt?: string;
  relativeToNow?: string;
  class?: string;
}

interface Address {
  address_key?: string;
  address_dong?: string;
  address_dongH?: string;
  address_road?: string;
  address_building_name?: string;
  address_detail?: string;
  address_location?: { lat: number; lon: number; };
  address_sido?: string;
  address_sigungu?: string;
  address_jibun?: string;
}

const labelMapping = {
  requestedPickupTime: '픽업요청',
  address_key: '번지주소',
  address_road: '도로명주소',
  address_detail: '상세주소',
  userTel: '고객전화',
  orderMsg: '요청사항',
  initialPaymentMethod: '결제방법',
  initialPaymentAmount: '결제금액'
};

@Component({
  selector: 'app-dialog-unified-delivery-status',
  templateUrl: './dialog-unified-delivery-status.component.html',
  styleUrls: ['./dialog-unified-delivery-status.component.scss']
})
export class DialogUnifiedDeliveryStatusComponent implements OnInit, OnDestroy {
  public delivery: UnifiedDeliveryDoc;

  // UI에 적합한 형태로 변환
  public status = '??';
  public shopName = '';
  public riderTel: string;
  public deliveryVendor: string;

  public sortedTimes: TIMESTAMP[];
  private times: {
    [key: string]: TIMESTAMP;
  } = {
      created_at: {
        name: '신청',
        path: 'timeSubmitted',
      },
      assigned_at: {
        name: '배차',
        path: 'timeAssigned',
      },
      picked_up_at: {
        name: '픽업',
        path: 'timePickedUp',
      },
      requested_pick_up_at: {
        name: '*요청픽업*',
        path: 'requestedPickupTime',
      },
      barogo_pickup_expected_at: {
        name: '*바로고예상픽업*',
        path: 'barogoPickupExpectedAt',
      },
      adjusted_pick_up_at: {
        name: '*픽업조정(라이더)*'
      },
      delivered_at: {
        name: '완료',
        path: 'timeDelivered',
      },
      now: {
        name: '--- 현재 ---',
        class: 'blink'
      },
    };

  public deliveryForm: FormGroup;

  public enableRequestedPickupTime = false;
  public isShowAddressForm = false;
  public address: Address;

  private destroySignal = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { delivery: UnifiedDeliveryDoc },
    public userService: UserService,
    public dialogRef: MatDialogRef<DialogUnifiedDeliveryStatusComponent, boolean>,
    private fb: FormBuilder,
    private fns: AngularFireFunctions,
    private roomService: RoomService,
    private utilService: UtilService,
    private logService: LogService,
    private dialogSpinnerService: DialogSpinnerService,
    private dialogRawDataService: DialogRawDataService,
  ) {
    this.delivery = cloneDeep(data.delivery);
  }

  ngOnInit() {
    this.status = deliveryStatusCodeMappings[this.delivery.deliveryStatusCode] ?? '몰라';
    this.riderTel = this.delivery?.riderTel ? telFormatter(this.delivery.riderTel) : '';
    this.shopName = `${trimOrganization(this.roomService.rooms[this.delivery.room]?.name)} ${this.delivery?.storeName ?? this.roomService.rooms[this.delivery.room]?.shopName}`;
    this.deliveryVendor = deliveryVendorMappings[this.delivery.deliveryVendor];

    this.address = {
      address_key: this.delivery.address_key,
      address_dongH: this.delivery.address_dongH,
      address_road: this.delivery.address_road,
      address_detail: this.delivery.address_detail,
      address_location: this.delivery.address_location,
      address_sido: this.delivery.address_sido,
      address_sigungu: this.delivery.address_sigungu,
      address_dong: this.delivery.address_dong,
      address_jibun: this.delivery.address_jibun,
    };

    this.initForm();
    this.setModifiableField();
    this.updateTimes();

    this.observeUserTel();
  }

  ngOnDestroy() {
    this.dialogRef?.close();

    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  /**
   * 요청 픽업 시간 더하기
   * 최대 90분
   */
  public addMinute() {
    const requestedPickupTime = this.deliveryForm.get('requestedPickupTime');
    const requestedPickupTimeMinute = this.deliveryForm.get('requestedPickupTimeMinute');
    const max = 90;

    if (requestedPickupTimeMinute.value >= max) {
      this.utilService.toastrInfo(`최대 ${max}분까지 조정이 가능합니다.`);
      return;
    }

    requestedPickupTimeMinute.setValue(requestedPickupTimeMinute.value + 5);
    requestedPickupTime.setValue(this.formatDateTimeZone(false, addMinutes(new Date(this.delivery.requestedPickupTime), requestedPickupTimeMinute.value)));
  }

  /*
   * 요청 픽업 시간 빼기
   * 최소 0분
   */
  public subMinute() {
    const requestedPickupTime = this.deliveryForm.get('requestedPickupTime');
    const requestedPickupTimeMinute = this.deliveryForm.get('requestedPickupTimeMinute');
    const min = 0;

    if (requestedPickupTimeMinute.value <= min) {
      return;
    }

    requestedPickupTimeMinute.setValue(requestedPickupTimeMinute.value - 5);
    requestedPickupTime.setValue(this.formatDateTimeZone(false, addMinutes(new Date(this.delivery.requestedPickupTime), requestedPickupTimeMinute.value)));
  }

  public addressConfirm(address: Partial<UnifiedOrder>) {
    this.deliveryForm.get('address_key').setValue(address.address_key);
    this.deliveryForm.get('address_detail').setValue(address.address_detail);
    if (address.address_road) {
      this.deliveryForm.get('address_road').setValue(address.address_road);
    } else {
      this.deliveryForm.get('address_road').setValue('');
    }
  }

  /**
   * 기존 값과 달라진(변경된) 값을 반환한다.
   */
  public checkFormValue(): { key: string, newValue: string | number, oldValue: string | number }[] {
    const changes = [];
    const keys = ['requestedPickupTime', 'address_key', 'address_road', 'address_detail', 'userTel', 'orderMsg', 'initialPaymentMethod', 'initialPaymentAmount'];

    keys.forEach(key => {
      const newValue = this.deliveryForm.get(key).value;
      const oldValue = key === 'requestedPickupTime' ? this.formatDateTimeZone(false, this.delivery[key]) :
        key === 'userTel' ? normalizingTel(this.delivery[key]) : this.delivery[key];

      if (newValue !== oldValue) {
        changes.push({ key, newValue, oldValue });
      }
    });

    return changes;
  }

  /**
   * 필드 변경 여부를 반환
   */
  public changedField(key: string) {
    if (key === 'requestedPickupTime') {
      // 픽업요청시간은 실제 포맷과 폼에 보여주는 포맷이 달라서 포맷을 맞춰준다.
      return this.deliveryForm.get(key).value !== this.formatDateTimeZone(false, this.delivery.requestedPickupTime);
    } else if (key === 'userTel') {
      // userTel은 전화번호 형식을 적용한 후 비교한다.
      return this.deliveryForm.get(key).value !== normalizingTel(this.delivery.userTel);
    } else {
      return this.deliveryForm.get(key).value !== this.delivery[key];
    }
  }

  public onClose() {
    this.dialogRef?.close(false);
    this.dialogRef = undefined;
  }

  /**
   * 배차 수정
   */
  public async modifyDelivery() {
    if (!['manna', 'barogo', 'logiall', 'run2u', 'spidor', 'zendeli', 'shero', 'dalgo', 'iudream', 'baedalyo', 'baedalhero'].includes(this.delivery.deliveryVendor)) {
      Swal.fire('해당 배달 대행사는 배차 변경이 불가합니다');
      return;
    }

    const changes = this.checkFormValue();

    if (changes.length === 0) {
      Swal.fire('변경한 값이 없습니다.');
      return;
    }

    const changeMessage = changes.reduce((acc, field) => {
      return acc += `<tr><th>${labelMapping[field.key]}<th><td>${field.oldValue}</td><td>${field.newValue}</td></tr>`;
    }, '');

    const { isConfirmed } = await Swal.fire({
      title: '배차 정보를 변경하시겠습니까?',
      html: `<table>${changeMessage}</table>`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: '변경',
      cancelButtonText: '취소',
    });

    if (isConfirmed) {
      const delivery: Partial<UnifiedDeliveryDoc> = {
        // logOrder에 사용하기 위해 정보를 추가한다.
        relatedOrderId: this.delivery.relatedOrderId,
        organization: this.delivery.organization,
        site: this.delivery.site,
        room: this.delivery.room,

        deliveryVendor: this.delivery.deliveryVendor,
        deliveryStatusCode: this.delivery.deliveryStatusCode,
        deliveryNo: this.delivery.deliveryNo
      };

      /**
       * 변경된 값을 요청 값에 추가해준다.
       * address, payment는 한 가지라도 변경된 것이 있으면 연관된 값을 다 채워서 보내준다.
       */
      changes.forEach(change => {
        if (change.key === 'address_road' || change.key === 'address_key' || change.key === 'address_detail') {
          delivery.address_key = this.deliveryForm.get('address_key').value;
          delivery.address_road = this.deliveryForm.get('address_road').value;
          delivery.address_detail = this.deliveryForm.get('address_detail').value;
        } else if (change.key === 'requestedPickupTime') {
          delivery.requestedPickupTime = this.formatDateTimeZone(true, this.deliveryForm.get('requestedPickupTime').value);
        } else if (change.key === 'orderMsg') {
          delivery.orderMsg = this.deliveryForm.get('orderMsg').value;
        } else if (change.key === 'initialPaymentMethod' || change.key === 'initialPaymentAmount') {
          delivery.initialPaymentAmount = this.deliveryForm.get('initialPaymentAmount').value;
          delivery.initialPaymentMethod = this.deliveryForm.get('initialPaymentMethod').value;
        } else if (change.key === 'userTel') {
          delivery.userTel = this.deliveryForm.get('userTel').value.replace(/-/g, '');
        }
      });

      const callInput: CallInputModifyDelivery = {
        delivery
      };

      const relatedOrder = { _id: this.delivery.relatedOrderId, site: this.delivery.site, room: this.delivery.room };

      this.logService.logOrder(relatedOrder,
        `'변경' 버튼을 눌러 배차 정보 변경 요청\ndeliveryVendor: ${delivery.deliveryVendor}, deliveryNo: ${delivery.deliveryNo}, changeValues: ${JSON.stringify(changes)}`
      );

      try {
        const callable = this.fns.httpsCallable<CallInputModifyDelivery, CallOutputModifyDelivery>('callModifyDelivery');
        const response = await callable(callInput).toPromise();

        if (response.result === 'success') {
          this.logService.logOrder(relatedOrder, `배차 수정 성공 ${JSON.stringify(response)}`);
          this.utilService.toastrInfo(`배차를 수정했습니다. ${response.reason}`);
          Swal.fire('알림', '변경되었습니다.', 'success');
          this.dialogRef?.close(false);
          this.dialogRef = undefined;
        } else {
          this.logService.logOrder(relatedOrder, `배차 수정 실패 ${JSON.stringify(response)}`, 'error');
          this.logService.withToastrError(`배차 수정 실패 ${response.result}: ${response.reason}`);
        }
      } catch (error) {
        this.logService.logOrder(relatedOrder, `배차 수정 중 예외 발생 ${JSON.stringify(error)}`, 'error');
        this.logService.withToastrError(`배차 수정 중 예외 발생 ${JSON.stringify(error)}`);
      }
    }
  }

  public async cancelDelivery() {
    const { deliveryVendor, deliveryNo, relatedOrderId, site, room, organization } = this.delivery;

    if (!['run2u', 'spidor', 'barogo', 'logiall', 'manna', 'zendeli', 'shero', 'dalgo', 'iudream', 'baedalyo', 'baedalhero'].includes(deliveryVendor)) {
      Swal.fire('취소가 불가한 배달대행사입니다');
      return;
    }

    const callInput: CallInputCancelDelivery = {
      organization,
      site,
      room,
      deliveryVendor,
      deliveryNo
    };

    if (deliveryVendor === 'barogo') {
      // 취소 사유를 선택받는다.
      const { value } = await Swal.fire({
        title: '취소 사유를 선택해주세요',
        input: 'select',
        inputOptions: barogoOrderCancelTypeMapping,
        inputPlaceholder: '취소 사유 선택',
        showCancelButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '확인'
      });

      if (!value) {
        return;
      }

      callInput.cancelReason = value;
    }

    const relatedOrder = { _id: relatedOrderId, site, room };

    this.logService.logOrder(
      relatedOrder,
      `'배송 취소' 버튼을 눌러 배차 취소 요청\ndeliveryVendor: ${deliveryVendor}, deliveryNo: ${deliveryNo}`
    );

    let spinnerRef = this.dialogSpinnerService.openSpinnerDialog('취소 요청 보내는 중');

    try {
      const callable = this.fns.httpsCallable<CallInputCancelDelivery, CallOutputCancelDelivery>('callCancelDelivery');
      const response = await callable(callInput).toPromise();

      spinnerRef?.close(true);
      spinnerRef = undefined;

      if (response.result === 'success') {
        this.logService.logOrder(relatedOrder, `배차 취소 성공 ${JSON.stringify(response)}`);
        this.utilService.toastrInfo(`배차를 취소했습니다. ${response.reason}`);
        this.dialogRef?.close(false);
        this.dialogRef = undefined;
      } else {
        this.logService.logOrder(relatedOrder, `배차 취소 실패 ${JSON.stringify(response)}`, 'error');
        this.logService.withToastrError(`배차 취소 실패 ${response.result}: ${response.reason}`);
      }
    } catch (error) {
      spinnerRef?.close(true);
      spinnerRef = undefined;

      this.logService.logOrder(relatedOrder, `배차 취소 중 예외 발생 ${JSON.stringify(error)}`, 'error');
      this.logService.withToastrError(`배차 취소 중 예외 발생 ${JSON.stringify(error)}`);
    }
  }

  /**
   * 지도 열기
   */
  public openDestMap() {
    const delivery = this.delivery;

    const name = encodeURI(`[${delivery?.distance}m] ${delivery.address_key} (${delivery.address_road})${delivery.riderName ? ' [' + delivery.riderName + ']' : ''}`)
      .replace(/,/g, ''); // COMMA 제거
    const url = 'http://map.daum.net/link/map/' + name + `,${delivery.address_location.lat},${delivery.address_location.lon}`;

    // refer : http://apis.map.daum.net/web/guide/#bigmapurl
    // http://map.daum.net/?urlX=506255.0&urlY=1113450.0&name=%EB%85%BC%ED%98%84%EB%8F%99%2B170-14
    window.open(
      url,
      delivery.address_key, // 번지가 다르면 새 창에서 열리도록 하기 위함
      'centerscreen,width=1600,height=1080,alwaysRaised,noreferrer'
    );
  }

  /**
   * 관련 raw 데이터
   */
  public openRawDataDialog() {
    this.dialogRawDataService.openDialog('unifiedDelivery', this.delivery._id);
  }

  private initForm() {
    this.deliveryForm = this.fb.group({
      requestedPickupTime: this.formatDateTimeZone(false, this.delivery.requestedPickupTime) ?? '',
      requestedPickupTimeMinute: 0,
      address_key: this.delivery.address_key ?? '',
      address_road: this.delivery.address_road ?? '',
      address_detail: this.delivery.address_detail ?? '',
      userTel: [normalizingTel(this.delivery.userTel) ?? '', Validators.pattern(/^(0[157][01][1-9]?-[0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/)],
      orderMsg: this.delivery.orderMsg ?? '',
      initialPaymentMethod: this.delivery.initialPaymentMethod ?? '',
      initialPaymentAmount: this.delivery.initialPaymentAmount ?? 0,
    });
  }

  /**
   * userTel에 변화가 있으면 포맷을 자동 적용한다.
   */
  private observeUserTel() {
    const formControl = this.deliveryForm.get('userTel');

    formControl.valueChanges
      .pipe(takeUntil(this.destroySignal))
      .subscribe(value => {
        const normalizedTel = normalizingTel(value);
        if (value !== normalizedTel) {
          this.deliveryForm.get('userTel').setValue(normalizedTel);
        }
      });
  }

  /**
   * 벤더, 상태 별로 form에 변경이 가능한 필드를 enable 한다.
   */
  private setModifiableField() {
    const delivery = this.delivery;
    this.deliveryForm.disable();

    if (delivery.deliveryVendor === 'barogo') {
      // pickup 전까지 수정 가능한 필드 enable
      if (delivery.deliveryStatusCode < UnifiedDeliveryStatusCode.PICKED_UP) {
        // assigned 전까지 수정 가능한 필드 enable
        if (delivery.deliveryStatusCode < UnifiedDeliveryStatusCode.ASSIGNED) {
          this.enableRequestedPickupTime = true;
        }
        this.isShowAddressForm = true;
        this.deliveryForm.get('initialPaymentMethod').enable();
        this.deliveryForm.get('initialPaymentAmount').enable();
        this.deliveryForm.get('userTel').enable();
        this.deliveryForm.get('orderMsg').enable();
      }
    } else if (delivery.deliveryVendor === 'logiall') {
      // 완료, 취소전 상태까지 모두 수정 가능
      this.isShowAddressForm = true;
      this.deliveryForm.get('initialPaymentMethod').enable();
      this.deliveryForm.get('initialPaymentAmount').enable();
      this.deliveryForm.get('orderMsg').enable();
    } else if (['run2u', 'spidor', 'manna', 'zendeli', 'shero', 'dalgo', 'iudream', 'baedalyo', 'baedalhero'].includes(delivery.deliveryVendor)) {
      this.enableRequestedPickupTime = true;
      this.isShowAddressForm = true;
      this.deliveryForm.get('initialPaymentMethod').enable();
      this.deliveryForm.get('initialPaymentAmount').enable();
      this.deliveryForm.get('userTel').enable();
      this.deliveryForm.get('orderMsg').enable();
    }
  }

  /**
   * time table을 만들어준다.
   */
  private updateTimes() {
    // delivery 속성만 업데이트 (path가 있는 경우)
    for (const timestamp of Object.values(this.times)) {
      const path = timestamp.path;
      if (path) {
        const timeString = get(this.delivery, path, undefined);
        if (timeString) {
          timestamp.date = toDate(timeString);
        }
      }
    }

    // 요청 픽업 계산하기
    this.times.requested_pick_up_at.date = new Date(this.delivery.requestedPickupTime);

    // now 업데이트
    this.times.now.date = new Date();

    // 픽업조정이 있으면 마지막 조정 시각으로 추가해준다.
    if (this.delivery?.adjustedPickupTimes?.length) {
      this.times.adjusted_pick_up_at.date = new Date(this.delivery.adjustedPickupTimes[this.delivery.adjustedPickupTimes.length - 1].adjustedTime);
    }

    // filter and sort
    this.sortedTimes = Object.values(this.times)
      .filter(timestamp => timestamp.date)
      .sort((t1, t2) => {
        return t1.date < t2.date ? -1 :
          t1.date > t2.date ? 1 : 0;
      });

    const now = this.times.now.date;
    const createdAt = this.times.created_at.date;

    // 상대시간 업데이트
    for (const timestamp of this.sortedTimes) {
      const t2 = timestamp.date;
      {
        const t1 = createdAt;
        const { m, sStr } = t1 <= t2 ? diffTime(t1, t2) : diffTime(t2, t1);
        timestamp.relativeToCreatedAt = t1 <= t2 ? `${m}:${sStr}` : `-${m}:${sStr}`;
      }
      {
        const t1 = now;
        const { m, sStr } = t1 <= t2 ? diffTime(t1, t2) : diffTime(t2, t1);
        timestamp.relativeToNow = t1 <= t2 ? `${m}:${sStr}` : `-${m}:${sStr}`;
      }
    }
  }

  /**
   * Date formatting
   * @param showTimezone 타임존 보임 여부
   * @return 'yyyy-MM-dd\'T\'HH:mm:ssXXX' | 'yyyy-MM-dd HH:mm:ss'
   */
  private formatDateTimeZone(showTimezone: boolean, date: string | Date) {
    if (!date) {
      date = '2000-01-01T00:00:00+09:00';
      this.utilService.toastrError(`date가 없어서 20000으로 임시 표시`);
    }

    const formatString = showTimezone ? 'yyyy-MM-dd\'T\'HH:mm:ssXXX' : 'yyyy-MM-dd HH:mm:ss';
    return date instanceof Date ? format(date, formatString) : format(new Date(date), formatString);
  }
}
