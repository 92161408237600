<div class="container">
  <h2 mat-h2>[배차 추가] {{ order.shopName }} / {{ trimOrganization(roomService.rooms[order.room].name) }}</h2>
  <h3 *ngIf="relatedDeliveriesMsg">{{ relatedDeliveriesMsg }}</h3>
  <div class="flex-container">
    <div class="left">
      <app-address-form #addressForm [order]="order" [room]="order.room" (addressConfirmed)="onAddressConfirmed($event)"></app-address-form>

      <!-- 주소만 -->
      <app-order-contact [showTelAndPrice]="false" [order]="order" [prefixMsg]="prefixMsg"></app-order-contact>

      <form [formGroup]="requestDeliveryForm" class="request-delivery-form">
        <div class="fieldGroup">
          <mat-form-field class="userTel" [hideRequiredMarker]="true" floatLabel="always">
            <mat-label>고객 전화번호</mat-label>
            <input matInput type="tel" formControlName="userTel" placeholder="숫자만 입력하세요">
            <mat-error *ngIf="requestDeliveryForm.get('userTel').invalid">{{ requestDeliveryForm.get('userTel').errors['reason'] }}</mat-error>
          </mat-form-field>

          <mat-form-field class="paymentMethod" [hideRequiredMarker]="true" floatLabel="always">
          <mat-select formControlName="payment_method" placeholder="결제 방법" required>
              <mat-option value="PREPAID">선불</mat-option>
              <mat-option value="CREDIT_CARD">후불카드</mat-option>
              <mat-option value="CASH">후불현금</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="deliveryValue" [hideRequiredMarker]="true" floatLabel="always">
            <mat-label>결제 금액</mat-label>
            <input matInput formControlName="delivery_value" type="number" min="0" max="1000000" step="100" required>
            <mat-error *ngIf="requestDeliveryForm.get('delivery_value').invalid">문제가 있어요.</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="deliveryVendors.length > 1" class="deliveryVendor">배달 대행</div>
        <div *ngIf="deliveryVendors.length > 1">
          <mat-radio-group formControlName="deliveryVendor">
            <mat-radio-button *ngFor="let deliveryVendor of deliveryVendors" [value]="deliveryVendor">{{ deliveryVendorMappings[deliveryVendor] }}</mat-radio-button>
          </mat-radio-group>
        </div>

        <!--------------------------------------------- 요청 픽업 --------------------------------------------->

        <div class="pickupOffset">요청 픽업 시간<span *ngIf="order.cookMinutes"> (조리시간 {{ order.cookMinutes }}분)</span></div>
        <div>
          <mat-radio-group formControlName="pickupOffset">
            <mat-radio-button *ngFor="let option of deliveryParams.pickups" [value]="option">{{ option + '분' }}</mat-radio-button>
          </mat-radio-group>
        </div>

        <!--------------------------------------------- 예상 배달 시간 --------------------------------------------->

        <div *ngIf="order.contextStatusCode >= 10 && order.contextStatusCode < 20" class="deliveryMinutes">예상 배달 시간</div>
        <div *ngIf="order.contextStatusCode >= 10 && order.contextStatusCode < 20">
          <mat-radio-group formControlName="deliveryMinutes">
            <!-- <mat-radio-button *ngFor="let option of deliveryMinutesOptions" [value]="option" [checked]="option === defaultDeliveryMinutes">{{ option + '분' }}</mat-radio-button> -->
            <mat-radio-button *ngFor="let option of deliveryParams.deliveryMinutesOptions" [class.recommended]="order.contextStatusCode > 10 && order.posDeliveryMinutes && order.recommendedDeliveryMinutes === option" [value]="option">{{ option + '분' }}</mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field class="orderNotes" appearance="outline" [hideRequiredMarker]="true" floatLabel="always">
          <mat-label>배송 메시지(고객 요청)</mat-label>
          <textarea [class.caution]="cautiousOrderMsg" matInput formControlName="orderNotes" placeholder="배송 관련 고객 메시지"></textarea>
        </mat-form-field>

        <div class="prefixMsg">추가 메시지(상세 주소 앞에 추가)</div>
        <div>
          <mat-checkbox formControlName="isUrgent">긴급누락건</mat-checkbox>
          <mat-checkbox formControlName="isOverWeight">과적건</mat-checkbox>
          <mat-checkbox formControlName="isNoodle">파스타</mat-checkbox>
        </div>

        <!-- <mat-form-field [class.hidden]="!isCustom" class="customMsg" appearance="outline" [hideRequiredMarker]="true" floatLabel="always"> -->
        <mat-form-field class="customMsg" appearance="outline" [hideRequiredMarker]="true" floatLabel="always">
          <mat-label>직접 입력</mat-label>
          <input matInput formControlName="customMsg" placeholder="괄호는 자동 추가">
        </mat-form-field>
      </form>

      <div class="buttons">
        <button mat-raised-button (click)="onCancel()">취소</button>
        <button mat-raised-button
          *ngIf="order.withDelivery === false && order.contextStatusCode >= 10 && order.contextStatusCode < 20"
          type="submit" color="primary"
          (click)="submitAccept(true)"
        >접수만</button>
        <button
          mat-raised-button
          type="submit"
          color="accent"
          (click)="onSubmit(addressForm)"
          [disabled]="requestDeliveryForm.invalid || isRequestButtonPressed || guardTimeMargin || isDeliveryPossible === false"
        >
          {{ guardTimeMargin ? '중복 배차 보호 중' : (deliveryVendorMappings[selectedDeliveryVendor] ? deliveryVendorMappings[selectedDeliveryVendor] : 'N/A') + ' 배차만 요청' }}
        </button>
        <!-- 요기요의 경우에는 form validation을 하지 않는다. userTel이 없기 때문에 -->
        <button
          mat-raised-button
          type="submit" color="accent"
          *ngIf="order.contextStatusCode >= 10 && order.contextStatusCode < 20"
          (click)="onSubmit(addressForm, true)"
          [disabled]="(order.orderVendor !== 'yogiyo' && requestDeliveryForm.invalid) || isRequestButtonPressed || guardTimeMargin || isDeliveryPossible === false"
        >
          {{ guardTimeMargin ? '중복 배차 보호 중' : '접수 및 ' + (deliveryVendorMappings[selectedDeliveryVendor] ? deliveryVendorMappings[selectedDeliveryVendor] : 'N/A') + ' 배차' }}
        </button>
      </div>
    </div>

    <div class="right">
      <table>
        <tr>
          <th>주문번호</th>
          <td>:</td>
          <td class="copyable" (click)="copyToClipboard($event)">{{ order._id }}</td>
        </tr>
        <tr *ngIf="order.adminMemo && order.adminMemo.length > 0">
          <th>관리자 메모</th>
          <td>:</td>
          <td>{{ order.adminMemo }}</td>
        </tr>
      </table>

      <div class="deliveryParams">
        <table>
          <tr>
            <th>POS 픽업요청시간</th>
            <td>:</td>
            <td>{{ order.posRequestedPickupMinutes ? order.posRequestedPickupMinutes : '--' }}분</td>
          </tr>
          <tr>
            <th>POS 예상배달시간</th>
            <td>:</td>
            <td><span [class.different]="order.contextStatusCode > 10 && order.deliveryType === 'DELIVERY' && order.posDeliveryMinutes > 0 && order.posDeliveryMinutes !== order.recommendedDeliveryMinutes">{{ order.posDeliveryMinutes ? order.posDeliveryMinutes : '--' }}분</span></td>
          </tr>
          <!-- 조리시간은 posRequestedPickupMinutes을 따르기 때문에 보여주지 않아도 된다. -->
          <tr *ngIf="!order.posRequestedPickupMinutes">
            <th>조리시간(업소)</th>
            <td>:</td>
            <td>{{ order.cookMinutes }}분</td>
          </tr>
          <tr>
            <th>최소픽업/기본/Km당</th>
            <td>:</td>
            <td>{{ deliveryParams.deliveryParamsMinPickupMinutes }}분 / {{ deliveryParams.deliveryParamsBaseDeliveryMinutes }}분 / {{ deliveryParams.deliveryParamsMinutesPerKm }}분</td>
          </tr>
          <tr>
            <th>배달거리</th>
            <td>:</td>
            <td>{{ deliveryParams.deliveryDistance | number }}m</td>
          </tr>
          <tr>
            <th>자동 픽업요청시간</th>
            <td>:</td>
            <td>{{ deliveryParams.calculatedPickupMinutes }}분 (= Max({{ order.cookMinutes ? order.cookMinutes : 20 }}, {{ deliveryParams.deliveryParamsMinPickupMinutes }}))</td>
          </tr>
          <tr>
            <th>자동 예상배달시간</th>
            <td>:</td>
            <td><span [class.different]="order.contextStatusCode > 10 && order.deliveryType === 'DELIVERY' && order.posDeliveryMinutes > 0 && order.posDeliveryMinutes !== order.recommendedDeliveryMinutes">{{ deliveryParams.calculatedDeliveryMinutes }}분</span> (= {{ deliveryParams.calculatedPickupMinutes }} + {{ deliveryParams.deliveryParamsBaseDeliveryMinutes}} + {{ deliveryParams.deliveryParamsMinutesPerKm }} * {{ deliveryParams.deliveryDistance | number }}m)</td>
          </tr>
          <tr>
            <th>오토파일럿</th>
            <td>:</td>
            <td>{{ autoPilot }}</td>
          </tr>
        </table>
      </div>

      <div class="order-info">
        <div *ngIf="selectedDeliveryVendor && deliveryInfo?.deliveryVendor !== selectedDeliveryVendor" style="text-align: center; color: blueviolet">{{deliveryVendorMappings[selectedDeliveryVendor]}} 배달 정보 읽는 중...</div>
        <app-delivery-information [deliveryInfo]="deliveryInfo"></app-delivery-information>
      </div>

      <!-- <div *ngIf="vroongAccount" class="vroong">
        <table>
          <ng-container>
            <tr>
              <th>부릉</th>
              <td>:</td>
              <td>{{ vroongAccount.sender_name }}</td>
            </tr>
            <tr>
              <th>ID/PW</th>
              <td>:</td>
              <td>{{ vroongAccount.name }} / {{ vroongAccount.password }}</td>
            </tr>
            <tr>
              <th>픽업옵션</th>
              <td>:</td>
              <td>
                <ng-container *ngFor="let item of deliveryParams.pickupMinutes">
                  <span class="vroongPickup" [class.enabled]="item.enabled">{{ item.time }}</span>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </table>
      </div> -->

      <app-menu *ngIf="order.foods && order.foods.length > 0" [order]="order"></app-menu>
    </div>
  </div>
</div>
