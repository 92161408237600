/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
export const riderNames = [
  '강건호',
  '김시동',
  '김윤중',
  '김준성',
  '김승환',
  '김홍일',
  '류종훈',
  '박형근',
  '백광흠',
  '염용남',
  '은광현',
  '이범우',
  '이재만',
  '이재원',
  '정민혁',
  '조영진',
  '조창익',
  '최경일',
  '최정이',
  '최중섭',
  '한필희',
  '허광진',
  '김태윤',
  '라이더1',
  '라이더2'
];
