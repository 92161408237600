/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { environment } from '../../../environments/environment';
import { UnifiedMenuDoc } from '../../schema/3/schema';
import { UserService } from '../2/user.service';

const collectionPath = 'unifiedMenu';

@Injectable({
  providedIn: 'root'
})
export class UnifiedMenuService {
  public menuDocs: UnifiedMenuDoc[] = [];
  public latestUnifiedMenuSubject = new BehaviorSubject<UnifiedMenuDoc[]>([]);

  constructor(
    private db: AngularFirestore,
    private userService: UserService
  ) { }

  public startObserving() {
    const organization = this.userService.organization;
    this.observeMenu('organization', organization).subscribe(menuDocs => {
      this.menuDocs = menuDocs;
      this.latestUnifiedMenuSubject.next(menuDocs);
    });
  }

  /**
   * 주어진 key/value 조건에 맞는 메뉴 목록을 가져온다.
   *
   * @param key 'organization' | 'site' | 'room' | 'shopNo'
   */
  private observeMenu(key: 'organization' | 'site' | 'room' | 'shopNo', value: string) {
    console.log(`${this.constructor.name}::observeMenu for ${key}:${value}`);
    const queryFn: QueryFn = ref => {
      const query = ref.where(key, '==', value);
      return query;
    };

    const collection = this.db.collection<UnifiedMenuDoc>(collectionPath, queryFn);

    // 디버깅용
    if (environment.production === false) {
      collection.stateChanges().pipe(
        map(actions => actions.map(action => {
          return { _type: action.type, ...action.payload.doc.data() };
        }))
      ).subscribe(menus => {
        for (const menu of menus) {
          // console.log(`[${menu._id}] ${menu.room}/${menu.shopName}`);
        }
      });
    }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = collection.valueChanges();

    return observable;
  }
}
