/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CollectionPath } from '../../core/4/firebase-manager.service';

import { DialogJsonEditorComponent } from './dialog-json-editor.component';

@Injectable({
  providedIn: 'root'
})
export class DialogJsonEditorService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialog(collectionName: CollectionPath, docId: string) {
    const dialogRef = this.dialog.open(DialogJsonEditorComponent, {
      data: {
        collectionName,
        docId
      },
      width: '80vw',
      height: '80vh'
    });

    return dialogRef;
  }
}
