/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-spinner',
  templateUrl: './dialog-spinner.component.html',
  styleUrls: ['./dialog-spinner.component.scss']
})
export class DialogSpinnerComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogSpinnerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onOk(): void {
    this.dialogRef?.close();
    this.dialogRef = undefined;
  }

  ngOnInit() {
  }
}
