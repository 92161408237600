/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import format from 'date-fns/format';
import { combineLatest, Subscription } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CellClassParams, ColDef, ColGroupDef, Module } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { MenuModule } from '@ag-grid-enterprise/menu';

import { LogOrderDoc, UnifiedOrderDoc } from '../../schema/3/schema';

import { forPlain, forTimestamp } from '../../core/1/ag-util';
import { LogOrderService } from '../../core/1/log-order.service';
import { toDate, trimOrganization } from '../../core/2/util';
import { RoomService } from '../../core/4/room.service';

import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';

@Component({
  selector: 'app-dialog-log-order',
  templateUrl: './dialog-log-order.component.html',
  styleUrls: ['./dialog-log-order.component.scss']
})
export class DialogLogOrderComponent implements OnInit, OnDestroy {

  public order: UnifiedOrderDoc;
  public orderDate: string;

  public modules: Module[] = [
    MenuModule,             // 우클릭할 때 표시되는 메뉴 UI
    ClipboardModule,        // 행 전체 복사 등의 기능 제공
  ];

  public defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    tooltipComponentFramework: CustomTooltipComponent, // custom tooltip 사용
  };

  public columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: '기본',
      children: [
        forPlain('ID', '_id', 120, { columnGroupShow: 'open' }),
        forTimestamp('생성시각', '_timeCreate', 180, 'yyyy-MM-dd ccc HH:mm:ss'),
        forPlain('조직', 'organization', 144, {
          columnGroupShow: 'open',
          valueFormatter: params => {
            const order = params.data as LogOrderDoc;
            return order?.room ? trimOrganization(this.roomService.rooms[order.room]?.organizationName) : '';
          }
        }),
        forPlain('사이트', 'site', 100, {
          columnGroupShow: 'open',
          valueFormatter: params => {
            const order = params.data as LogOrderDoc;
            return order?.room ? trimOrganization(this.roomService.rooms[order.room]?.siteName) : '';
          }
        }),
        forPlain('호실', 'room', 106, { valueFormatter: params => trimOrganization(this.roomService.rooms[params.value]?.name) }),
        forPlain('주문번호', 'orderId', 164),
      ]
    },
    {
      headerName: '메시지',
      children: [
        forPlain('level', 'level', 80, {
          filter: 'agSetColumnFilter',
          cellClassRules: {
            error: (params: CellClassParams) => params?.data?.level === 'error',
            warn: (params: CellClassParams) => params?.data?.level === 'warn',
          }
        }),
        forPlain('메시지', 'message', 400),
      ]
    },
    {
      headerName: '인스턴스',
      children: [
        forPlain('instanceType', 'instanceType', 144),
        forPlain('instanceNo', 'instanceNo', 280, { columnGroupShow: 'open' }),
      ]
    },
    {
      headerName: '계정정보',
      children: [
        forPlain('계정', 'account', 200),
        forPlain('IP', 'publicIP', 130, { columnGroupShow: 'open' }),
      ]
    },
  ];

  public rowData: LogOrderDoc[];

  private logOrderSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DialogLogOrderComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { order: UnifiedOrderDoc },
    private logOrderService: LogOrderService,
    private roomService: RoomService
  ) { }

  ngOnInit(): void {
    this.order = this.data.order;
    this.orderDate = format(toDate(this.order.orderDate), 'dd ccc HH:mm:ss');

    this.logOrderSubscription = combineLatest([this.logOrderService.observeRoom(this.order), this.logOrderService.observeOrder(this.order)]).subscribe(([logs1, logs2]) => {
      // 1. merge
      const merged = logs1.concat(logs2);
      // 2. 정렬
      merged.sort((a, b) => b._timeCreate.toMillis() - a._timeCreate.toMillis());

      this.rowData = merged;
    });
  }
  ngOnDestroy() {
    this.logOrderSubscription?.unsubscribe();
  }

  public onCloseDialog() {
    this.dialogRef?.close(false);
    this.dialogRef = undefined;
  }
}
