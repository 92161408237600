<h1>{{ status }}</h1>

<table class="timetable">
  <thead>
    <tr><th></th> <th class="timefield">절대시각</th> <th class="timefield">신청기준</th> <th class="timefield">현재기준</th> </tr>
  </thead>
  <tbody>
    <tr *ngFor="let timestamp of sortedTimes; let last = last"
      [class.last]="last"
      [class.notice]="timestamp.class === 'notice'"
      [class.blink]="timestamp.class === 'blink'">
      <th>{{ timestamp.name }}</th>
      <td class="center">{{ timestamp.date | date:'HH:mm:ss':'+0900' }}</td>
      <td class="right">{{ timestamp.relativeToCreatedAt }}</td>
      <td class="right">{{ timestamp.relativeToNow }}</td>
    </tr>
  </tbody>
</table>

<table>
  <tbody>
    <tr><th>상점명</th><td>{{ delivery.origin_name }}</td></tr>
    <tr><th>번지주소</th><td>{{ delivery.dest_address.beonji_address.raw_address }}</td></tr>
    <tr><th>도로명주소</th><td>{{ delivery.dest_address.road_address.raw_address }}</td></tr>
    <tr><th>고객전화</th><td>{{ userTel }}</td></tr>
    <tr><th>요청사항</th><td>{{ delivery.request_notes }}</td></tr>
    <tr><th>결제수단</th><td>{{ payMethod }} ({{ delivery.cargo_price | number }}원)</td></tr>
    <tr><th>정산배송비</th><td>{{ delivery.billable_fee | number }}원</td></tr>
    <tr *ngIf="delivery.delivery_tracking_status.agent_name"><th>라이더</th><td>{{ delivery.delivery_tracking_status.agent_name }} ({{ agentTel }})</td></tr>
    <tr class="dim"><th>배송번호</th><td>{{ delivery.delivery_tracking_status.vroong_order_number }}</td></tr>
    <tr class="dim"><th>배송ID</th><td>{{ delivery.delivery_number }}</td></tr>
    <tr class="dim"><th>주문번호</th><td>{{ delivery.applicant_order_number }}</td></tr>
    <tr class="dim"><th>인스턴스ID</th><td>{{ delivery._bizNo }}</td></tr>
  </tbody>
</table>

<div class="buttons">
  <button mat-raised-button type="submit" (click)="onSubmit()">닫기</button>
  <button *ngIf="delivery.status !== 'DELIVERED' && delivery.status !== 'CANCELED'" mat-raised-button color="warn" (click)="cancelDelivery()">배송 취소</button>
  <button *ngIf="delivery.status === 'ASSIGNED' && !delivery.delivery_tracking_status.cargo_prepared_at" mat-raised-button color="primary" (click)="cargoPrepared()" >조리완료</button>
  <button  mat-raised-button color="primary" (click)="openDestMap()">지도 열기</button>
</div>
