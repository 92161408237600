/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { DeliveryVendor } from '../../schema/1/schema-common';
import { UnifiedOrderDoc, UnifiedOrderContextStatusCode } from '../../schema/3/schema';

export const registeredDeliveryVendors: DeliveryVendor[] = ['vroong', 'run2u', 'spidor', 'barogo', 'ghokirun', 'logiall', 'manna', 'zendeli', 'shero', 'dalgo', 'iudream', 'baedalyo', 'baedalhero'];

export const roleMappings = {
  admin: '관리자',
  operator: '접수자',
  ceo: '사장님',
};

export const unifiedOrderStatusMappings = {
  0: '이상상태',
  10: '신규주문',
  15: '업소접수',
  17: '배차대기',
  20: '주문접수',
  30: '조리시작',
  40: '조리완료',
  50: '포장완료',
  60: '배송중',
  70: '배송완료',
  80: '취소',
  90: '삭제',
  95: '결제대기',
  96: '결제중단'
};

export const unifiedOrderExtendedStatusMappings = {
  ...unifiedOrderStatusMappings,
  77: '미확인취소'
};

export const unifiedOrderVendorMappings = {
  baemin: '배민',
  yogiyo: '요기요',
  coupangeats: '쿠팡이츠',
  shuttle: '셔틀',
  ghostkitchen: '고스트키친',
  ddangyo: '땡겨요',
  ddingdong: '띵동',
  other: '기타',
};

export const unifiedOrderChannelMappings = {
  app: '앱',
  tel: '전화',
  face: '직접방문'
};

export const unifiedOrderDeliveryTypeMappings = {
  DELIVERY: '배달',
  TAKEOUT: '포장',
  BAERA: '배민1',
  COUPANG: '쿠팡이츠',
  SHUTTLE: '셔틀',
  HERE: '매장식사',
  YOGIYO: '익스프레스'
};

export const unifiedOrderCreatedByMappings = {
  manual: '직접입력',
  face: '고객입력',
  fingerFace: '손가락'
};

export const unifiedDeliveryStatusCodeMappings = {
  10: '신청',
  20: '배차',
  30: '픽업',
  40: '완료',
  50: '취소'
};

export const baeminAppListPaymentMethodMappings = {
  1: '만나서현금',
  2: '만나서카드',
};

export const baeminAppPaymentGroupMappings = {
  1: '선불',
  2: '후불'
};

export const baeminAppDetailPaymentMethodMappings = {
  1: '카드',
  2: '휴대폰결제',
  5: '만나서현금',
  6: '만나서카드',
  12: '페이코',
  13: '배민페이 계좌이체',
  14: '배민페이',
  15: '토스',
  16: '네이버페이',
  17: '카카오페이',
  30: '네이버페이2', // T0G20000076D 16과 구별하기 위해서 네이버페이2라고 했다.
  99: 'OK캐시백' // 2019-07-19. 100%할인 받은 경우일 수도 있다.
};

export const baeminCeoStatusMappings = {
  OPENED: '신규',
  ACCEPTED: '주문접수',
  CLOSED: '배송완료',
  CANCELLED: '취소'
};

export const baeminCeoCategoryCodeMappings = {
  1: '치킨',
  2: '중식',
  3: '피자',
  4: '족발,보쌈',
  5: '야식',
  6: '찜,탕',
  7: '패스트푸드',
  9: '도시락',
  10: '돈까스,회,일식',
  32: '한식',
  33: '분식',
  34: '카페,디저트'
};

// 2021-08-22: 인증없이 아래 요청을 보내면 확인 가능
// refer: https://ceo.baemin.com/route/adcenter/v2/ad-categories
// displayRadius도 확인 가능
export const baeminAdCategoryMappings = {
  KOREAN: '한식',
  SNACK: '분식',
  JAPANESE: '돈까스·회·일식',
  CHICKEN: '치킨',
  PIZZA: '피자',
  ASIAN_WESTERN: '아시안·양식',
  ASIAN: '아시안',
  WESTERN: '양식',
  CHINESE: '중식',
  BAEKBAN: '백반·죽·국수',
  MEAT: '고기·구이',
  JOKBAL: '족발·보쌈',
  NIGHTSNACK: '야식',
  ZZIM_TANG: '찜·탕',
  DOSIRAK: '도시락',
  CAFE: '카페·디저트',
  FASTFOOD: '패스트푸드',

  BAEMIN_1_MEAT: '고기·구이',
  BAEMIN_1_BAEKBAN: '백반·죽·국수',
  BAEMIN_1_ZZIM_TANG: '찜·탕·찌개',
  BAEMIN_1_SNACK: '분식',
  BAEMIN_1_CAFE: '카페·디저트',
  BAEMIN_1_JAPANESE: '돈까스·회·일식',
  BAEMIN_1_WESTERN: '양식',
  BAEMIN_1_ASIAN: '아시안',
  BAEMIN_1_JOKBAL: '족발·보쌈',
  BAEMIN_1_PIZZA: '피자',
  BAEMIN_1_CHICKEN: '치킨',
  BAEMIN_1_CHINESE: '중식',
  BAEMIN_1_BURGER: '버거',
};

export const baeminCeoPurchaseTypeMappings = {
  BARO: '선불',
  MEET: '후불'
};

export const vroongStatusMappings = {
  SUBMITTED: '배차대기',
  ASSIGNED: '픽업중',
  PICKED_UP: '배송중',
  DELIVERED: '배송완료',
  CANCELED: '취소',
};

export const vroongPayMethodMappings = {
  PREPAID: '선불',
  AT_DEST_CARD: '후불카드',
  AT_DEST_CASH: '후불현금'
};

export const coupangeatsOrderStatusMappings = {
  PAYMENT_APPROVED: '신규',
  ACCEPTED: '주문접수',
  PICKED_UP: '배송중',
  COMPLETED: '배송완료',
  CANCELLED: '취소'
};

export const coupangeatsDeliveryStatusMappings = {
  MERCHANT_ACCEPTED: '배차대기',
  COURIER_ACCEPTED: '픽업중',
  MERCHANT_READY: '조리완료',
  COURIER_PICKEDUP: '배송중',
  DELIVERED: '배송완료',
  CANCELLED: '취소'
};

export const combinenetStateCodeMappings = {
  1: '접수',
  2: '배차',
  3: '픽업',
  5: '배달완료',
  44: '취소'
};

export const combinenetPayMethodMappings = {
  0: '후불현금',
  1: '후불카드',
  2: '선불'
};

export const simpleNoticeMappings = {
  hide: '비활성화',
  show: '활성화',
  deleted: '삭제',
};

export const deliveryVendorMappings = {
  vroong: '부릉',
  run2u: '런투유',
  spidor: '스파이더',
  barogo: '바로고',
  ghokirun: '고키런',
  logiall: '생각대로',
  manna: '만나플래닛',
  baera: '배민1',
  coupang: '쿠팡',
  yogiyo: '요기요',
  zendeli: '젠딜리',
  shero: '슈퍼히어로',
  dalgo: '달리고',
  iudream: '이어드림',
  baedalyo: '배달요',
  baedalhero: '배달히어로'
};

const autoPilotResultMappings = {
  acceptOnly: '🟡',
  success: '🟢',
  error: '❌',
  warning: '🟠',
  skip: '⛔️',
  na: '-'
};

export function autoPilotResultFor(order: UnifiedOrderDoc) {
  if (order) {
    const autoPilotResult = order.autoPilotResult;

    if (autoPilotResult) {
      return autoPilotResultMappings[autoPilotResult] ?? '구멍';
    } else {
      if (order.deliveryType !== 'DELIVERY') {
        return '-';
      }
      if (order.contextStatusCode < UnifiedOrderContextStatusCode.CEOACCEPTED) {
        return '💤';
      } else {
        return '❓';
      }
    }
  } else {
    return undefined;
  }
}

export const deliveryAreaActionMappings = {
  accept: '🟢',
  reject: '❌',
  skip: '-'
};

export const deliveryAreaActionToAliasMappings = {
  accept: 'Y',
  reject: 'N',
  skip: 'S'
};

export const deliveryAreaActionFromAliasMappings = {
  Y: 'accept',
  N: 'reject',
  S: 'skip'
};

export const deliveryStatusCodeMappings = {
  10: '접수',
  /** 고키런에서 접수와 배차 사이의 상태를 표현하기 위해서 만들었다. DB에는 존재하지 않는다. */
  15: '수락대기',
  20: '배차',
  30: '픽업',
  40: '배달완료',
  50: '취소'
};

export const riderVehicleMappings = {
  MOTORCYCLE: '오토바이',
  CAR: '자동차',
  BYCYCLE: '자전거',
  WALKING: '도보',
  UNKNOWN: '모름'
};

export const barogoOrderStatusMapping = {
  CREATED: '생성',
  ACCEPTED: '수락',
  REJECTED: '거절',
  PREPARED: '상품 준비 완료',
  CANCELED: '취소',
  FINISHED: '완료',
  FAILED: '실패'
};

export const barogoDeliveryStatusCodeMapping = {
  CREATED: '배달 생성',
  ASSIGNED: '대행사 지정',
  ACCEPTED: '배달 수락',
  ALLOCATED: '배차',
  ALLOCATION_CANCELED: '배차 취소',
  ALLOCATION_CHANGED: '배차 변경',
  COOK_REQUESTED: '조리 요청',
  ARRIVED: '픽업지 도착',
  PICKUP_FINISHED: '픽업 완료',
  DROP_FINISHED: '배달 완료',
  CANCELED: '취소',
  REJECTED: '배달 거절',
  FAILED: '실패'
};

export const barogoOrderTypeMappings = {
  FOR_DELIVERY: '배달요청',
  FOR_ORDER: '주문요청',
  ACCEPTED_ORDER: '주문전달'
};

export const barogoPaidMethodMappings = {
  CARD: '카드',
  CASH: '현금',
  POINT: '포인트',
  KAKAOPAY: '카카오페이',
  NAVERPAY: '네이버페이',
  PAYCO: '페이코',
  SMILEPAY: '스마일페이',
  MICROPAY: '핸드폰 소액 결제',
  WITHOUT_BANKBOOK: '무통장입금',
  ACCOUNT_TRANSFER: '계좌이체',
  ONE_CLICK: '원클릭 결제',
  PAYNOW: '페이나우',
  SSGPAY: '쓱페이',
  ETC: '기타'
};

export const barogoOrderChannelMappings = {
  BAEMIN: '배민',
  YOGIYO: '요기요',
  KAKAO: '카카오',
  NAVER: '네이버',
  SHUTTLE: '셔틀',
  BAEGOFA: '배고파',
  WMPO: '위메프오',
  DDINGDONG: '띵동',
  COUPANG: '쿠팡이츠',
  HAPPYORDER: '해피오더',
  FACEBOOK: '페이스북',
  MANUAL: '수동',
  BULLEOBOMNAE: '불러봄내',
  LASTORDER: '라스트오더',
  ETC: '기타'
};

export const barogoOrderCancelTypeMapping = {
  OTHER_DELIVERY_AGENCY: '다른 배달대행사로 요청',
  DUPLICATE_ORDER: '중복된 주문 접수',
  CANCEL_BY_STORE: '상점 요청',
  CANCEL_BY_CUSTOMER: '고객 요청',
  CANCEL_BY_ADMIN: '관리자 요청',
  CANCEL_BY_TIMEOUT: '수락 시간 초과',
  COMPLAIN_BY_CUSTOMER: '고객의 불만 요청',
  DELAY_DELIVERY: '배달 지연',
  DELIVERY_AGENCY_CANCELED: '배달대행사가 배달을 취소',
  UNNECESSARY_ORDER: '불필요한 주문 접수',
  ETC: '기타'
};

export const barogoDeliveryCancelTypeMapping = {
  INVALID_AREA: '배달할 수 없는 지역',
  OVER_CAPACITY: '배달을 수행할 수 있는 라이더 부족',
  CHANGE_STORE: '다른 브랜드 상점으로 변경',
  BAD_WEATHER: '배달 불가능한 날씨',
  CLOSE_AGENCY: '배달대행사의 영업이 종료',
  CLOSE_STORE: '상점의 영업이 종료',
  INSUFFICIENT_DEPOSIT: '상점 예치금 부족',
  WRONG_REQUEST: '배달 접수 후 잘못된 정보로 인한 취소',
  WRONG_RECEIVER_INFO: '고객 정보 부정확',
  NOT_ANSWER_RECEIVER: '고객과 연락불가',
  CHANGE_CUSTOMER_MIND: '고객 요청',
  DAMAGE_PRODUCT: '배달 상품 손상',
  SOLD_OUT: '배달할 상품 품절',
  TIMEOUT_CANCEL: '배달대행사의 장기 배차 지연',
  UNDERAGE: '미성년자 고객',
  DELAY_DELIVERY: '배달 지연',
  CANCEL_BY_STORE: '상점 요청',
  ETC: '기타',
};

export const barogoOrderRejectReasonTypeMapping = {
  INVALID_AREA: '배달할 수 없는 지역',
  INVALID_ADDRESS: '유효하지 않은 주소 및 좌표',
  TOO_LONG_DELIVERY_DISTANCE: '배달 가능한 유효거리 초과',
  OVER_CAPACITY: '배달을 수행할 수 있는 라이더 부족',
  BAD_WEATHER: '배달 불가능한 날씨',
  CLOSE_AGENCY: '배달대행사 영업 종료',
  CLOSE_STORE: '상점 영업 종료',
  INSUFFICIENT_DEPOSIT: '상점 예치금 부족',
  WRONG_REQUEST: '접수 오류',
  DELIVERY_AGENCY_CAN_NOT_RESERVATION: '예약 주문 가능한 배달대행사가 없음',
  DELIVERY_AGENCY_CAN_NOT_UNTACT: '비대면 배달이 가능한 배달대행사가 없음',
  STORE_NOT_APPROVED: '상점이 승인되지 않은 상태',
  ETC: '기타',
};

export const barogoServeTypeMapping = {
  DELIVERY: '배달',
  PACKING: '포장',
  HALL: '매장'
};

export const barogoOrderProductTypeMapping = {
  FOOD: '음식',
  DELIVERY_TIP: '배달팁',
  GROCERY: '식료품',
  CONVENIENCE_STORE: '편의점',
  PHARMACY: '약국',
  SUMMARY: '통합상품',
  DESSERT: '디저트',
  DRINK: '음료',
  ETC: '기타'
};

export const barogoExtraChargeTypeMapping = {
  WEATHER: '기상',
  AREA: '구역',
  OVERLOAD: '과적',
  DISTANCE: '거리',
  HOLIDAY: '휴일',
  TIME: '시간(피크,야간)',
  VALUABLE: '귀중',
  ETC: '기타'
};

export const logiallDeliveryStatusMapping = {
  1: '접수',
  2: '운행',
  3: '픽업',
  4: '완료',
  5: '접수취소'
};

export const logiallPaidMethodMapping = {
  1: '현금',
  7: '카드',
  9: '선결제',
};

export const replySmsMappings = {
  active: '활성화',
  inactive: '비활성화',
  deleted: '삭제',
};
