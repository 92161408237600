<div class="container">
  <h1>{{ status }}</h1>

  <table class="timetable">
    <thead>
      <tr><th></th> <th class="timefield">절대시각</th> <th class="timefield">신청기준</th> <th class="timefield">현재기준</th> </tr>
    </thead>
    <tbody>
      <tr *ngFor="let timestamp of sortedTimes; let last = last"
        [class.last]="last"
        [class.notice]="timestamp.class === 'notice'"
        [class.blink]="timestamp.class === 'blink'">
        <th>{{ timestamp.name }}</th>
        <td class="center">{{ timestamp.date | date:'HH:mm:ss':'+0900' }}</td>
        <td class="right">{{ timestamp.relativeToCreatedAt }}</td>
        <td class="right">{{ timestamp.relativeToNow }}</td>
      </tr>
    </tbody>
  </table>
      
  <table>
    <tbody>
      <tr><th>상점명</th><td>{{ shopName }}</td></tr>
      <tr><th>번지주소</th><td>{{ delivery.orderAddRequest?.address }} {{ delivery.orderAddRequest?.addressDetail }}</td></tr>
      <tr><th>도로명주소</th><td>{{ delivery.orderAddRequest?.address_road }}</td></tr>
      <tr><th>고객전화</th><td>{{ userTel }}</td></tr>
      <tr><th>요청사항</th><td>{{ delivery.orderAddRequest?.memo }}</td></tr>
      <tr><th>결제수단</th><td>{{ payMethod }} ({{ delivery.orderAddRequest?.paymentPrice | number }}원)</td></tr>
      <tr><th>정산배송비</th><td>{{ delivery.orderAddResponse?.totalFee | number }}원</td></tr>
      <tr *ngIf="delivery.orderStatus.riderName"><th>라이더</th><td>{{ delivery.orderStatus?.riderName }} ({{ agentTel }})</td></tr>
      <tr class="dim"><th>POS 가맹점코드</th><td>{{ delivery.orderAddRequest?.posStoreCode }}</td></tr>
      <tr class="dim"><th>POS 주문번호</th><td>{{ delivery.orderAddRequest?.posOrderCode }}</td></tr>
      <tr class="dim"><th>인스턴스ID</th><td>{{ delivery.instanceNo }}</td></tr>
    </tbody>
  </table>
  
  <div class="buttons">
    <button mat-raised-button type="submit" (click)="onSubmit()">닫기</button>
    <button *ngIf="delivery.orderStatus?.stateCode !== '5' && delivery.orderStatus?.stateCode !== '44'" mat-raised-button color="warn" (click)="cancelDelivery()">배송 취소</button>
    <button mat-raised-button color="primary" (click)="refreshOrderStatus()">상태갱신</button>
    <button  mat-raised-button color="primary" (click)="openDestMap()">지도 열기</button>
    <button mat-raised-button color="warn" (click)="recover()">복구</button>
  </div>
  <div class="buttons" *ngIf="userService.user.role === 'admin'">
    <button mat-raised-button color="warn" (click)="openJsonEditor()">raw 데이터 편집</button>
  </div>
</div>