<h2 mat-h2>주문 {{ order.orderStatusCode === 10 ? '거부' : '취소' }}</h2>

<div class="flex-container">
  <div class="left">
    <!-- 연락처, 지불방법 등 -->
    <app-order-contact [order]="order"></app-order-contact>

    <form [formGroup]="cancelOrderForm">
      <mat-radio-group formControlName="cancelReasonCode" class="cancelReasonCode">
        <ng-container *ngFor="let cancelDesc of cancelDescs">
          <mat-radio-button [value]="cancelDesc.cancelCode">{{ cancelDesc.cancelReason }}</mat-radio-button>
        </ng-container>
      </mat-radio-group>

      <div class="buttons">
        <button mat-raised-button
          (click)="onCancel()">닫기</button>
        <button mat-raised-button type="submit" color="accent" [disabled]="cancelOrderForm.invalid"
          (click)="onSubmit()" >주문 {{ order.orderStatusCode === 10 ? '거부' : '취소' }} 및 확인</button>
      </div>
    </form>
  </div>

  <div class="right">
    <app-menu [order]="data.order"></app-menu>
  </div>
</div>

