/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UnifiedOrderDoc } from '../../schema/3/schema';

import { DialogPrintOrderComponent } from './dialog-print-order.component';

@Injectable({
  providedIn: 'root'
})
export class DialogPrintOrderService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialog(order: UnifiedOrderDoc, timeout?: number) {
    // 다른 다이얼로그는 닫는다.
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(DialogPrintOrderComponent, {
      data: {
        order
      },
      maxWidth: '400px'
    });

    if (timeout !== undefined) {
      setTimeout(() => {
        if (dialogRef) {
          dialogRef.close();
          dialogRef = undefined;
        }
      }, timeout);
    }
  }
}
