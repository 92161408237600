/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-help-linkage',
  templateUrl: './dialog-help-linkage.component.html',
  styleUrls: ['./dialog-help-linkage.component.scss']
})
export class DialogHelpLinkageComponent {

  constructor(
    private dialogRef: MatDialogRef<DialogHelpLinkageComponent>,
  ) { }

  public closeDialog() {
    this.dialogRef?.close();
    this.dialogRef = undefined;
  }
}
