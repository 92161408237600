<form class="addressForm" [formGroup]="addressForm">
  <div class="fieldGroup">
    <mat-form-field class="road" [hideRequiredMarker]="true" floatLabel="always">
      <mat-label>도로명 주소</mat-label>
      <input matInput formControlName="address_road" placeholder="자동 완성" required>
    </mat-form-field>

    <mat-form-field class="dongH" [hideRequiredMarker]="true" floatLabel="always">
      <mat-label>행정동</mat-label>
      <input matInput formControlName="address_dongH" placeholder="자동 완성" required>
    </mat-form-field>

    <mat-form-field class="lat" [hideRequiredMarker]="true" floatLabel="always">
      <mat-label>위도</mat-label>
      <input matInput formControlName="address_location_lat" [class.warn]="addressForm.get('address_location_lat').value < 30" placeholder="자동 완성" required>
    </mat-form-field>

    <mat-form-field class="lon" [hideRequiredMarker]="true" floatLabel="always">
      <mat-label>경도</mat-label>
      <input matInput formControlName="address_location_lon" [class.warn]="addressForm.get('address_location_lon').value < 30" placeholder="자동 완성" required>
    </mat-form-field>
  </div>

  <mat-tab-group animationDuration="0ms"  (selectedIndexChange)="onSelectedIndexChange($event)">
    <mat-tab label="번지 주소">
      <div class="fieldGroup inTab">
        <mat-form-field class="sido" [hideRequiredMarker]="true" floatLabel="always">
          <mat-select panelClass="longSelectPanel" formControlName="address_sido" placeholder="시도" required>
            <mat-option *ngFor="let sido of sidos" [value]="sido">{{sido}}</mat-option>
          </mat-select>
        </mat-form-field>
      
        <mat-form-field class="sigungu" [hideRequiredMarker]="true" floatLabel="always">
          <mat-select panelClass="longSelectPanel" formControlName="address_sigungu" placeholder="시군구" required>
            <mat-option *ngFor="let sigungu of sigungus" [value]="sigungu">{{sigungu}}</mat-option>
          </mat-select>
        </mat-form-field>
      
        <mat-form-field class="dong" [hideRequiredMarker]="true" floatLabel="always">
          <mat-select panelClass="longSelectPanel" formControlName="address_dong" placeholder="동" required>
            <mat-option *ngFor="let dong of dongs" [value]="dong">{{dong}}</mat-option>
          </mat-select>
        </mat-form-field>
      
        <mat-form-field class="jibun" [hideRequiredMarker]="true" floatLabel="always">
          <mat-label>번지</mat-label>
          <input matInput formControlName="address_jibun" placeholder="예) 3-45"
            pattern="^(산\s*)?([1-9][0-9]{0,3}|[1-9][0-9]{0,3}-[1-9][0-9]{0,2})$">
          <mat-error *ngIf="addressForm.get('address_jibun').invalid">{{ addressForm.get('address_jibun').errors['reason'] }}</mat-error>
        </mat-form-field>
      </div>
    </mat-tab>

    <mat-tab label="도로명/번지">
      <div class="fieldGroup inTab">
        <mat-form-field class="detail" [hideRequiredMarker]="true" floatLabel="always">
          <mat-label>주소 입력 (상세 주소는 제외하고 번지나 도로명 주소 입력)</mat-label>
          <input matInput formControlName="address_typing" placeholder="예) 강남구 강남대로 606">
          <mat-error *ngIf="addressForm.get('address_typing').invalid">{{ addressForm.get('address_typing').errors['reason'] }}</mat-error>
        </mat-form-field>
      </div>
    </mat-tab>
  </mat-tab-group>
  
  <div class="fieldGroup">
    <mat-form-field class="detail" [hideRequiredMarker]="true" floatLabel="always">
      <mat-label>상세 주소</mat-label>
      <input matInput formControlName="address_detail" placeholder="예) 동아타워 지하1층" required>
      <mat-error *ngIf="addressForm.get('address_detail').invalid">내용이 없네요.</mat-error>
    </mat-form-field>
  </div>

  <!-- <table>
    <tr>
      <th>valid</th>
      <th>invalid</th>
      <th>pristine</th>
      <th>dirty</th>
      <th>untouche</th>
      <th>touched</th>
    </tr>
    <tr>
      <td>{{addressForm.valid}}</td>
      <td>{{addressForm.invalid}}</td>
      <td>{{addressForm.pristine}}</td>
      <td>{{addressForm.dirty}}</td>
      <td>{{addressForm.untouched}}</td>
      <td>{{addressForm.touched}}</td>
    </tr>
  </table> -->
</form>

<div class="buttons" *ngIf="addressForm.dirty || !augmented"><button mat-flat-button color="primary"  [disabled]="addressForm.invalid" (click)="augmentAddress()">주소 확인</button></div>
