/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UnifiedOrderDoc } from '../../schema/3/schema';

@Component({
  selector: 'app-dialog-order-action',
  templateUrl: './dialog-order-action.component.html',
  styleUrls: ['./dialog-order-action.component.scss']
})
export class DialogOrderActionComponent implements OnInit {
  public order: UnifiedOrderDoc;

  constructor(
    public dialogRef: MatDialogRef<DialogOrderActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.order = this.data.order;
  }


  actionDone(result: boolean) {
    if (result) {
      this.dialogRef?.close();
      this.dialogRef = undefined;
    }
  }
}
