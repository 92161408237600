<form [formGroup]="smsForm">
  <h2>문자보내기</h2>

  <mat-form-field floatLabel="always">
    <mat-label>발신번호</mat-label>
    <mat-select formControlName="sendNo" required>
      <mat-option *ngFor="let sendNo of sendNos" [value]="sendNo.telNo">{{ sendNo.telNo }} ({{ sendNo.desc }})</mat-option>
    </mat-select>
  </mat-form-field>

  <h3 *ngIf="room !== null">{{ room }}</h3>
  <h3 *ngIf="name">{{name}}</h3>

  <mat-form-field floatLabel="always">
    <mat-label>연락처</mat-label>
    <input matInput formControlName="telNo" required type="tel" placeholder="숫자만 입력" pattern="^(0[157][01][1-9]?-[0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$">
    <mat-error *ngIf="smsForm.get('telNo').invalid">전화번호가 형식에 맞지 않습니다.</mat-error>
  </mat-form-field>

  <mat-form-field class="content">
    <mat-label>내용</mat-label>
    <textarea matInput formControlName="message"></textarea>
    <mat-error *ngIf="smsForm.get('message').invalid">{{ smsForm.get('message').errors['reason'] }}</mat-error>
  </mat-form-field>
  <p *ngIf="smsType === 'sms'">({{ currentByte }}/90byte)</p>
  <p *ngIf="smsType === 'lms'" class='lms'>!MMS ({{ currentByte }}/2000byte)</p>
  
  <div class="buttons">
    <button mat-raised-button (click)="onCancel()">취소</button>
    <button mat-raised-button type="submit" color="primary" [disabled]="smsForm.invalid || isButtonPressed" (click)="onSubmit()">전송</button>
  </div>
</form>