<!-- 드래그가 가능하도록 옵션을 추가. cdkDragRootElement으로 드래그가 가능한 root 영역을 지정한다. -->
<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <h1>고객 주문 이력</h1>
  <p class="history">
    {{ history }}
  </p>
  <button mat-icon-button class="button-close" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<ag-grid-angular
  class="ag-theme-balham"
  [modules]="modules"
  [animateRows]="true"
  [immutableData]="true"
  [rowData]="rowData"
  [getRowNodeId]="getRowNodeId"
  [frameworkComponents]="frameworkComponents"

  [tooltipMouseTrack]="true"
  [tooltipShowDelay]="400"

  [groupRowRendererParams]="groupRowRendererParams"
  [groupUseEntireRow]="true"
  [groupDefaultExpanded]="1"
  [defaultGroupSortComparator]="defaultGroupSortComparator"

  [defaultColDef]="defaultColDef"
  [columnDefs]="columnDefs"
  [suppressAggFuncInHeader]="true"

  rowSelection="multiple"
>
</ag-grid-angular>
