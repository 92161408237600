/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { UnifiedOrderDoc, UnifiedOrderContextStatusCode } from '../../schema/3/schema';

import { UtilService } from '../../core/1/util.service';
import { ClipboardService } from '../../core/1/clipboard.service';
import { unifiedOrderStatusMappings, unifiedOrderDeliveryTypeMappings } from '../../core/1/string-map';
import { trimOrganization, weekdayKR, channelVendorDeliveryType } from '../../core/2/util';
import { UserService } from '../../core/2/user.service';
import { RoomService } from '../../core/4/room.service';

@Component({
  selector: 'app-readonly-order-action',
  templateUrl: './readonly-order-action.component.html',
  styleUrls: ['./order-action.component.scss', './readonly-order-action.component.scss']
})
export class ReadonlyOrderActionComponent implements OnInit, OnDestroy {
  unifiedDeliveryTypeMappings = unifiedOrderDeliveryTypeMappings;
  trimOrganization = trimOrganization;
  weekdayKR = weekdayKR;

  @Input() order: UnifiedOrderDoc;
  @Input() isDialog = false;
  @Output() actionDone = new EventEmitter<boolean>();

  channel: string;
  statusString = '...';

  minimalView = false;

  operatingGroupName = 'ABNORMAL';

  mainActionName = 'NA';
  mainActionDisplay = 'N/A';

  buttonPressed = {
    ACCEPT: false,
    COMPLETE: false,
    CANCEL: false,
    DELETE: false
  };

  // 오늘 주문이냐?
  isTodayOrder = true;

  cssClass = {};

  // TODO: 나중에는 지역별 설정과 연동
  deliveryMinutes = 40;
  deliveryMinutesTitle = '예상 배송 시간';
  deliveryMinutesOptions: number[];

  constructor(
    public roomService: RoomService,
    private clipboardService: ClipboardService,
    public utilService: UtilService,
    public userService: UserService,
  ) { }

  ngOnInit() {
    this.orderUpdated();

    if (this.order.deliveryType === 'BAERA') {
      this.deliveryMinutesTitle = '조리 시간';
    } else if (this.order.deliveryType === 'TAKEOUT') {
      this.deliveryMinutesTitle = '음식 픽업시간';
    }

    // BAERA아 TAKEOUT이 이후에 추가되어 API는 기존 것을 같이 사용하지만 의미는 약간 다르다.
    if (this.order.deliveryType === 'DELIVERY') {
      // 예상 배달 시간
      this.deliveryMinutesOptions = [30, 40, 50, 60, 90, 120];
      this.deliveryMinutes = 40;
    } else {
      // TAKEOUT : 음식 픽업시간
      // BAERA : 조리시간
      this.deliveryMinutesOptions = [5, 10, 15, 20, 25, 30];
      this.deliveryMinutes = 20;
    }
  }

  ngOnDestroy() {
  }

  orderUpdated() {
    this.updateView();
  }

  updateView() {
    this.cssClass = {
      // 주문 접수는 카드에 옵션을 함께 표시하므로 공간이 더 필요하다.
      newOrder: (this.order.contextStatusCode === UnifiedOrderContextStatusCode.NEW),

      // TODO: 일단은 ACCEPTED 전은 모두 NEW로 처리한다. 나중에는 변경될 수도 있다.
      NEW: (this.order.contextStatusCode === UnifiedOrderContextStatusCode.NEW || this.order.contextStatusCode === UnifiedOrderContextStatusCode.CEOACCEPTED),
      ACCEPTED: this.order.contextStatusCode === UnifiedOrderContextStatusCode.ACCEPTED,
      COOKED: this.order.contextStatusCode === UnifiedOrderContextStatusCode.COOKED,
      PICKEDUP: this.order.contextStatusCode === UnifiedOrderContextStatusCode.PICKEDUP,
      COMPLETED: this.order.contextStatusCode === UnifiedOrderContextStatusCode.COMPLETED,
      CANCELED: this.order.contextStatusCode === UnifiedOrderContextStatusCode.CANCELED,
      DELETED: this.order.contextStatusCode === UnifiedOrderContextStatusCode.DELETED
    };

    // Safari는 +0900 형식의 timezone을 인식하지 못하는 문제가 있어서 new Date(this.order.orderDate)를 사용하지 못한다.
    const orderDate = format(parseISO(this.order.orderDate), 'yyyy-MM-dd');
    const todayDate = format(new Date(), 'yyyy-MM-dd');
    this.isTodayOrder = orderDate === todayDate ? true : false;
    this.channel = channelVendorDeliveryType(this.order);
    this.statusString = unifiedOrderStatusMappings[this.order.contextStatusCode];
  }

  copyToClipboard(event: MouseEvent, text: string = null) {
    const el = event.target as HTMLElement;
    // nested element의 경우에 상위에서 복사가 또 발생하는 것을 막는다.
    event.stopPropagation();

    if (text) {
      this.clipboardService.copyTextToClipboard(text, el);
    } else {
      this.clipboardService.copyTextToClipboard(el.innerText, el);
    }
  }

  onOk() {
    // 취할 액션이 없이 창만 닫을 경우에는 false
    this.actionDone.emit(true);
  }
}
