/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../environments/environment';

// message, log에서 사용한다.
export const instanceId = uuidv4();

/**
 * 깊은 구조의 object에 사용
 */
export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export function debugLog(msg: string) {
  // 디버깅용
  if (environment.production === false) {
    console.log(msg);
  }
}

export function debugDir(msg: string) {
  // 디버깅용
  if (environment.production === false) {
    console.dir(msg);
  }
}

export interface DateDurationType {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

export interface DateSetOptions {
  year?: number;
  month?: number;
  date?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

export interface DateRangeButton {
  buttonName: string;
  startDateDurationFromToday: DateDurationType;
  endDateDurationFromStartDate: DateDurationType;
  startDateSetOptions?: DateSetOptions;
  endDateSetOptions?: DateSetOptions;
}

/**
 * 인증용 전화기 전화번호
 */
export const authTels = ['01092528608', '01046218608'];
 /**
  * 개발자 전화번호처럼 개발용으로 등록한 번호
  */
export const auxTels = ['01099022252', '01034675530'];
