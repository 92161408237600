<div class="container">
  <mat-tab-group animationDuration="0ms" (selectedIndexChange)="onTabChange($event)">
    <mat-tab *ngFor="let rawDoc of rawDocs" [label]="rawDoc.collection">
      <div class="json-area">
        <pre [innerHTML]="rawDoc.jsonDocToHtml">
        </pre>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="buttons">
    <button mat-raised-button color="primary" (click)="openJsonEditor()">데이터 변경</button>
    <button mat-raised-button (click)="onCloseDialog()">닫기</button>
  </div>
</div>
