/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';

import { AgGridModule } from '@ag-grid-community/angular';

import { environment } from '../../environments/environment';
import { SafePipe } from './safe.pipe';

import { MenuComponent } from './menu/menu.component';
import { OrderContactComponent } from './order-contact/order-contact.component';
import { AddressFormComponent } from './address-form/address-form.component';

import { DialogOrderActionComponent } from './dialog-order-action/dialog-order-action.component';
import { BaeminOrderActionComponent } from './dialog-order-action/baemin-order-action.component';
import { YogiyoOrderActionComponent } from './dialog-order-action/yogiyo-order-action.component';
import { ManualOrderActionComponent } from './dialog-order-action/manual-order-action.component';
import { ReadonlyOrderActionComponent } from './dialog-order-action/readonly-order-action.component';
import { CoupangeatsOrderActionComponent } from './dialog-order-action/coupangeats-order-action.component';

import { DialogSpinnerComponent } from './dialog-spinner/dialog-spinner.component';
import { DialogLoginComponent } from './dialog-login/dialog-login.component';

import { DialogCancelOrderComponent } from './dialog-cancel-order/dialog-cancel-order.component';
import { DialogSingleComponent } from './dialog-single/dialog-single.component';
import { DialogVroongStatusComponent } from './dialog-vroong-status/dialog-vroong-status.component';
import { DialogCombinenetStatusComponent } from './dialog-combinenet-status/dialog-combinenet-status.component';
import { DialogGhostriderOptionsPickupComponent } from './dialog-ghostrider-options-pickup/dialog-ghostrider-options-pickup.component';
import { DialogSendSmsComponent } from './dialog-send-sms/dialog-send-sms.component';
import { DialogOrderCenterComponent } from './dialog-order-center/dialog-order-center.component';
import { OrderActionComponent } from './dialog-order-center/order-action/order-action.component';
import { OrderMemoComponent } from './dialog-order-center/order-memo/order-memo.component';
import { FastContactComponent } from './dialog-order-center/fast-contact/fast-contact.component';
import { OrderHistoryGroupRendererComponent } from './dialog-order-center/dialog-order-history/order-history-group-renderer/order-history-group-renderer.component';
import { DialogPrintOrderComponent } from './dialog-print-order/dialog-print-order.component';
import { TimerComponent } from './timer/timer.component';
import { DialogRawDataComponent } from './dialog-raw-data/dialog-raw-data.component';
import { DialogJsonEditorComponent } from './dialog-json-editor/dialog-json-editor.component';
import { DialogConfirmOptionsComponent } from './dialog-json-editor/dialog-confirm-options/dialog-confirm-options.component';
import { DialogLogOrderComponent } from './dialog-log-order/dialog-log-order.component';
import { DialogOrderHistoryComponent } from './dialog-order-center/dialog-order-history/dialog-order-history.component';
import { DialogUnifiedDeliveryStatusComponent } from './dialog-unified-delivery-status/dialog-unified-delivery-status.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { DateTimeRangePickerComponent } from './date-time-range-picker/date-time-range-picker.component';
import { DateTimeSelectorComponent } from './date-time-selector/date-time-selector.component';
import { RelatedDeliveryComponent } from './dialog-order-center/related-delivery/related-delivery.component';
import { DeliveryInformationComponent } from './delivery-information/delivery-information.component';
import { DialogHelpLinkageComponent } from './dialog-help-linkage/dialog-help-linkage.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([
      DialogOrderHistoryComponent
    ]),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,

    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatRadioModule,
    MatDividerModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatIconModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTooltipModule,
    DragDropModule,
    MatListModule,
    OverlayModule,
  ],
  declarations: [
    SafePipe,

    MenuComponent,
    OrderContactComponent,
    AddressFormComponent,

    DialogSpinnerComponent,
    DialogLoginComponent,

    DialogOrderActionComponent,
    BaeminOrderActionComponent,
    YogiyoOrderActionComponent,
    ManualOrderActionComponent,
    ReadonlyOrderActionComponent,
    CoupangeatsOrderActionComponent,

    DialogCancelOrderComponent,
    DialogSingleComponent,
    DialogVroongStatusComponent,
    DialogCombinenetStatusComponent,
    DialogGhostriderOptionsPickupComponent,
    DialogSendSmsComponent,
    DialogOrderCenterComponent,
    OrderActionComponent,
    OrderMemoComponent,
    FastContactComponent,
    OrderHistoryGroupRendererComponent,
    DialogPrintOrderComponent,
    TimerComponent,
    DialogRawDataComponent,
    DialogJsonEditorComponent,
    DialogConfirmOptionsComponent,
    DialogLogOrderComponent,
    DialogOrderHistoryComponent,
    DialogUnifiedDeliveryStatusComponent,
    CustomTooltipComponent,
    DateTimeRangePickerComponent,
    DateTimeSelectorComponent,
    RelatedDeliveryComponent,
    DeliveryInformationComponent,
    DialogHelpLinkageComponent,
  ],
  exports: [
    SafePipe,

    MenuComponent,
    OrderContactComponent,
    AddressFormComponent,

    DialogSpinnerComponent,
    DialogLoginComponent,

    DialogOrderActionComponent,
    BaeminOrderActionComponent,
    YogiyoOrderActionComponent,
    ManualOrderActionComponent,
    ReadonlyOrderActionComponent,
    CoupangeatsOrderActionComponent,

    DialogCancelOrderComponent,
    DialogSingleComponent,
    DialogVroongStatusComponent,
    DialogCombinenetStatusComponent,
    DialogGhostriderOptionsPickupComponent,
    DialogSendSmsComponent,
    DialogOrderCenterComponent,
    OrderActionComponent,
    OrderMemoComponent,
    FastContactComponent,
    OrderHistoryGroupRendererComponent,
    DialogPrintOrderComponent,
    TimerComponent,
    DateTimeRangePickerComponent,
    DateTimeSelectorComponent
  ],
  providers: [
    // SETTINGS DI Token은 angularfire 소스 코드에서 찾았다.
    // https://firebase.google.com/docs/reference/js/firebase.firestore.Settings#optional-ignoreundefinedproperties
    { provide: SETTINGS, useValue: { ignoreUndefinedProperties: true } }
  ]
})
export class SharedModule { }
