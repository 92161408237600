<ng-container *ngFor="let site of uiSites">
  <div class="title">{{ site.siteName }} 최소 픽업 시간</div>
  <div>
    <mat-radio-group (change)="onChangePickupOption(site.siteKey, $event)">
      <mat-radio-button *ngFor="let option of pickupOptions" [value]="option" [checked]="option === site.minPickupOption">{{ option + '분' }}</mat-radio-button>
    </mat-radio-group>
  </div>
</ng-container>

<div class="buttons">
  <button mat-raised-button (click)="onCancel()">닫기</button>
  <button mat-raised-button color="accent" (click)="onSubmit()">변경</button>
</div>
