/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { RowNode } from '@ag-grid-community/core';
import { CallOutputDeliveryTip } from '../../schema/4/schema-functions-call';

/**
 * 가장 최근에 조회한 주소 정보를 표현한다.
 *
 * UnifiedOrder의 주소 관련 부분만 분리
 */
export interface LatestAddress {
  address_key: string;
  address_detail: string;
  address_sido: string;
  address_sigungu: string;
  address_dong: string;
  address_jibun: string;
  address_dongH: string;
  address_road: string | null;
  address_building_name: string;
  address_location: {
    lat: number;
    lon: number;
  };
}

export type DeliveryTip = CallOutputDeliveryTip['deliveryTip'];

/**
 * 접수 이후에 요기요 주문에 대한 전화번호를 전달할 때 사용
 */
export interface YogiyoUserTel {
  orderId: string;
  userTel: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationCenterService {

  constructor() { }

  readonly latestAddress0 = {
    address_key: '',
    address_detail: '',
    address_sido: '',
    address_sigungu: '',
    address_dong: '',
    address_jibun: '',
    address_dongH: '',
    address_road: null,
    address_building_name: '',
    address_location: {
      lat: 0,
      lon: 0
    }
  };

  public latestAddressSubject = new BehaviorSubject<LatestAddress>(this.latestAddress0);

  public rowNodeRefreshedSubject = new BehaviorSubject<RowNode>(null);

  public latestDeliveryTips = new Subject<CallOutputDeliveryTip['deliveryTip']>();

  public yogiyoUserTel = new Subject<YogiyoUserTel>();

  clearLatestAddress() {
    this.latestAddressSubject.next(this.latestAddress0);
  }
}
