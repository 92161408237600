/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './auth-guard.service';
import { InitGuardService } from './init-guard.service';
import { MenuGuardService } from './menu-guard.service';

import { AccountInitGuardService } from './account-init-guard.service';

const routes: Routes = [
  {
    path: 'embedded-web-site',
    pathMatch: 'full',
    canActivateChild: [AuthGuardService, InitGuardService, AccountInitGuardService],
    loadChildren: () => import('./pages/embedded-web-site/embedded-web-site.module').then(m => m.EmbeddedWebSiteModule)
  },
  {
    path: '',
    canActivateChild: [AuthGuardService, InitGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'unified-order'
      },
      {
        path: 'unified-order',
        loadChildren: () => import('./pages/unified-order/unified-order.module').then(m => m.UnifiedOrderModule),
      },
      {
        path: 'staging-order',
        pathMatch: 'full',
        loadChildren: () => import('./pages/staging-order/staging-order.module').then(m => m.StagingOrderModule)
      },
      {
        path: 'unified-order-range',
        pathMatch: 'full',
        loadChildren: () => import('./pages/unified-order-range/unified-order-range.module').then(m => m.UnifiedOrderRangeModule)
      },
      {
        path: 'direct-order',
        pathMatch: 'full',
        loadChildren: () => import('./pages/direct-order/direct-order.module').then(m => m.DirectOrderModule)
      },
      {
        path: 'cherrypick-delivery',
        pathMatch: 'full',
        loadChildren: () => import('./pages/cherrypick-delivery/cherrypick-delivery.module').then(m => m.CherrypickDeliveryModule)
      },
      {
        path: 'ghokirun-delivery',
        pathMatch: 'full',
        loadChildren: () => import('./pages/ghokirun-delivery/ghokirun-delivery.module').then(m => m.GhokirunDeliveryModule)
      },
      {
        path: 'baemin-operating-ad-campaign',
        pathMatch: 'full',
        loadChildren: () => import('./pages/baemin-operating-ad-campaign/baemin-operating-ad-campaign.module').then(m => m.BaeminOperatingAdCampaignModule)
      },
      {
        path: 'baemin-campaign-map/:room/:shopNo',
        pathMatch: 'full',
        loadChildren: () => import('./pages/baemin-campaign-map/baemin-campaign-map.module').then(m => m.BaeminCampaignMapModule)
      },
      {
        path: 'printer',
        pathMatch: 'full',
        loadChildren: () => import('./pages/printer/printer.module').then(m => m.PrinterModule)
      },
      {
        path: 'organization',
        pathMatch: 'full',
        loadChildren: () => import('./pages/organization/organization.module').then(m => m.OrganizationModule)
      },
      {
        path: 'simple-notice',
        pathMatch: 'full',
        loadChildren: () => import('./pages/simple-notice/simple-notice.module').then(m => m.SimpleNoticeModule)
      },
      {
        path: 'contact',
        pathMatch: 'full',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'site',
        pathMatch: 'full',
        loadChildren: () => import('./pages/site/site.module').then(m => m.SiteModule)
      },
      {
        path: 'room',
        pathMatch: 'full',
        loadChildren: () => import('./pages/room/room.module').then(m => m.RoomModule)
      },
      {
        path: 'manual-order',
        canActivate: [MenuGuardService],
        loadChildren: () => import('./pages/manual-order/manual-order.module').then(m => m.ManualOrderModule)
      },
      {
        path: 'baemin-menu',
        canActivate: [MenuGuardService],
        loadChildren: () => import('./pages/baemin-menus/baemin-menus.module').then(m => m.BaeminMenusModule)
      },
      {
        path: 'order-action',
        pathMatch: 'full',
        loadChildren: () => import('./pages/order-action/order-action.module').then(m => m.OrderActionModule)
      },
      {
        path: 'baemin-review',
        pathMatch: 'full',
        loadChildren: () => import('./pages/baemin-review/baemin-review.module').then(m => m.BaeminReviewModule)
      },
      {
        path: 'delivery-area',
        pathMatch: 'full',
        loadChildren: () => import('./pages/delivery-area/delivery-area.module').then(m => m.DeliveryAreaModule)
      },
      {
        path: 'notice',
        pathMatch: 'full',
        loadChildren: () => import('./pages/notice/notice.module').then(m => m.NoticeModule)
      },
      {
        path: 'baemin-ceo-delivery-region',
        pathMatch: 'full',
        loadChildren: () => import('./pages/baemin-ceo-delivery-region/baemin-ceo-delivery-region.module').then(m => m.BaeminCeoDeliveryRegionModule)
      },
      {
        path: 'unified-delivery',
        pathMatch: 'full',
        loadChildren: () => import('./pages/unified-delivery/unified-delivery.module').then(m => m.UnifiedDeliveryModule)
      },
      {
        path: 'unified-delivery-range',
        pathMatch: 'full',
        loadChildren: () => import('./pages/unified-delivery-range/unified-delivery-range.module').then(m => m.UnifiedDeliveryRangeModule)
      },
      {
        path: 'ghokirun-rider',
        pathMatch: 'full',
        loadChildren: () => import('./pages/ghokirun-rider/ghokirun-rider.module').then(m => m.GhokirunRiderModule)
      },
      {
        path: 'ghokirun-delivery-range',
        pathMatch: 'full',
        loadChildren: () => import('./pages/ghokirun-delivery-range/ghokirun-delivery-range.module').then(m => m.GhokirunDeliveryRangeModule)
      },
      {
        path: 'site-dong-db',
        pathMatch: 'full',
        loadChildren: () => import('./pages/site-dong-db/site-dong-db.module').then(m => m.SiteDongDbModule)
      },
      {
        path: 'unified-delivery-shop',
        pathMatch: 'full',
        loadChildren: () => import('./pages/unified-delivery-shop/unified-delivery-shop.module').then(m => m.UnifiedDeliveryShopModule)
      },
      {
        path: 'log-order/:site/:room',
        pathMatch: 'full',
        loadChildren: () => import('./pages/log-order/log-order.module').then(m => m.LogOrderModule),
      },
      {
        path: 'reply-sms',
        pathMatch: 'full',
        loadChildren: () => import('./pages/reply-sms/reply-sms.module').then(m => m.ReplySmsModule)
      },
      {
        path: 'linkage-state',
        pathMatch: 'full',
        loadChildren: () => import('./pages/linkage-state/linkage-state.module').then(m => m.LinkageStateModule)
      },
      {
        path: 'unified-menu',
        pathMatch: 'full',
        loadChildren: () => import('./pages/unified-menu/unified-menu.module').then(m => m.UnifiedMenuModule)
      },
      {
        path: 'baemin-user-detail-shop-v8',
        pathMatch: 'full',
        loadChildren: () => import('./pages/baemin-user-detail-shop-v8/baemin-user-detail-shop-v8.module').then(m => m.BaeminUserDetailShopV8Module)
      },
      {
        path: 'baemin-ceo-shops-open',
        pathMatch: 'full',
        loadChildren: () => import('./pages/baemin-ceo-shops-open/baemin-ceo-shops-open.module').then(m => m.BaeminCeoShopsOpenModule)
      },
      {
        path: 'yogiyo-restaurant',
        loadChildren: () => import('./pages/yogiyo-restaurant/yogiyo-restaurant.module').then(m => m.YogiyoRestaurantModule)
      },
      {
        path: 'baemin-ceo-manager',
        pathMatch: 'full',
        loadChildren: () => import('./pages/baemin-ceo-manager/baemin-ceo-manager.module').then(m => m.BaeminCeoManagerModule)
      },
      {
        path: 'baemin-ceo-shop-owner-query',
        pathMatch: 'full',
        loadChildren: () => import('./pages/baemin-ceo-shop-owner-query/baemin-ceo-shop-owner-query.module').then(m => m.BaeminCeoShopOwnerQueryModule)
      },
      {
        path: 'pos-active-device',
        pathMatch: 'full',
        loadChildren: () => import('./pages/pos-active-device/pos-active-device.module').then(m => m.PosActiveDeviceModule)
      },
    ]
  },
  // {
  //   path: 'dev',
  //   loadChildren: './dev/dev.module#DevModule',
  //   canLoad: [AuthGuardService],
  //   canActivate: [AuthGuardService]
  // },
  {
    path: '**',
    redirectTo: 'unified-order'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
