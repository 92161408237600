/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { cloneDeep } from 'lodash-es';
import { asyncScheduler, BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { GhokirunRiderDoc, GhokirunRiderDocs, GhokirunRiderStatusDoc, MergedGhokirunRider } from '../../schema/1/schema-ghokirun';
import { UserService } from '../2/user.service';

const riderCollectionPath = 'ghokirunRider';
const statusCollectionPath = 'ghokirunRiderStatus';

@Injectable({
  providedIn: 'root'
})
export class GhokirunRiderService {
  public riders: GhokirunRiderDocs;
  public latestSubject = new BehaviorSubject<GhokirunRiderDocs>({});

  public mergedGhokirunRiderDocs: MergedGhokirunRider[];
  public latestMergedGhokirunRiderSubject = new BehaviorSubject<MergedGhokirunRider[]>([]);
  public mergedGhokirunRiderSubscription: Subscription;

  constructor(
    private db: AngularFirestore,
    private userService: UserService
  ) { }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  public observeRider() {
    const organization = this.userService.organization;
    const queryFn: QueryFn = ref => ref.where('organization', '==', organization);
    const collectionRef = this.db.collection<GhokirunRiderDoc>(riderCollectionPath, queryFn);

    console.log(`${this.constructor.name}::observe ${riderCollectionPath}`);

    collectionRef.valueChanges().subscribe(docs => {
      this.riders = docs.reduce((acc, doc) => { acc[doc._id] = doc; return acc; }, {});
      this.latestSubject.next(this.riders);
    });
  }

  public async setRider(doc: Partial<GhokirunRiderDoc>) {
    const riderCollection = this.db.firestore.collection(riderCollectionPath);
    const riderDocRef = riderCollection.doc();

    const riderDoc: GhokirunRiderDoc = {
      _id: riderDocRef.id,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      organization: doc.organization,
      riderId: riderDocRef.id,
      riderName: doc.riderName,
      riderTel: doc.riderTel,
      riderTelConfirmed: false,
      riderTelConfirmedAt: '',
      riderVehicle: doc.riderVehicle
    };

    try {
      await this.db.doc<GhokirunRiderDoc>(riderDocRef).set(riderDoc);
    } catch (error) {
      throw new Error(error);
    }

    const statusCollection = this.db.firestore.collection(statusCollectionPath);
    const statusDocRef = statusCollection.doc();

    const statusDoc: GhokirunRiderStatusDoc = {
      _id: statusDocRef.id,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      riderId: riderDoc.riderId,
      networkStatus: 'down',
      riderDeliveryStatus: 'off',
      appIsActive: false,
      appNotification: false,
    };

    return this.db.doc<GhokirunRiderStatusDoc>(statusDocRef).set(statusDoc);
  }

  /** online(ready, running) 상태와 전화번호 인증이 유효한 라이더 목록만 가져온다 */
  public observeOnlineRiders() {
    this.unsubscribe();

    const riderStatusQueryFn: QueryFn = ref => ref.where('riderDeliveryStatus', 'in', ['ready', 'running']).where('networkStatus', '==', 'up');
    const riderStatusDocs = this.db.collection<GhokirunRiderStatusDoc>(statusCollectionPath, riderStatusQueryFn).valueChanges();
    const riderQueryFn: QueryFn = ref => ref.where('riderTelConfirmed', '==', true);
    const riderDocs = this.db.collection<GhokirunRiderDoc>(riderCollectionPath, riderQueryFn).valueChanges();

    this.mergedGhokirunRiderSubscription = combineLatest([riderDocs, riderStatusDocs])
      .pipe(
        // 여러명의 라이더가 있을 경우 위치정보가 무수히 많은 이벤트를 발생시킬 것이다.
        throttleTime(300, asyncScheduler, { leading: false, trailing: true }),
      )
      .subscribe(([rider0, riderStatus0]) => {
        const riders = cloneDeep(rider0);
        const riderStatuses = cloneDeep(riderStatus0);

        const onlineRiderList = riderStatuses.map(status => status.riderId);

        const filteredRiders = riders
          // riderStatus의 내용을 참조하여 online상태의 라이더로 필터한다
          .filter(rider => onlineRiderList.includes(rider.riderId))
          // riderStatus의 상태를 병합한다.
          .map(rider => {
            const riderStatus = riderStatuses.find(status => status.riderId === rider.riderId);
            return { ...riderStatus, ...rider, _ui: { statusId: riderStatus._id } };
          });

        this.mergedGhokirunRiderDocs = filteredRiders;
        this.latestMergedGhokirunRiderSubject.next(this.mergedGhokirunRiderDocs);
      });
  }

  public unsubscribe() {
    this.mergedGhokirunRiderSubscription?.unsubscribe();
    this.mergedGhokirunRiderSubscription = undefined;
  }
}
