<mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="주의사항">
      <section>
        <h3>신규 입점 업소 연동시 주의사항</h3>
        <article>
          <p>
            기존 업소가 퇴점하고 새로 입점하는 경우, 기존 업소 정보가 남아있다면,
            <br>연동 정보 입력 전 <span class="highlight red-bg">퇴점(초기화)</span>를 수행해야 합니다.
          </p>
          <p class="inform">
            초기화를 하지 않은 퇴점 업소가 간혹 있으니 새로운 업소가 입점하는 경우
            <br>우선 초기화를 진행한 후 연동 정보를 입력하는 것을 추천드립니다.
          </p>
          <p class="inform">퇴점 버튼은 잘못 누르는 것을 방지하기 위해 숨겨져있습니다.</p>
          <img src="assets/images/reset-room.png">
        </article>
      </section>
    </mat-tab>

    <mat-tab label="배달의민족">
      <section>
        <h3>배달의민족 연동</h3>
        <article>
          <h4>배민 사장님광장</h4>
          <p>1. <a href="https://ceo.baemin.com/web/login" target="_brank">배달의민족 사장님 광장</a>에 로그인합니다.</p>

          <p>2. 매니저정보에 '발가락: 01092528608'을 추가합니다.</p>
          <p class="inform with-img">
            사장님광장 -> 내정보 -> 매니저정보
            ※ SMS수신은 체크하지 않습니다.
          </p>
          <img class="width-320" src="assets/images/baemin-ceo-manager.png">

          <p>3. 대표 업소명 확인('5. 업소 정보 입력'과정에 필요한 정보입니다.)</p>
          <p class="inform with-img">사장님광장 -> 셀프서비스 -> 가게관리 -> 기본정보 -> 가게 이름</p>
          <img class="width-320" src="assets/images/baemin-ceo-shopname.png">

          <p>4. 영업시간 확인('5. 업소 정보 입력'과정에 필요한 정보입니다.)</p>
          <p class="inform with-img">
            사장님광장 -> 셀프서비스 -> 가게관리 -> 운영정보 -> 영업시간
            <br>요일별로 영업시간이 다를 경우 시작시간은 가장 빠른 시간으로, 종료시간은 가장 늦은 시간으로 계산합니다.
            <br>아래와 예시의 경우 영업시간은 10:00 ~ 00:00 입니다.
          </p>
          <img class="width-320" src="assets/images/baemin-ceo-time-range.png">

          <h4>발가락 OMC - 연동설정</h4>
          <p>5. 업소 정보 입력</p>
          <p class="inform with-img">
            배민을 기준으로 대표업소, 운영시간을 입력합니다.(이미 있는 경우 내용을 한 번더 확인해주세요.)
          </p>
          <img src="assets/images/omc-room-setting.png">

          <p>6. 연동 추가
            <br>연동하려는 호실의 <span class="highlight blue-bg">연동 추가...</span>로 배민 연동을 진행합니다.
          </p>
          <p class="inform with-img">
            사용자ID 와 비밀번호를 입력해주세요.(자동입력된 이름과 managerTel은 특별한 경우가 아니라면 그대로 유지합니다.)
          </p>
          <img src="assets/images/baemin-account.png">

        </article>
      </section>
    </mat-tab>

    <mat-tab label="쿠팡이츠">
      <section>
        <h3>쿠팡이츠 연동</h3>
        <article>
          <h4>쿠팡이츠 스토어</h4>
          <p>1. <a href="https://store.coupangeats.com/merchant/login" target="_brank">쿠팡이츠 스토어</a>에 로그인합니다.</p>

          <p>2. 연동할 매장의 스토어 아이디를 확인합니다.('3. 연동 추가'과정에 필요한 정보입니다.)</p>
          <p class="inform with-img">
            쿠팡이츠 Portal -> 매장 정보 관리
            <br>※ '상태'가 '앱 노출중'인 매장이어야 합니다.
          </p>
          <img src="assets/images/coupangeats-store-id.png">

          <h4>발가락 OMC - 연동설정</h4>
          <p>3. 연동 추가
            <br>연동하려는 호실의 <span class="highlight blue-bg">연동 추가...</span>로 쿠팡이츠 연동을 진행합니다.
          </p>
          <p class="inform with-img">
            이름, storeId, 사용자ID, 비밀번호를 모두 입력해주세요.
          </p>
          <img src="assets/images/coupangeats-account.png">
        </article>
      </section>
    </mat-tab>

    <mat-tab label="요기요">
      <section>
        <h3>요기요 연동</h3>
        <article>
          <h4>요기요 사장님사이트</h4>
          <p>1. <a href="https://owner.yogiyo.co.kr" target="_brank">요기요 사장님사이트</a>에 로그인합니다.</p>

          <p>2. 연동하고자 하는 상호를 선택합니다.</p>
          <p class="inform with-img">내 가게 관리 -> 내 정보관리 - 상호명 선택</p>
          <img src="assets/images/yogiyo-shop-name.png">

          <p>3. '문자 주문 접수'에 SMS 번호 추가</p>
          <p class="inform with-img">내 가게 관리 -> 내 정보관리 - 상호명 선택</p>
          <img src="assets/images/yogiyo-add-sms.png" style="margin-bottom: 0px;">
          <p class="inform with-img">연락처: 01092528608, 사용여부: 미사용, 성함: 고스트키친 발가락</p>
          <img class="width-320" src="assets/images/yogiyo-add-sms-form.png">

          <p>4. 가게 정보에 사업자 등록번호를 확인합니다.('5. 연동 추가'과정에 필요한 정보입니다.)</p>

          <h4>발가락 OMC - 연동설정</h4>
          <p>5. 연동 추가
            <br>연동하려는 호실의 <span class="highlight blue-bg">연동 추가...</span>로 요기요 연동을 진행합니다.
          </p>
          <p class="inform with-img">사업자 등록번호, 사용자ID, 비밀번호를 입력합니다.</p>
          <img src="assets/images/yogiyo-account.png">
        </article>
      </section>
    </mat-tab>

    <mat-tab label="배달대행사">
      <section>
        <h3>배달대행사</h3>
        <article>
          <h4>발가락 OMC - 연동설정</h4>
          <p>1. 바로고
            <br>연동하려는 호실의 <span class="highlight blue-bg">연동 추가...</span>로 바로고 연동을 진행합니다.
          </p>
          <p class="inform with-img">
            ※ 연동하려는 지점의 담당자를 통해 업소 등록이 완료된 후 진행해야합니다.
            <br>'orderAgencyStoreId' 값을 입력후 '등록 확인'을 통해 정상 등록여부를 확인해주세요.
          </p>
          <img src="assets/images/barogo-account.png">

          <p>
            2. 그 외 대행사(런투유, 스파이더, 생각대로, 만나플래닛, 젠딜리)
            <br>연동하려는 호실의 <span class="highlight blue-bg">연동 추가...</span>로 연동을 진행합니다.
            <br>바로고를 제외한 대행사들은 입력 요구사항이 다르지만 동일한 흐름으로 진행됩니다.
          </p>
          <p class="inform with-img">
            '생각대로'를 예로 들었으나 다른 대행사도 같은 순서로 진행해주시면 됩니다.
          </p>
          <img src="assets/images/delivery-account.png">
        </article>
      </section>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="closeDialog()">닫기</button>
</mat-dialog-actions>
