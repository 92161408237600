/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import format from 'date-fns/format';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import stringify from 'json-stable-stringify';

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LogService } from '../../core/3/log.service';
import { CollectionPath, FirebaseManagerService } from '../../core/4/firebase-manager.service';

import { DialogJsonEditorService } from '../dialog-json-editor/dialog-json-editor.service';

@Component({
  templateUrl: './dialog-raw-data.component.html',
  styleUrls: ['./dialog-raw-data.component.scss']
})
export class DialogRawDataComponent implements OnInit {
  public rawDocs: { collection: CollectionPath; jsonDocToHtml: any; jsonDoc: any }[] = [];

  private selectedTab: number;

  constructor(
    public dialogRef: MatDialogRef<DialogRawDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { collectionName: CollectionPath, docId: string },
    private firebaseManager: FirebaseManagerService,
    private logService: LogService,
    private dialogJsonEditorService: DialogJsonEditorService
  ) { }

  async ngOnInit() {
    const { collectionName, docId } = this.data;

    try {
      // 1. data로 호출한 메인 collection, id로 doc을 가져온다.
      const mainDoc = await this.firebaseManager.getDoc<any>(`${collectionName}/${docId}`);
      if (mainDoc) {
        this.rawDocs.push({ collection: collectionName, jsonDocToHtml: this.makeJsonToHtml(mainDoc), jsonDoc: mainDoc });
      } else {
        this.logService.withToastrWarn(`${collectionName}/${docId}가 존재하지 않습니다.`);
      }

      // 2. 불러온 collection과 관련된 collction명을 가져온다.
      const relatedCollections = this.getRelatedCollections(collectionName, mainDoc);

      // 3. 관련된 collection들을 불러와 배열에 추가한다.
      for (const relatedCollection of relatedCollections) {
        const subId = this.getRelatedCollectionDocId(collectionName, mainDoc);
        if (subId) {
          const relatedDoc = await this.firebaseManager.getDoc<any>(`${relatedCollection}/${subId}`);

          if (relatedDoc) {
            this.rawDocs.push({ collection: relatedCollection, jsonDocToHtml: this.makeJsonToHtml(relatedDoc), jsonDoc: relatedDoc });
          } else {
            this.logService.withToastrWarn(`관련 doc이 있어야 할텐데 없네요. dev라면 가능합니다: ${relatedCollection}/${subId}`);
          }
        }
      }
    } catch (error) {
      this.logService.withToastrCatch(error, `dialog-raw-data에서 예외 발생`);
    }
  }

  public onTabChange(index: number) {
    this.selectedTab = index;
  }

  public openJsonEditor() {
    const collection = this.rawDocs[this.selectedTab].collection;
    const docId = this.rawDocs[this.selectedTab].jsonDoc._id;

    this.dialogJsonEditorService.openDialog(collection, docId);
  }

  public onCloseDialog() {
    this.dialogRef?.close(false);
    this.dialogRef = undefined;
  }

  private makeJsonToHtml(doc: any) {
    // 시간을 보기편한 형태로 변경한다.
    this.formatTimestamp(doc);

    const jsonDoc = stringify(doc, {
      cmp: (a, b) => {
        return a.key.localeCompare(b.key);
      }, space: 2
    });

    return Prism.highlight(jsonDoc, Prism.languages.json, 'json');
  }

  private formatTimestamp(doc: any) {
    for (const timestampKey of ['_timeCreate', '_timeUpdate']) {
      if (doc?.[timestampKey]) {
        // 포맷을 변경한다.
        doc[timestampKey] = format(new Date(doc[timestampKey].toDate()), 'yyyy-MM-dd HH:mm:ss');
      }
    }
  }

  /**
   * 관련 collection을 읽기 위한 id 값을 반환한다.
   */
  private getRelatedCollectionDocId(mainCollection: string, mainDoc: any) {
    if (mainDoc === undefined) {
      return undefined;
    }

    switch (mainCollection) {
      case 'unifiedOrder':
        // 쿠팡이츠는 _id를 파싱하여 사용하고, 배민, 요기요는 orderNo을 사용한다.
        const orderNo = mainDoc.orderVendor === 'coupangeats' ? mainDoc._id.split('-')[1] : mainDoc.orderNo;
        return orderNo;
      default:
        return mainDoc._id;
    }
  }

  /**
   * collection과 관련된 collection 명을 배열로 반환한다.
   */
  private getRelatedCollections(mainCollection: string, mainDoc: any): CollectionPath[] {
    if (mainDoc === undefined) {
      return [];
    }

    // 관련 collection 맵 객체
    const collectionsMap: {
      unifiedOrder: {
        [vendor: string]: CollectionPath[];
      };
      unifiedDelivery: {
        [vendor: string]: CollectionPath[];
      };
    } = {
      unifiedOrder: {
        baemin: ['baeminAppListOrder', 'baeminAppDetailOrder'],
        coupangeats: ['coupangeatsAppOrder'],
        yogiyo: ['yogiyoAppListOrder']
      },
      unifiedDelivery: {
        run2u: ['combinenetDelivery'],
        spidor: ['combinenetDelivery'],
        barogo: ['barogoDelivery'],
        vroong: ['vroongPosDelivery'],
        logiall: ['logiallDelivery'],
        manna: ['mannaDelivery'],
        zendeli: ['zendeliDelivery'],
        shero: ['moaplanetDelivery'],
        dalgo: ['moaplanetDelivery'],
        iudream: ['iudreamDelivery'],
        baedalyo: ['baedalyoDelivery'],
        baedalhero: ['baedalyoDelivery'],
      }
    };

    switch (mainCollection) {
      case 'unifiedOrder':
        // 1. manual action은 외부 벤더가 없으므로 더 이상 표시할 것이 없다.
        const isManualAction = mainDoc.orderVendor === 'ghostkitchen' ||
          mainDoc.createdBy === 'manual' ||
          mainDoc.createdBy === 'face';

        if (isManualAction) {
          return [];
        }

        // 2. orderVendor와 관련있는 collection 맵핑
        return collectionsMap[mainCollection][mainDoc.orderVendor] ?? [];
      case 'unifiedDelivery':
        return collectionsMap[mainCollection][mainDoc.deliveryVendor] ?? [];
      default:
        return [];
    }
  }
}
