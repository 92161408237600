/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogConfirmOptionsComponent } from './dialog-confirm-options.component';

@Injectable({
  providedIn: 'root'
})
export class DialogConfirmOptionsService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialog() {
    const dialogRef = this.dialog.open(DialogConfirmOptionsComponent);

    return dialogRef;
  }
}
