/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { RowNode } from '@ag-grid-community/core';

@Component({
  selector: 'app-order-history-group-renderer',
  templateUrl: './order-history-group-renderer.component.html',
  styleUrls: ['./order-history-group-renderer.component.scss']
})
export class OrderHistoryGroupRendererComponent implements ICellRendererAngularComp {
  public node: RowNode;

  agInit(params: any) {
    this.node = params.node;
  }

  refresh(): boolean {
    return false;
  }
}
