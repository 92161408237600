/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import Swal from 'sweetalert2';
import get from 'lodash-es/get';
import cloneDeep from 'lodash-es/cloneDeep';
import firebase from 'firebase/app';
import firestore = firebase.firestore;

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/functions';

import { CombinenetDeliveryDoc } from '../../schema/2/schema-combinenet';
import { CallInputCombinenetApi, CallInputRecoverDelivery, CallOutputCombinenetApi, CallOutputRecoverDelivery } from '../../schema/4/schema-functions-call';

import { telFormatter } from '../../core/1/ag-util';
import { UtilService } from '../../core/1/util.service';
import { combinenetStateCodeMappings, combinenetPayMethodMappings } from '../../core/1/string-map';
import { toDate, diffTime, trimOrganization } from '../../core/2/util';
import { UserService } from '../../core/2/user.service';
import { LogService } from '../../core/3/log.service';
import { FirebaseManagerService } from '../../core/4/firebase-manager.service';
import { RoomService } from '../../core/4/room.service';

import { DialogSpinnerService } from '../../shared/dialog-spinner/dialog-spinner.service';
import { DialogSpinnerComponent } from '../../shared/dialog-spinner/dialog-spinner.component';
import { DialogJsonEditorService } from '../dialog-json-editor/dialog-json-editor.service';

interface TIMESTAMP {
  name: string;
  path?: string;
  date?: Date;
  relativeToCreatedAt?: string;
  relativeToNow?: string;
  class?: string;
}

@Component({
  selector: 'app-dialog-combinenet-status',
  templateUrl: './dialog-combinenet-status.component.html',
  styleUrls: ['./dialog-combinenet-status.component.scss']
})
export class DialogCombinenetStatusComponent implements OnInit, OnDestroy {
  delivery: CombinenetDeliveryDoc;
  private spinnerDialogRef: MatDialogRef<DialogSpinnerComponent, any>;

  // UI에 적합한 형태로 변환
  status = '??';
  shopName = '';
  payMethod: string;
  agentTel: string;
  userTel: string;

  sortedTimes: TIMESTAMP[];
  times: {
    [key: string]: TIMESTAMP;
  } = {
      created_at: {
        name: '신청',
        path: 'orderStatus.requestTime',
      },
      assigned_at: {
        name: '배차',
        path: 'orderStatus.caralcTime',
      },
      picked_up_at: {
        name: '픽업',
        path: 'orderStatus.pickupTime',
      },
      requested_pick_up_at: {
        name: '*요청픽업*',
        path: '',
      },
      delivered_at: {
        name: '완료',
        path: 'orderStatus.completeTime',
      },
      now: {
        name: '--- 현재 ---',
        class: 'blink'
      },
    };

  constructor(
    public userService: UserService,
    public dialogRef: MatDialogRef<DialogCombinenetStatusComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: { delivery: CombinenetDeliveryDoc },
    private fns: AngularFireFunctions,
    private dialogSpinnerService: DialogSpinnerService,
    private utilService: UtilService,
    private roomService: RoomService,
    private logService: LogService,
    private dialogJsonEditorService: DialogJsonEditorService,
    private firebaseManager: FirebaseManagerService,
  ) {
    this.delivery = cloneDeep(data.delivery);
  }

  ngOnInit() {
    if (this.delivery.orderStatus) {
      this.status = combinenetStateCodeMappings[this.delivery.orderStatus?.stateCode] ?? '몰라';
      this.payMethod = combinenetPayMethodMappings[this.delivery.orderAddRequest?.paymentType] ?? '몰라';
      // this.payMethod = this.delivery.orderStatus.realPaymentType;
      if (this.delivery.orderStatus.riderTel) {
        this.agentTel = telFormatter(this.delivery.orderStatus?.riderTel);
      }
      this.userTel = telFormatter(this.delivery.orderAddRequest?.tel);
    }

    this.shopName = `${trimOrganization(this.roomService.rooms[this.delivery.room]?.name)} ${this.delivery.relatedOrder?.shopName}`;

    this.updateTimes();
  }

  ngOnDestroy() {
    this.dialogRef?.close();
    this.spinnerDialogRef?.close();
  }

  updateTimes() {
    // 2020-06-05 : 서버의 지속적인 에러로 orderStaus가 없는 경우가 있다.
    if (!(this.delivery.orderStatus && this.delivery.orderStatus?.requestTime)) {
      return;
    }

    // delivery 속성만 업데이트 (path가 있는 경우)
    for (const timestamp of Object.values(this.times)) {
      const path = timestamp.path;
      if (path) {
        // path는 'k1.k2'와 같은 keyPath 형식이다. 그래서 lodash get을 이용한다.
        const timeString = get(this.delivery, path);
        if (timeString) {
          timestamp.date = toDate(timeString);
        }
      }
    }

    // 요청 픽업 계산하기
    // 런투유의 이상으로 orderAddRequest가 없는 경우가 있다.
    if (this.delivery.orderAddRequest) {
      this.times.requested_pick_up_at.date = new Date(this.times.created_at.date.getTime() + this.delivery.orderAddRequest.waitTime * 60 * 1000);
    }

    // now 업데이트
    this.times.now.date = new Date();

    // filter and sort
    this.sortedTimes = Object.values(this.times)
      .filter(timestamp => timestamp.date)
      .sort((t1, t2) => {
        return t1.date < t2.date ? -1 :
          t1.date > t2.date ? 1 : 0;
      });

    const now = this.times.now.date;
    const createdAt = this.times.created_at.date;

    // 상대시간 업데이트
    for (const timestamp of this.sortedTimes) {
      const t2 = timestamp.date;
      {
        const t1 = createdAt;
        const { m, sStr } = t1 <= t2 ? diffTime(t1, t2) : diffTime(t2, t1);
        timestamp.relativeToCreatedAt = t1 <= t2 ? `${m}:${sStr}` : `-${m}:${sStr}`;
      }
      {
        const t1 = now;
        const { m, sStr } = t1 <= t2 ? diffTime(t1, t2) : diffTime(t2, t1);
        timestamp.relativeToNow = t1 <= t2 ? `${m}:${sStr}` : `-${m}:${sStr}`;
      }
    }
  }

  onSubmit() {
    if (this.dialogRef) {
      this.dialogRef.close(false);
      this.dialogRef = undefined;
    }
  }

  async cancelDelivery() {
    const delivery = this.delivery;

    const posOrderCode = delivery.orderAddRequest?.posOrderCode ?? delivery.orderStatus?.posOrderCode ?? delivery.latestCallback?.posOrderCode;
    const posStoreCode = delivery.orderAddRequest?.posStoreCode ?? delivery.orderStatus?.posStoreCode ?? delivery.latestCallback?.posStoreCode;
    if (posOrderCode === undefined) {
      this.logService.logOrder({ _id: this.delivery.relatedOrder.orderId, site: this.delivery.site, room: this.delivery.room }, `No posStoreCode, posOrderCode`, 'error');
      this.logService.withToastrError('No posStoreCode, posOrderCode');
      return;
    }

    this.spinnerDialogRef = this.dialogSpinnerService.openSpinnerDialog('취소 요청 보내는 중');

    try {
      // deliveryVendor는 초기에 없으므로 이 경우에는 'spidor'
      // 2020-03-01 부터는 생성되므로 값을 따른다.
      if (delivery.deliveryVendor === undefined) {
        this.logService.logOrder({ _id: this.delivery.relatedOrder.orderId, site: this.delivery.site, room: this.delivery.room }, '컴바인넷 취소를 하는데 deliveryVendor가 없어서 run2u로 가정합니다.', 'warn');
        delivery.deliveryVendor = 'run2u';
      }
      const endpoint = (delivery.deliveryVendor === 'run2u') ? 'dscall' : 'spidor';

      this.logService.info(`${posStoreCode}:${posOrderCode} 사용자가 배차 취소 요청`);
      this.logService.logOrder({ _id: this.delivery.relatedOrder.orderId, site: this.delivery.site, room: this.delivery.room }, `컴바인넷 '배송 취소' 버튼을 눌러 배차 취소 요청\n${posStoreCode}:${posOrderCode}`);

      const callInput: CallInputCombinenetApi<'/order/cancel'> = {
        endpoint,
        path: '/order/cancel',
        requestBody: {
          posStoreCode,
          posOrderCode
        }
      };

      const callable = this.fns.httpsCallable<CallInputCombinenetApi<'/order/cancel'>, CallOutputCombinenetApi<'/order/cancel'>>('callCombinenetApi');
      const orderCancelResponse = await callable(callInput).toPromise();

      if (this.spinnerDialogRef) {
        this.spinnerDialogRef.close(true);
        this.spinnerDialogRef = undefined;
      }

      const header = orderCancelResponse.header;
      if (header === undefined) {
        Swal.fire(`order/cancel 응답 헤더가 없습니다.`);
        this.logService.logOrder({ _id: this.delivery.relatedOrder.orderId, site: this.delivery.site, room: this.delivery.room }, `order/cancel 응답 헤더가 없습니다.`, 'error');
        console.error(JSON.stringify(orderCancelResponse, null, 2));
        return;
      } else if (header.result_code !== '0000') {
        this.logService.logOrder({ _id: this.delivery.relatedOrder.orderId, site: this.delivery.site, room: this.delivery.room }, `order/cancel 오류\n[${header.result_code}] ${header.result_message}`, 'error');
        Swal.fire(`order/cancel 에러: [${header.result_code}] ${header.result_message}`);
        return;
      }

    } catch (err) {
      this.logService.logOrder({ _id: this.delivery.relatedOrder.orderId, site: this.delivery.site, room: this.delivery.room }, `order/cancel 오류\n${JSON.stringify(err)}`, 'error');
      Swal.fire(`order/cancel 에러: ${err}`);
      return;
    }

    // 성공인 경우
    if (this.dialogRef) {
      this.dialogRef.close(true);
      this.dialogRef = undefined;
    }
  }

  /**
   * 수동으로 상태 경신을 한다.
   */
  async refreshOrderStatus() {
    const delivery = this.delivery;

    const posOrderCode = delivery.orderAddRequest?.posOrderCode ?? delivery.orderStatus?.posOrderCode ?? delivery.latestCallback?.posOrderCode;
    const posStoreCode = delivery.orderAddRequest?.posStoreCode ?? delivery.orderStatus?.posStoreCode ?? delivery.latestCallback?.posStoreCode;
    if (posOrderCode === undefined) {
      this.logService.withToastrError('No posStoreCode, posOrderCode');
      return;
    }

    this.spinnerDialogRef = this.dialogSpinnerService.openSpinnerDialog('최신 상태 조회 중');

    // 1. POS order/status
    // deliveryVendor는 초기에 없으므로 이 경우에는 'spidor'
    // 2020-03-01 부터는 생성되므로 값을 따른다.
    if (delivery.deliveryVendor === undefined) {
      this.logService.logOrder({ _id: this.delivery.relatedOrder.orderId, site: this.delivery.site, room: this.delivery.room }, '컴바인넷 상태 갱신을 하는데 deliveryVendor가 없어서 run2u로 가정합니다.', 'warn');
      delivery.deliveryVendor = 'run2u';
    }
    const endpoint = delivery.deliveryVendor === 'run2u' ? 'dscall' : 'spidor';

    const callInput: CallInputCombinenetApi<'/order/status'> = {
      endpoint,
      path: '/order/status',
      requestBody: {
        posStoreCode,
        posOrderCode
      }
    };

    const callable = this.fns.httpsCallable<CallInputCombinenetApi<'/order/status'>, CallOutputCombinenetApi<'/order/status'>>('callCombinenetApi');
    const orderStatusResponse = await callable(callInput).toPromise();

    if (this.spinnerDialogRef) {
      this.spinnerDialogRef.close(true);
      this.spinnerDialogRef = undefined;
    }

    const header = orderStatusResponse.header;
    if (header === undefined) {
      this.logService.withToastrError(`order/status 응답 헤더가 없습니다.`);
      // console.error(JSON.stringify(orderStatusResponse, null, 2));
      return;
    } else if (header.result_code !== '0000') {
      this.logService.withToastrError(`order/status 에러: [${header.result_code}] ${header.result_message}`);
      return;
    }

    const partialDoc: Partial<CombinenetDeliveryDoc> = {
      orderStatus: orderStatusResponse.body
    };
    // 2. firestore coombinenetDelivery 업데이트
    try {
      await this.firebaseManager.updateDoc('combinenetDelivery', posOrderCode, partialDoc);
      this.logService.logOrder({ _id: this.delivery.relatedOrder.orderId, site: this.delivery.site, room: this.delivery.room }, '컴바인넷 상태 갱신 성공');
      this.utilService.toastrInfo(posOrderCode, '배차 상태 갱신', 3000);
    } catch (error) {
      this.logService.logOrder({ _id: this.delivery.relatedOrder.orderId, site: this.delivery.site, room: this.delivery.room }, `컴바인넷 상태 갱신 오류\n${JSON.stringify(error)}`, 'error');
      this.logService.withToastrCatch(error, 'combinenetDelivery orderStatus 업데이트 중 예외 발생');
    }
  }

  openDestMap() {
    const delivery = this.delivery;
    // tslint:disable-next-line: max-line-length
    const name = encodeURI(`[${delivery.orderAddResponse?.distance}m] ${delivery.orderAddRequest?.address} (${delivery.orderAddRequest?.address_road})${delivery.orderStatus?.riderName ? ' [' + delivery.orderStatus?.riderName + ']' : ''}`)
      .replace(/,/g, ''); // COMMA 제거
    const url = 'http://map.daum.net/link/map/' + name + `,${delivery.orderAddRequest?.addressGpsLat},${delivery.orderAddRequest?.addressGpsLng}`;

    // refer : http://apis.map.daum.net/web/guide/#bigmapurl
    // http://map.daum.net/?urlX=506255.0&urlY=1113450.0&name=%EB%85%BC%ED%98%84%EB%8F%99%2B170-14
    window.open(
      url,
      delivery.orderAddRequest?.address, // 번지가 다르면 새 창에서 열리도록 하기 위함
      'centerscreen,width=1600,height=1080,alwaysRaised,noreferrer'
    );
  }

  /**
   * 배차는 성공했지만 DB가 완성이 되지 않아서 나중에 callback에 의해서 추가된 경우에
   * 나머지 데이터를 복구할 때 사용한다.
   * 완벽한 데이터 복구를 위해서는 컴바인넷 DB와 비교를 해야 한다.
   */
  async recover() {
    const delivery = this.delivery;

    const posOrderCode = delivery.orderAddRequest?.posOrderCode ?? delivery.orderStatus?.posOrderCode ?? delivery.latestCallback?.posOrderCode;
    const posStoreCode = delivery.orderAddRequest?.posStoreCode ?? delivery.orderStatus?.posStoreCode ?? delivery.latestCallback?.posStoreCode;
    if (posOrderCode === undefined || posStoreCode === undefined) {
      this.logService.withToastrError(`recover ${delivery._id}하려고 했으나 posOrderCode, posStoreCode를 모른다.`);
      return;
    }

    if (delivery._timeCreate && delivery.instanceNo && delivery.deliveryVendor && delivery.room && delivery.relatedOrder && delivery.orderAddRequest && delivery.orderAddResponse) {
      Swal.fire('복구', '복구할 것이 없네요.');
      return;
    }

    const callInput: CallInputRecoverDelivery = {
      deliveryVendor: delivery.deliveryVendor ?? 'combinenet',
      deliveryNo: posOrderCode,
      storeNo: posStoreCode,
    };

    this.spinnerDialogRef = this.dialogSpinnerService.openSpinnerDialog('데이터 복구 중');

    const callable = this.fns.httpsCallable<CallInputRecoverDelivery, CallOutputRecoverDelivery>('recoverDelivery');
    const callOutput = await callable(callInput).toPromise();

    try {
      if (callOutput.result === 'success') {
        Swal.fire('복구 성공', '조회 화면은 자동으로 업데이트되지 않으니 페이지 다시 불러서 확인하세요.');
      } else {
        Swal.fire('복구 실패', callOutput.reason);
      }
    } catch (error) {
      this.logService.withToastrCatch(error, 'combinenetDelivery orderStatus 업데이트 중 예외 발생');
    }

    this.spinnerDialogRef?.close(true);
    this.spinnerDialogRef = undefined;
  }

  openJsonEditor() {
    this.dialogJsonEditorService.openDialog('combinenetDelivery', this.delivery._id);
  }
}
