/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentTelephone {
  CURRENT_TELEPHONE = 'current_telephone';
  currentTelephone: string;
  telephoneSubject = new BehaviorSubject<string>('X');

  constructor(
  ) {
    const storageTelephone = this.getCurrentTelephoneFromLocalStorage();
    if (storageTelephone !== null) {
      this.currentTelephone = storageTelephone;
      this.telephoneSubject.next(this.currentTelephone);
    } else {
      this.currentTelephone = 'X';
      this.setCurrentTelephoneToLocalStorage(this.currentTelephone);
    }

    // 로컬스토리지의 변화를 감지하여 화면끼리 선택된 전화기를 동기화한다.
    // 스토리지를 변화 시킨 창에서는 이벤트가 발생하지 않느다.
    addEventListener('storage', event => {
      if (event.key === this.CURRENT_TELEPHONE) {
        const value = localStorage.getItem(this.CURRENT_TELEPHONE);
        this.telephoneSubject.next(value);
        this.currentTelephone = value;
      }
    });
  }

  setCurrentTelephone(telephone: string) {
    this.currentTelephone = telephone;
    this.setCurrentTelephoneToLocalStorage(telephone);
    this.telephoneSubject.next(telephone);
  }

  setCurrentTelephoneToLocalStorage(user: string) {
    localStorage.setItem(this.CURRENT_TELEPHONE, user);
  }

  getCurrentTelephoneFromLocalStorage() {
    return localStorage.getItem(this.CURRENT_TELEPHONE);
  }
}
