/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

import { CombinenetStateCode } from '../../../schema/1/schema-combinenet-api';
import { UnifiedDeliveryDoc, UnifiedDeliveryStatusCode } from '../../../schema/3/schema';

import { toDate } from '../../../core/2/util';

@Component({
  selector: 'app-unified-delivery-timer-renderer',
  templateUrl: './unified-delivery-timer-renderer.component.html',
  styleUrls: ['./unified-delivery-timer-renderer.component.scss']
})
export class UnifiedDeliveryTimerRendererComponent implements ICellRendererAngularComp {
  public params: any;

  public isPickupAdjusted = false;
  public pickupTime: string;
  public ignore = true; // 보여줄 필요가 없는 상태

  constructor(
  ) { }

  agInit(params: ICellRendererParams): void {
    this.params = params;

    this.updatePickupTime();
  }

  // refer: https://www.ag-grid.com/javascript-grid-cell-rendering-components/#grid-vs-component-refresh
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.updatePickupTime();
    // true: component를 파괴하지 말아라. 내가 알아서 다시 그리겠다.
    return true;
  }

  updatePickupTime() {
    // 주의: agInit()에서 order를 저장한 후에 사용하면 최신 order를 추적하지 않는다.
    const doc: UnifiedDeliveryDoc = this.params.node.data;

    if (doc === undefined) {
      this.ignore = true;
      return undefined;
    }

    // 아직 orderStatus가 없으므로 SUBMITTED로 분류한다.
    const stateCode = doc.deliveryStatusCode ?? CombinenetStateCode.SUBMITTED;

    if (stateCode !== UnifiedDeliveryStatusCode.ASSIGNED && stateCode !== UnifiedDeliveryStatusCode.SUBMITTED) {
      // console.log(`보여줄 것이 없는 상태: ${stateCode}`);
      this.ignore = true;
      return undefined;
    }
    this.ignore = false;

    // 픽업 조정이 있다면 가장 최근의 픽업 조정 시각을 기준으로 하고
    // 그렇지 않다면 최초 요청 픽업 시각을 기준으로 한다.
    // 바로고의 경우에는 barogoPickupExpectedAt을 기준으로 한다.
    if (doc?.barogoPickupExpectedAt) {
      this.pickupTime = doc.barogoPickupExpectedAt;
    } else if (doc?.adjustedPickupTimes?.length > 0) {
      // this.isPickupAdjusted = true;
      const adjustedPickupTimeLength = doc.adjustedPickupTimes.length;
      this.pickupTime = doc.adjustedPickupTimes[adjustedPickupTimeLength - 1].adjustedTime;
    } else if (doc.requestedPickupTime) {
      const pickupUnixTime = toDate(doc.requestedPickupTime).getTime();

      // HTML에서 문자열의 길이로 어설픈 조건을 사용하고 있다. 그래서 string으로 변환한다.
      this.pickupTime = String(pickupUnixTime);
      // }
    } else {
      // 에러를 유발할 수 있는 상황이므로 표시하지 않는다.
      this.ignore = true;
    }

    // barogoPickupExpectedAt이 우선순위가 높아도 픽업 조정 여부는 별도로 표시해야 한다.
    this.isPickupAdjusted = (doc?.adjustedPickupTimes?.length > 0);

    return undefined;
  }
}
