/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { CurrentTelephone } from '../../core/1/current-telephone';
import { Telephone, TelephoneService } from '../../core/2/telephone.service';
import { normalizeTel } from '../../core/2/util';

@Component({
  selector: 'app-telephone-selector',
  templateUrl: './telephone-selector.component.html',
  styleUrls: ['./telephone-selector.component.scss']
})
export class TelephoneSelectorComponent implements OnInit, OnDestroy {
  user: string;
  storedTelephonen: string;

  public telephoneList: Telephone[] = [];

  telephoneSubscription: Subscription;

  constructor(
    private localStorageService: CurrentTelephone,
    private telephoneService: TelephoneService,
  ) { }

  ngOnInit() {
    this.telephoneList = this.telephoneService.getTelephoneList().map(tel => ({
      user: tel.user,
      number: normalizeTel(tel.number),
    }));
    this.telephoneSubscription = this.localStorageService.telephoneSubject.subscribe(telephone => {
      this.user = telephone;
    });
  }

  ngOnDestroy() {
    this.telephoneSubscription?.unsubscribe();
  }

  changedPhoneValue(value: string) {
    this.user = value;
    this.localStorageService.setCurrentTelephone(value);
  }
}
