/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
// tslint:disable: deprecation
import Swal from 'sweetalert2';
import cloneDeep from 'lodash-es/cloneDeep';  // refer: https://medium.com/@armno/til-importing-lodash-into-angular-the-better-way-aacbeaa40473
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';

import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/functions';

import { DeliveryVendor } from '../../schema/1/schema-common';
import { UnifiedAccountDoc } from '../../schema/2/schema-account';
import {
  UnifiedOrderStatusCode,
  UnifiedOrderContextStatusCode,
  UnifiedOrder,
  UnifiedOrderDoc,
  UnifiedDeliveryDoc,
} from '../../schema/3/schema';
import {
  CallInputRequestDelivery,
  CallOutputRequestDelivery,
  CallInputRequestDeliveryInformation,
  CallOutputRequestDeliveryInformation
} from '../../schema/4/schema-functions-call';

import { dateFormatter } from '../../core/1/ag-util';
import { ClipboardService } from '../../core/1/clipboard.service';
import { UtilService } from '../../core/1/util.service';
import { NotificationCenterService } from '../../core/1/notification-center.service';
import { registeredDeliveryVendors, deliveryVendorMappings, deliveryStatusCodeMappings } from '../../core/1/string-map';
import { autoPilotResultFor } from '../../core/1/string-map';
import { normalizeTel, trimOrganization, sleep } from '../../core/2/util';
import { LogService } from '../../core/3/log.service';
import { AccountService } from '../../core/3/account.service';
import { FirebaseManagerService } from '../../core/4/firebase-manager.service';
import { RoomService } from '../../core/4/room.service';
import { MessageService } from '../../core/5/message.service';
import { DeliveryUtilService, DeliveryVendorParams } from '../../core/5/delivery-util.service';

import { AddressFormComponent } from '../address-form/address-form.component';
import { DialogSpinnerService } from '../dialog-spinner/dialog-spinner.service';
import { DialogSpinnerComponent } from '../dialog-spinner/dialog-spinner.component';

// UI 용
interface UIOnly {
  _ui?: {
    relatedDeliveries?: UnifiedDeliveryDoc[];
  };
}

/**
 * 2020-06-02
 * 디폴트 배달 시간 계산식
 *
 * order.cookMinutes (조리시간) : 업소에서 선택한 시간
 * site.deliveryParams.minPickupSeconds (최소픽업시간) : 지점 설정
 * site.deliveryParams.secondsPerKm (Km당 소요시간) : 지점 설정
 * siet.deliveryParams.baseDeliverySeconds (기본배달시간) : 지점 설정
 * 배달거리 : 동적으로 계산
 *
 * 픽업요청시간 = Max(order.cookMinutes, minPickupSeconds)
 * 예상배달시간 = 픽업요청시간 + 기본배달시간 + minPickupSeconds * 배달거리
 */

@Component({
  selector: 'app-dialog-single',
  templateUrl: './dialog-single.component.html',
  styleUrls: ['./dialog-single.component.scss']
})
export class DialogSingleComponent implements OnInit, OnDestroy {
  trimOrganization = trimOrganization;

  public deliveryVendors: DeliveryVendor[] = [];
  public deliveryVendorMappings = deliveryVendorMappings;

  public order: UnifiedOrderDoc & UIOnly;
  public requestDeliveryForm: FormGroup;
  public isRequestButtonPressed = false;
  public isAcceptPressed = false;
  public guardTimeMargin = true;
  public prefixMsg = '';
  public relatedDeliveriesMsg = '';
  public cautiousOrderMsg = false; // 예약으로 의심되는 요청 사항 감지시 true
  public autoPilot: string;

  private instanceNos: { [key in DeliveryVendor]?: string; } = {};

  // 부릉을 이용할 수 있다.
  private vroongInstanceNo: string;
  public vroongAccount: UnifiedAccountDoc<'vroong'>;  // ID/PW 표시에 사용

  public selectedDeliveryVendor: DeliveryVendor;
  public selectedPickupMinute: number;

  public deliveryParams: DeliveryVendorParams;

  public deliveryInfo: (CallOutputRequestDeliveryInformation & {
    deliveryVendor: string;
    instanceNo: string;
  });

  // 벤더별 배송가능여부
  public isDeliveryPossible = true;

  private spinnerDialogRef: MatDialogRef<DialogSpinnerComponent, any>;

  private destroySignal = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { order: UnifiedOrderDoc },
    public dialogRef: MatDialogRef<DialogSingleComponent, boolean>,
    public roomService: RoomService,
    private fns: AngularFireFunctions,
    private fb: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService,
    private logService: LogService,
    private accountService: AccountService,
    private clipboardService: ClipboardService,
    private notificationCenterService: NotificationCenterService,
    private dialogSpinnerService: DialogSpinnerService,
    private deliveryUtilService: DeliveryUtilService,
    private firebaseManager: FirebaseManagerService,
  ) {
    this.order = cloneDeep(data.order);

    // 관련 배차 존재 정보를 화면 상단에 표시할 때 사용하는 relatedDeliveriesMsg를 생성한다.
    if (this.order?._ui?.relatedDeliveries?.length > 0) {
      const dates = this.order._ui.relatedDeliveries.map(delivery => {
        const status = deliveryStatusCodeMappings[delivery.deliveryStatusCode];
        const date = dateFormatter(delivery.timeSubmitted, 'dd ccc HH:mm:ss');
        return `요청: ${date} (현재: ${status})`;
      }).join(' | ');

      const deliveryVendors = this.order._ui.relatedDeliveries.map(delivery => deliveryVendorMappings[delivery.deliveryVendor]);
      const uniqueVendors = `${Array.from(new Set(deliveryVendors)).join('|')}`;

      this.relatedDeliveriesMsg = `이미 관련 ${uniqueVendors} 배차 ${this.order._ui.relatedDeliveries.length}건 [ ${dates} ]이 존재합니다.`;
    }

    // 서로 다른 곳에서 동시에 배차를 넣는 경우를 방지하기 위한 장치
    // 추가된 배차가 감지되고 30초가 지나지 않았다면 배차 버튼을 비활성화한다.
    // 매우 짧은 시간에 동시에 할 경우에는 여전히 중복 배차가 가능하다.
    this.firebaseManager.observe<UnifiedDeliveryDoc>('unifiedDelivery', [['relatedOrderId', '==', this.order._id]], { sortKey: 'timeSubmitted', orderBy: 'desc' })
      .pipe(takeUntil(this.destroySignal)).subscribe(docs => {
        // 과적건을 선택한 경우에는 중복 배차 방지를 하지 않는다.
        if (this.requestDeliveryForm.get('isOverWeight').value || this.requestDeliveryForm.get('isUrgent').value) {
          this.guardTimeMargin = false;
          return;
        }

        this.guardTimeMargin = false;
        if (docs.length > 0 && docs[0].timeSubmitted) {
          const latestCombinenetDelivery = docs[0];
          const latestSubmittedDate = new Date(latestCombinenetDelivery.timeSubmitted);

          // 최근 배차 시각이 아직 30초가 지나지 않았다.
          if (Date.now() - latestSubmittedDate.getTime() < 30 * 1000) {
            this.guardTimeMargin = true;
          }
        }
      });

    // 예약 주문, 추가 주문 요청은 바로 배차를 신청하지 말아야 하기 때문에 예약 요청으로 의심되는 요청을 강조표시한다.
    {
      // ex) 12시까지 ...
      const matches = this.order.orderMsg?.match(/(\d+시|\d{1,2}:\d{2}|오전|오후)|추가주문/);
      if (matches) {
        this.cautiousOrderMsg = true;
      }
    }

    this.autoPilot = `${autoPilotResultFor(this.order)} ${this.order.autoPilotReason ?? ''}`;
  }

  ngOnInit() {
    const order = this.order;

    this.deliveryParams = this.deliveryUtilService.calculateDeliveryVendorParams(order, this.selectedDeliveryVendor);
    this.selectedPickupMinute = this.deliveryParams.pickupMinutes;

    // 배송 벤더와는 의존 관계가 없다.
    this.recalculateDelivery();

    const [{ orderAmount = 0, deliveryTip = 0, discount = 0, eventDiscount = 0 } = {}] = [order];

    // 호실 설정 순서에 따른 우선 순위를 갖는다.
    const roomDoc = this.roomService.rooms[order.room];
    if (roomDoc) {
      roomDoc.deliveryVendors.forEach(deliveryVendor => {
        // room에 등록한 deliveryVendor를 기준으로 instanceNo를 가져온다.
        this.instanceNos[deliveryVendor as string] = this.roomService.rooms[this.order.room].account?.[deliveryVendor];

        if (deliveryVendor === 'ghokirun') {
          this.instanceNos.ghokirun = 'N/A';
        }

        if (this.instanceNos[deliveryVendor]) {
          this.deliveryVendors.push(deliveryVendor);
        } else if (Object.keys(deliveryVendorMappings).includes(deliveryVendor)) {
          this.logService.logOrder(order, `호실 설정에만 ${deliveryVendorMappings[deliveryVendor]}가 있습니다.`, 'error');
          this.logService.withToastrError(`호실 설정에만  ${deliveryVendorMappings[deliveryVendor]}가 있습니다. account 설정에도 있어야 합니다.`);
        } else {
          this.logService.logOrder(order, `예기치 않은 배달 대행사 ${deliveryVendor}`, 'error');
          this.logService.withToastrError(`알 수 없는 배달 대행사: ${deliveryVendor}`);
        }
      });
    } else {
      this.logService.logOrder(order, `${order.room}에 대한 호실 설정이 없습니다.`, 'error');
      this.logService.withToastrError(`${order.room}에 대한 호실 설정이 없습니다.`);
    }

    if (this.deliveryVendors?.length > 0) {
      this.selectedDeliveryVendor = this.deliveryVendors[0];
      this.updateDeliveryInfo(this.selectedDeliveryVendor);
    } else {
      this.logService.logOrder(order, `${order.room}에 속한 배달 대행이 없습니다.`, 'error');
      this.logService.withToastrError(`${order.room}에 속한 배달 대행이 없습니다.`);
    }

    this.requestDeliveryForm = this.fb.group({
      userTel: [normalizeTel(order.userTel), this.userTelValidator()],
      payment_method: order.paymentMethod === '선불' ? 'PREPAID' : order.paymentMethod === '후불카드' ? 'CREDIT_CARD' : 'CASH',
      delivery_value: orderAmount + deliveryTip - eventDiscount - discount,
      deliveryVendor: this.selectedDeliveryVendor,
      pickupOffset: this.order.posRequestedPickupMinutes ?? this.deliveryParams.pickupMinutes,
      deliveryMinutes: this.order.posDeliveryMinutes ?? this.deliveryParams.deliveryMinutes,
      isUrgent: false,
      isOverWeight: false,
      isNoodle: false,
      prefixMsg: '',
      customMsg: { value: '', disabled: false },
      orderNotes: order.orderMsg
    });

    this.observeChanges();
    this.observeUserTel();

    // 요기요는 접수 후에 전화번호를 알 수 있다.
    this.notificationCenterService.yogiyoUserTel.pipe(
      filter(yogiyoTel => yogiyoTel.orderId === order._id),
      take(1),
      takeUntil(this.destroySignal)
    ).subscribe(yogiyoUserTel => {
      console.log(`요기요 userTel ${yogiyoUserTel.userTel} 확득!`);
      this.requestDeliveryForm.get('userTel').setValue(normalizeTel(yogiyoUserTel.userTel));
      this.order.userTel = yogiyoUserTel.userTel.replace(/-/g, '');
    },
      err => console.error(err),
      () => console.log('subscription completed'));
  }

  ngOnDestroy() {
    if (this.spinnerDialogRef) {
      this.spinnerDialogRef.close();
    }

    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  onCancel() {
    if (this.dialogRef) {
      this.dialogRef.close(false);
      this.dialogRef = undefined;
    }
  }

  /**
   * vroongPickups와 defaultPickupTime을 설정한다.
   * this.instanceNo가 먼저 설정되어 있어야 한다. (updateInstanceNo())
   */
  private updateVroong() {
    // Step 1. guard
    if (this.vroongInstanceNo === undefined) {
      return;
    }

    // Step 2. do
    // 주소가 바뀐 경우에 instanceNo가 변경될 수 있다.
    const vroongInstanceNo = this.deliveryUtilService.findVroongInstanceNo(this.order);

    const vroongAccount = this.accountService.unifiedAccount[`vroong-${vroongInstanceNo}`];
    if (vroongAccount === undefined) {
      this.logService.logOrder(this.order, '부릉 계정 정보를 찾을 수 없습니다.', 'error');
      this.logService.withToastrError('부릉 계정 정보를 찾을 수 없습니다. 리로드해 보시고 해결이 되지 않으면 개발자를 혼내주세요.');
    }
    // Step 3. side effect
    this.vroongInstanceNo = vroongInstanceNo;
    this.vroongAccount = vroongAccount;
  }

  private async updateDeliveryInfo(deliveryVendor: DeliveryVendor) {
    const order = this.order;
    const instanceNo = this.instanceNos[deliveryVendor];

    // 일단 업데이트 되기 전에는 초기화한다.
    // this.deliveryInfo = undefined;
    this.isDeliveryPossible = true;

    try {
      const callInput: CallInputRequestDeliveryInformation = {
        organization: order.organization,
        site: order.site,
        room: order.room,
        instanceNo,
        deliveryVendor,
        address_key: order.address_key,
        address_road: order.address_road,
        address_detail: order.address_detail,
        address_location: order.address_location,
        address_jibun: order.address_jibun,
        address_sido: order.address_sido,
        address_sigungu: order.address_sigungu,
        address_dong: order.address_dong,
        address_dongH: order.address_dongH,
        initialPaymentAmount: 0,
      };

      const callable = this.fns.httpsCallable<CallInputRequestDeliveryInformation, CallOutputRequestDeliveryInformation>('callRequestDeliveryInformation');
      const deliveryInfoResponse = await callable(callInput).toPromise();

      this.isDeliveryPossible = deliveryInfoResponse.result === 'success';

      if (deliveryInfoResponse.result !== 'error') {
        if (deliveryInfoResponse.result === 'success') {
          if (this.deliveryParams.pickups.toString() !== deliveryInfoResponse.deliveryInfo.pickups.toString()) {
            this.deliveryParams.pickups = deliveryInfoResponse.deliveryInfo.pickups;
            this.selectRequestPickupMinutes(deliveryInfoResponse.deliveryInfo.pickups);
          }
        }
        this.deliveryInfo = {
          deliveryVendor,
          instanceNo,
          ...deliveryInfoResponse
        };
      } else {
        this.isDeliveryPossible = false;
        this.logService.logOrder(this.order, `${deliveryVendor} 배차 전 정보 요청에 실패하였습니다. ${deliveryInfoResponse.reason}`, 'error');
        this.logService.withToastrError(`${deliveryVendor} 배차 전 정보 요청에 실패하였습니다. ${deliveryInfoResponse.reason}`);
        return;
      }
    } catch (error) {
      this.isDeliveryPossible = false;
      this.logService.logOrder(this.order, `dialog-single의 updateDeliveryInfo 예외가 발생했습니다.\n${JSON.stringify(error)}`, 'error');
      this.logService.withToastrCatch(error, 'dialog-single의 updateDeliveryInfo 예외 발생');
    }
  }

  observeChanges() {
    let formControl = this.requestDeliveryForm.get('customMsg');
    formControl.valueChanges.pipe(takeUntil(this.destroySignal)).subscribe(value => {
      // console.log(`customMsg changed : ${this.requestDeliveryForm.get('customMsg').value}`);
      this.updatePrefixMsg();
    });

    formControl = this.requestDeliveryForm.get('isUrgent');
    formControl.valueChanges.pipe(takeUntil(this.destroySignal)).subscribe(value => {
      if (value) {
        // console.log('중복 방지 해제');
        this.guardTimeMargin = false;
      }
      this.updatePrefixMsg();
    });
    formControl = this.requestDeliveryForm.get('isOverWeight');
    formControl.valueChanges.pipe(takeUntil(this.destroySignal)).subscribe(value => {
      if (value) {
        // console.log('중복 방지 해제');
        this.guardTimeMargin = false;
      }

      this.updatePrefixMsg();
    });
    formControl = this.requestDeliveryForm.get('isNoodle');
    formControl.valueChanges.pipe(takeUntil(this.destroySignal)).subscribe(value => {
      // console.log(`isNoodle changed : ${this.requestDeliveryForm.get('isNoodle').value}`);
      this.updatePrefixMsg();
    });

    formControl = this.requestDeliveryForm.get('pickupOffset');
    formControl.valueChanges.pipe(takeUntil(this.destroySignal)).subscribe(value => {
      console.log(`pickupOffset: ${value}`);
      this.selectedPickupMinute = value;
    });

    formControl = this.requestDeliveryForm.get('deliveryVendor');
    formControl.valueChanges.pipe(takeUntil(this.destroySignal)).subscribe(value => {
      console.log(`deliveryVendor ${value}`);
      this.selectedDeliveryVendor = value;
      this.updateDeliveryInfo(value);
    });
  }

  /**
   * userTel에 변화가 있으면 포맷을 자동 적용한다.
   */
  observeUserTel() {
    /**
     * (manual-order.componet.ts에서 가져오다)
     * 입력 과정 중에 자동으로 -를 붙여준다.
     * util.ts에 있는 noramlizeTel과는 쓰임이 다르다.
     */
    function progressNormalizingTel(telNo: string) {
      // 숫자 이외에는 모두 제외한다.
      telNo = telNo.replace(/[^0-9]/g, '');

      // console.log(`1. telNo = ${telNo}`);

      if (telNo[0] !== '0') {
        return '';
      }
      // 2번째 숫자가 허용되지 않는 숫자라면 거부
      if (telNo[1] !== '1' && telNo[1] !== '2' && telNo[1] !== '5' && telNo[1] !== '7') {
        return telNo[0];
      }
      // 010, 050, 070 이 아니고 051같은 경우는 거부
      // if ((telNo[1] === '1' || telNo[1] === '5' || telNo[1] === '7') && telNo[2] !== '0' ) {
      //   return `${telNo[0]}${telNo[1]}`;
      // }

      if (telNo.match(/^010|011|050|070/)) {
        // 국번이 0이나 1로 시작하지 않는다.
        if (telNo[3] === '0' || telNo[3] === '1') {
          return telNo.substr(0, 3);
        }

        if (telNo.length === 12) {
          return `${telNo.substr(0, 4)}-${telNo.substr(4, 4)}-${telNo.substr(8, 4)}`;
        } else if (telNo.length > 7) {
          return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}-${telNo.substr(7, 4)}`;
        } else if (telNo.length > 3) {
          return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}`;
        } else {
          return telNo;
        }
      } else { // 02
        // 국번이 0이나 1로 시작하지 않는다.
        if (telNo[2] === '0' || telNo[2] === '1') {
          return telNo.substr(0, 2);
        }

        if (telNo.length > 9) {
          return `${telNo.substr(0, 2)}-${telNo.substr(2, 4)}-${telNo.substr(6, 4)}`;
        } else if (telNo.length > 5) {
          return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}-${telNo.substr(5, 4)}`;
        } else if (telNo.length > 2) {
          return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}`;
        } else {
          return telNo;
        }
      }
    }

    const formControl = this.requestDeliveryForm.get('userTel');

    formControl.valueChanges
      .pipe(takeUntil(this.destroySignal)).forEach(value => {
        const normalizedTel = progressNormalizingTel(value);
        if (value !== normalizedTel) {
          this.requestDeliveryForm.get('userTel').setValue(normalizedTel);
          this.order.userTel = normalizedTel.replace(/-/g, '');
        }
      });
  }

  updatePrefixMsg() {
    // 주의 : form.value.isNoodle은 form.get('isNoodle').value보다 한 사이클 늦다.
    // console.log('-----------------------------------------');
    // console.log(`this.requestDeliveryForm.value.customMsg = ${this.requestDeliveryForm.value.customMsg}`);
    // console.log(`this.requestDeliveryForm.get('customMsg').value = ${this.requestDeliveryForm.get('customMsg').value}`);
    // console.log(`this.requestDeliveryForm.value.isNoodle = ${this.requestDeliveryForm.value.isNoodle}`);
    // console.log(`this.requestDeliveryForm.get('isNoodle').value = ${this.requestDeliveryForm.get('isNoodle').value}`);

    const customMsgValue = this.requestDeliveryForm.get('customMsg').value;
    const isUrgentValue = this.requestDeliveryForm.get('isUrgent').value;
    const isOverWeightValue = this.requestDeliveryForm.get('isOverWeight').value;
    const isNoodleValue = this.requestDeliveryForm.get('isNoodle').value;

    let prefixMsg = [isUrgentValue ? '긴급누락건' : '', isOverWeightValue ? '과적건' : '', isNoodleValue ? '파스타' : '', customMsgValue]
      .join('/')
      .replace(/[/]{1,}/g, '/')
      .replace(/^[/]/, '')
      .replace(/[/]$/, '');

    // 괄호로 감싼다.
    if (prefixMsg && prefixMsg[0] !== '(') {
      prefixMsg = `(${prefixMsg})`;
    }

    this.prefixMsg = prefixMsg;
    // console.log(`prefixMsg = ${this.prefixMsg}`);
  }

  /**
   * deliveryType의 값에 따라서 결과가 다른다.
   */
  userTelValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const userTel = control.value;

      if (userTel.length > 0) {
        const match = userTel.match(/^(0[157][01][1-9]?-[0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
        if (match === null) {
          return { reason: '전화번호가 형식에 맞지 않습니다.' };
        }
      } else {
        return { reason: '배달은 전화번호가 필요합니다.' };
      }

      return null;
    };
  }

  async onSubmit(addressForm: AddressFormComponent, withAccept = false) {
    const deliveryVendor: DeliveryVendor = this.requestDeliveryForm.get('deliveryVendor').value;

    if (!registeredDeliveryVendors.includes(deliveryVendor)) {
      this.logService.logOrder(this.order, `예기치 않은 deliveryVendor: ${deliveryVendor}`, 'error');
      this.logService.withToastrError(`이런... devliveryVendor(${deliveryVendor})가 이상합니다. 개발자에게 알려주세요.`);
      return;
    }
    if (addressForm.addressForm.dirty || this.order.address_key == null) {
      Swal.fire('주소 확인을 해 주세요.');
      return;
    }

    const order = this.order;

    // 수동 주문 입력의 경우에 전화번호가 없을 수 있다.
    if (withAccept && order.orderVendor === 'yogiyo') {
      // 요기요의 경우에는 접수를 해야만 전화번호를 알 수 있다.
    } else if (!order.userTel) {
      this.logService.logOrder(this.order, '고객 전화번호가 없으면 배차 요청을 할 수 없습니다.', 'warn');
      Swal.fire('고객 전화번호가 없으면 배차 요청을 할 수 없습니다.');
      return;
    }

    // 연속적으로 버튼이 눌리는 경우를 방지
    if (this.isRequestButtonPressed) {
      return;
    }
    this.isRequestButtonPressed = true;

    // 1. 주문 접수
    if (withAccept) {
      // 전화번호를 받을 때까지 잠시 대기
      if (order.orderVendor === 'yogiyo' && !order.userTel) {
        this.spinnerDialogRef = this.dialogSpinnerService.openSpinnerDialog('고객 전화번호 획득 중');

        await this.submitAccept();

        let userTelAcquired = false;
        // 2020-08-22 10초 넘게 걸리는 경우가 가끔 있어서 시간을 20초로 늘렸다.
        let maxTry = 100; // 200 * 100 = 20 sec

        // busy waiting으로 확인한다.
        while (maxTry > 0) {
          if (this.order.userTel) {
            userTelAcquired = true;
            break;
          } else {
            await sleep(200);
            maxTry--;
          }
        }

        this.spinnerDialogRef.close(true);
        this.spinnerDialogRef = undefined;

        if (!userTelAcquired) {
          Swal.fire('고객 전화번호 획득 실패. 배차를 진행하지 않습니다.');
          this.logService.logOrder(this.order, `요기요 고객 전화번호 획득 실패. 배차를 진행하지 않습니다.`, 'error');
          return;
        }
      } else {
        await this.submitAccept();
      }
    }

    // 2. 배차 요청
    if (deliveryVendor === 'vroong') {
      await this.submitVroong();
    } else if (['run2u', 'spidor', 'barogo', 'ghokirun', 'logiall', 'manna', 'zendeli', 'shero', 'dalgo', 'iudream', 'baedalyo', 'baedalhero'].includes(deliveryVendor)) {
      await this.submitDelivery(deliveryVendor);
    } else {
      this.logService.logOrder(this.order, `예기치 않은 deliveryVendor: ${deliveryVendor}`, 'error');
      this.logService.withToastrError(`Unexpected deliveryVendor ${deliveryVendor}`);
    }
  }

  /**
   * 접수 및 배차를 요청했을 때 접수를 담당한다.
   */
  public async submitAccept(closeDialog = false) {
    const order = this.order;
    const deliveryMinutes: number = this.requestDeliveryForm.get('deliveryMinutes').value;

    // 연속적으로 눌리는 것을 방지
    if (this.isAcceptPressed) {
      return;
    }
    this.isAcceptPressed = true;

    if (order.createdBy === 'manual' || order.orderVendor === 'ghostkitchen') {
      try {
        await this.firebaseManager.setDoc('unifiedOrder', this.order._id, {
          deliveryMinutes,
          orderStatusCode: UnifiedOrderStatusCode.ACCEPTED,
          contextStatusCode: UnifiedOrderContextStatusCode.ACCEPTED
        });
        this.logService.logOrder(this.order, `'접수 및 배차' 버튼을 눌러서 접수(ACCEPTED) 주문으로 상태를 변경하고, 예상 배달시간을 ${deliveryMinutes}분으로 변경했습니다.`);
      } catch (error) {
        this.logService.logOrder(this.order, `'접수 및 배차' 버튼을 눌러서 접수(ACCEPTED) 주문으로 상태를 변경하는 과정에서 예외가 발생했습니다.\n${JSON.stringify(error)}`, 'error');
        console.error(error);

        Swal.fire(error.message);
        return;
      }
    } else if (order.orderVendor === 'baemin' && order.orderChannel === 'app') {
      try {
        await this.messageService.requestAcceptBaeminOrder(
          this.order.instanceNo,
          this.order.orderNo,
          deliveryMinutes
        );
        this.logService.logOrder(this.order, `'접수 및 배차' 버튼을 눌러서 접수(ACCEPTED) 주문으로 상태를 변경했습니다.`);
      } catch (err) {
        this.logService.logOrder(this.order, `'접수 및 배차' 버튼을 눌러서 접수(ACCEPTED) 주문으로 상태를 변경하는 과정에서 예외가 발생했습니다.\n${JSON.stringify(err)}`, 'error');
        console.error(err);

        Swal.fire(err.message);
        return;
      }
    } else if (order.orderVendor === 'yogiyo' && order.orderChannel === 'app') {
      try {
        await this.messageService.requestAcceptYogiyoOrder(
          this.order.orderNo,
          String(deliveryMinutes)
        );
        this.logService.logOrder(this.order, `'접수 및 배차' 버튼을 눌러서 접수(ACCEPTED) 주문으로 상태를 변경했습니다.`);
      } catch (err) {
        this.logService.logOrder(this.order, `'접수 및 배차' 버튼을 눌러서 접수(ACCEPTED) 주문으로 상태를 변경하는 과정에서 예외가 발생했습니다.\n${JSON.stringify(err)}`, 'error');
        console.error(err);

        Swal.fire(err.message);
        return;
      }
    } else {
      this.logService.logOrder(this.order, `예기치 않은 경우가 발생했습니다.(${order.orderVendor}-${order.orderChannel})`, 'error');
      Swal.fire(`예기치 않은 경우입니다(${order.orderVendor}-${order.orderChannel}). 개발자에게 알려주세요.`);
      return;
    }

    if (closeDialog && this.dialogRef) {
      this.dialogRef.close(true);
      this.dialogRef = undefined;
    }
  }

  private async submitVroong() {
    if (this.vroongInstanceNo === undefined) {
      this.logService.withToastrError('부릉 계정을 찾을 수 없어서 지금은 부릉 배차가 불가합니다. 주소가 잘못된 경우일 수 있습니다.');
      this.logService.logOrder(this.order, `부릉 계정을 찾을 수 없어서 지금은 부릉 배차가 불가합니다. 주소가 잘못된 경우일 수 있습니다.`, 'error');
      return;
    }

    const order = this.order;
    const { orderNotes, userTel, payment_method, delivery_value } = this.requestDeliveryForm.value;

    const vroongPickupOffset = parseInt(this.requestDeliveryForm.value.vroongPickupOffset, 10);
    const vroongInstanceNo = this.vroongInstanceNo;
    const promise = this.messageService.requestCreateVroongDelivery(vroongInstanceNo, {
      request_id: uuidv4(),

      dest_sido: order.address_sido, // 40, 필수, '서울특별시', '경기도'
      dest_sigungu: order.vroong.dest_sigungu, // 40, 필수, '강남구', '성남시 수정구'
      dest_legal_eupmyeondong: order.vroong.dest_legal_eupmyeondong, // 40, 필수, '삼성동', '신흥동'
      dest_admin_eupmyeondong: order.vroong.dest_admin_eupmyeondong, // 40, 필수, '서강동', '면목동'
      dest_ri: order.vroong.dest_ri, // 40, 필수, '', '신기리'
      dest_beonji: order.vroong.dest_beonji, // 40, 필수, '100-12', '산550-3'
      dest_road: order.vroong.dest_road, // 80, 필수, '봉은사로', '시민로175번길'
      dest_building_number: order.vroong.dest_building_number, // 40, 필수, '', '120-1', '14', '지하56'

      dest_detail: `${this.prefixMsg}${order.address_detail}`, // 224, 필수, '현대아파트 101동 101'
      dest_lat: String(order.address_location.lat), // 255, 필수, '37.1234'
      dest_lng: String(order.address_location.lon), // 255, 필수, '128.1234'

      payment_method,
      order_creation_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), // 40, 필수, '2019-07-23 19:14:33'
      order_pickup_offset: vroongPickupOffset, // 필수, 30 // 분
      // tslint:disable-next-line: max-line-length
      delivery_value, // 필수, 10000 (받아야 되는 돈, PREPAID인 경우에는 0이라고 해도 된다.)
      recipient_phone: normalizeTel(userTel), // 20, 필수, '010-0000-0000'
      // recipient_name?: string; // 20, 옵션, '',
      order_notes: orderNotes, // 255, 옵션,
      // client_delivery_no?: string; // 60, 옵션,
      client_order_no: order._id, // 60, 옵션k
    });
    // TEST
    // const promise = new Promise(resolve => setTimeout(resolve, 1000));

    promise.then(() => {
      if (this.dialogRef) {
        this.dialogRef.close(true);
        this.dialogRef = undefined;
      }
    }, error => {
      if (error !== false) {
        Swal.fire(error.message);
      }
    });
  }

  async submitDelivery(deliveryVendor: DeliveryVendor) {
    const order = this.order;
    const { orderNotes, userTel, payment_method } = this.requestDeliveryForm.value;
    const deliveryValue = Number(this.requestDeliveryForm.value.delivery_value);
    const pickupOffset = this.requestDeliveryForm.get('pickupOffset').value;

    const delivery: CallInputRequestDelivery = {
      organization: order.organization,
      site: order.site,
      room: order.room,

      relatedOrderId: order._id,
      deliveryVendor,
      cookMinutes: pickupOffset,
      userTel: userTel.replace(/-/g, ''),

      address_key: order.address_key,
      address_road: order.address_road,
      address_detail: `${this.prefixMsg}${order.address_detail}`,
      address_location: order.address_location,
      address_sido: order.address_sido,
      address_sigungu: order.address_sigungu,
      address_dong: order.address_dong,
      address_dongH: order.address_dongH,
      address_jibun: order.address_jibun,
      address_building_name: order.address_building_name,
      vroong: order.vroong,

      initialPaymentMethod: payment_method === 'PREPAID' ? '선불' : payment_method === 'CASH' ? '후불현금' : '후불카드',
      initialPaymentAmount: deliveryValue,

      orderMsg: orderNotes,
    };

    const spinnerRef = this.dialogSpinnerService.openSpinnerDialog('배차 요청 중...');

    try {
      const callable = this.fns.httpsCallable<CallInputRequestDelivery, CallOutputRequestDelivery>('callRequestDelivery');
      const callOutput = await callable(delivery).toPromise();

      spinnerRef.close();

      if (callOutput.result === 'success') {
        if (this.dialogRef) {
          this.dialogRef.close(true);
          this.dialogRef = undefined;
        }
        this.logService.logOrder(this.order, `${deliveryVendorMappings[deliveryVendor]} 배차 요청했습니다.`);
      } else {
        this.logService.logOrder(this.order, `${deliveryVendorMappings[deliveryVendor]} 배차 요청에 실패했습니다.\n${JSON.stringify(callOutput)}`, 'error');
        Swal.fire(`${deliveryVendorMappings[deliveryVendor]} 배차 요청 실패:\n${JSON.stringify(callOutput)}`);
      }
    } catch (error) {
      spinnerRef.close();
      this.logService.withToastrCatch(error, `${deliveryVendorMappings[deliveryVendor]} 배차 요청 예외 발생`);
      this.logService.logOrder(this.order, `${deliveryVendorMappings[deliveryVendor]} 배차 요청 처리 중 예외가 발생했습니다.\n${JSON.stringify(error)}`, 'error');
    }
  }

  /**
   * addressForm에서 confirm된 주문이 오면 모델(unifiedOrder)에 추가한다.
   *
   * @param unifiedOrder 주소 관련한 부분만 포함하고 있다.
   */
  public onAddressConfirmed(unifiedOrder: Partial<UnifiedOrder>) {
    // 필드가 겹치지 않으므로 합하도록 한다.
    // this.order는 변형이 발생하기 때문에 앞에서 cloneDeep()으로 복사한 것이다.
    this.order = { ...this.order, ...unifiedOrder };

    // 주소가 바뀌면 instanceNo가 바뀔 수도 있다.
    if (this.vroongInstanceNo) {
      this.updateVroong();
    }

    const deliveryVendor = this.requestDeliveryForm.get('deliveryVendor').value;

    this.updateDeliveryInfo(deliveryVendor);

    this.recalculateDelivery();
    this.requestDeliveryForm.get('deliveryMinutes').setValue(this.deliveryParams.deliveryMinutes);
  }

  /**
   * 최초, 주소가 변경되었을 때마다
   * 배달 거리, 예상 배달 시간(deliveryMinutesOptions)를 다시 계산한다.
   */
  private recalculateDelivery() {
    if (this.order.address_location && this.order.address_location.lat > 30 && this.order.address_location.lon > 120) {
      const deliveryParams = this.deliveryUtilService.calculateDeliveryVendorParams(this.order, this.selectedDeliveryVendor);
      // const deliveryParams = this.deliveryUtilService.calculateDeliveryParams(this.order);

      // for (const [key, value] of Object.entries(deliveryParams)) {
      //   this[key] = value;
      // }

      this.deliveryParams = deliveryParams;
    } else {
      this.utilService.toastrError('위도, 경도가 올바르지 않습니다. 주소를 확인해 주세요.');
      return;
    }
  }

  private selectRequestPickupMinutes(responsePickups: number[]) {
    // 기존보다 큰 값을 못찾으면 목록중에 제일 큰 값
    // [20, 30, 40, 50]: 50 => [20, 30, 40]: 40
    const largestOption = responsePickups[responsePickups.length - 1];
    if (largestOption < this.selectedPickupMinute) {
      this.requestDeliveryForm.get('pickupOffset').setValue(largestOption);
      return;
    }

    // 같은 값이 있으면 유지
    // [20, 30, 40]: 20 => [20, 30, 40, 50]: 20
    // 목록에 없으면 큰 수 중에 제일 작은 값
    // [25, 30, 40]: 25 => [20, 30, 40, 50]: 30
    // [20, 30, 40, 50]: 20 => [30, 40]: 30

    // 중간에 탈출하기 위해서 every 사용
    const result = responsePickups.every(option => {
      if (this.selectedPickupMinute <= option) {
        this.requestDeliveryForm.get('pickupOffset').setValue(option);
        return false;
      }

      return true;
    });

    if (result === true) {
      this.logService.logOrder(this.order, 'select value error', 'error');
    }
  }

  copyToClipboard(event: MouseEvent, text: string = null) {
    const el = event.target as HTMLElement;
    // nested element의 경우에 상위에서 복사가 또 발생하는 것을 막는다.
    event.stopPropagation();

    if (text) {
      this.clipboardService.copyTextToClipboard(text, el);
    } else {
      this.clipboardService.copyTextToClipboard(el.innerText, el);
    }
  }
}
