/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

import { ContactDoc } from '../../schema/3/schema-ops';
import { CallInputSendSms, CallOutputSendSms } from '../../schema/4/schema-functions-call';

import { UtilService } from '../../core/1/util.service';
import { trimOrganization, normalizingTel, normalizeTel } from '../../core/2/util';
import { UserService } from '../../core/2/user.service';
import { LogService } from '../../core/3/log.service';
import { RoomService } from '../../core/4/room.service';

@Component({
  selector: 'app-dialog-send-sms',
  templateUrl: './dialog-send-sms.component.html',
  styleUrls: ['./dialog-send-sms.component.scss']
})
export class DialogSendSmsComponent implements OnInit, OnDestroy {
  public readonly sendNos = [
    { telNo: '1522-6385', desc: '고객센터' },
    { telNo: '010-8496-0658', desc: '우지' },
    { telNo: '010-4441-2365', desc: '킴' },
    { telNo: '010-4621-8608', desc: '본사 폰[pushbullet]' },
    { telNo: '010-9252-8608', desc: 'S 폰[pushbullet]' },
  ];

  public smsForm: FormGroup;
  room: string;
  name: string;
  telNo: string;
  currentByte = 0;
  public smsType: 'sms' | 'lms' = 'sms';
  public isButtonPressed = false;

  private destroySignal = new Subject<boolean>();

  constructor(
    private fns: AngularFireFunctions,
    public dialogRef: MatDialogRef<DialogSendSmsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { contact: Partial<ContactDoc> },
    private fb: FormBuilder,
    private roomService: RoomService,
    private logService: LogService,
    private utilService: UtilService,
    private userService: UserService
  ) { }

  ngOnInit() {
    // 통합주문 상태를 통해서 문자를 보낼 경우 라이더와 고객은 호실 정보가 없다.
    this.room = this.data.contact._room ? trimOrganization(this.roomService.rooms[this.data.contact._room]?.name) : null;
    this.name = this.data.contact.name;
    this.initForm();
    this.observeMessage();
    this.observeTelNo(this.smsForm.get('telNo'));
  }

  ngOnDestroy() {
    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  initForm() {
    this.smsForm = this.fb.group({
      sendNo: this.sendNos[0].telNo,
      telNo: normalizeTel(this.data.contact.telNo),
      message: ['', this.messageValidator()]
    });
  }

  closeDialog() {
    this.dialogRef?.close(false);
    this.dialogRef = undefined;
  }

  onCancel() {
    this.closeDialog();
  }

  async onSubmit() {
    const sendNo = this.smsForm.get('sendNo').value.replace(/-/g, '');
    const tel = this.smsForm.get('telNo').value.replace(/-/g, '');
    const message = this.smsForm.get('message').value;

    if (this.isButtonPressed) {
      return;
    }

    this.isButtonPressed = true;

    try {
      const callInput: CallInputSendSms = {
        telNo: tel,
        message,
        type: 'auto', // functions에서 메시지를 추가할 수 있으므로 여기서 고정하지 않는다.
        account: this.userService.user.email
      };

      // sendNo가 undefined 인 경우에는 발신전용 안내 메시지가 functions에서 자동으로 추가된다.
      if (sendNo !== '15226385') {
        callInput.senderTelNo = sendNo;
      }

      const callable = this.fns.httpsCallable<CallInputSendSms, CallOutputSendSms>('callSendSms');
      const callOutput = await callable(callInput).toPromise();

      if (callOutput.result === 'success') {
        this.utilService.toastrInfo(`${normalizeTel(sendNo)} => ${normalizeTel(tel)} 문자 발송 성공`);
      } else {
        this.logService.withToastrError(`${normalizeTel(sendNo)} => ${normalizeTel(tel)} 문자 발송 실패: ${callOutput.result}`);
      }
    } catch (error) {
      this.logService.withToastrCatch(error, 'dialog-send-sms의 onSubmit 예외 발생');
    }

    this.closeDialog();
  }

  observeMessage() {
    this.smsForm.get('message').valueChanges
      .pipe(takeUntil(this.destroySignal)).subscribe(message => {
        this.currentByte = this.getByte(message);

        this.smsForm.get('message').updateValueAndValidity({ emitEvent: false });

        this.smsType = this.currentByte > 90 ? 'lms' : 'sms';
      });
  }

  getByte(str: string): number {
    return str
      .split('')
      .map(s => s.charCodeAt(0))
      // tslint:disable-next-line: no-bitwise
      .reduce((prev, c) => (prev + ((c === 10) ? 2 : ((c >> 7) ? 2 : 1))), 0);
  }

  messageValidator(): ValidatorFn {
    return (): ValidationErrors | null => {
      if (this.currentByte > 2000) {
        return { reason: '2000Byte를 초과할 수 없습니다.' };
      }

      return null;
    };
  }

  /**
   * userTel에 변화가 있으면 포맷을 자동 적용한다.
   */
  private observeTelNo(formControl: AbstractControl) {
    formControl.valueChanges
    .pipe(takeUntil(this.destroySignal))
    .subscribe(value => {
      const normalizedTel = normalizingTel(value);
      if (value !== normalizedTel) {
        formControl.setValue(normalizedTel);
      }
    });
  }

}
