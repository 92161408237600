<div class="order-container-background" [ngClass]="cssClass" [class.shortHeight]="false">
    <!-- 제목 배경 용도로 자리만 잡는다.  -->
    <div></div>
  </div>


  <div class="order-container" [ngClass]="cssClass">
    <h3>
      <span class="date"><span *ngIf="!isTodayOrder">{{ order.orderDate | date:'M-d':'+0900' }} ({{ weekdayKR(order.orderDate) }}) </span>{{ order.orderDate | date:'HH:mm':'+0900' }}</span>

      <span *ngIf="order.address_dong && order.address_jibun">{{ order.address_dong }} {{ order.address_jibun }}</span>
      <span *ngIf="!order.address_dong || !order.address_jibun">{{ unifiedDeliveryTypeMappings[order.deliveryType] }}</span>

      <span class="status">{{ statusString }}</span>
    </h3>

    <table class="delivery">
      <tr >
        <td>{{ order.shopName }} / {{ trimOrganization(roomService.rooms[order.room].name)  }}</td>
      </tr>
    </table>

    <div *ngIf="order.deliveryType === 'TAKEOUT'" class="tags">
      <span>포장</span>
    </div>
    <div *ngIf="order.deliveryType === 'HERE'" class="tags">
      <span>매장식사</span>
    </div>

    <!-- 연락처, 지불방법 등 -->
    <app-order-contact [order]="order"></app-order-contact>

    <div class="dl">
      <div class="dt" *ngIf="order.simpleNo">간단 번호: {{ order.simpleNo }}</div>
      <div class="dt" *ngIf="order.orderMsg && order.orderMsg.length > 0">요청 사항 :</div>
      <div class="dd" *ngIf="order.orderMsg && order.orderMsg.length > 0"><span class="copyable" (click)="copyToClipboard($event)">{{ order.orderMsg }}</span></div>
      <div class="dt" *ngIf="order.contextStatusCode >= 15 && order.posRequestedPickupMinutes">POS 요청픽업시간: <span style="font-weight: normal; font-size:16px">{{ order.posRequestedPickupMinutes }}분</span></div>
      <div class="dt" *ngIf="order.contextStatusCode >= 15 && order.posDeliveryMinutes">POS 예상배달시간: <span style="font-weight: normal; font-size:16px">{{ order.posDeliveryMinutes }}분</span></div>
      <div class="dt" *ngIf="order.contextStatusCode >= 15 && (!order.posRequestedPickupMinutes && order.cookMinutes)">조리 시간: <span style="font-weight: normal; font-size:16px">{{ order.cookMinutes }}분</span></div>
      <div class="dt" *ngIf="order.contextStatusCode >= 20 && order.deliveryMinutes">배달 예상 시간: <span style="font-weight: normal; font-size:16px">{{ order.deliveryMinutes }}분</span></div>
      <div class="dt">주문 채널: <span style="font-weight: normal; font-size:16px">{{ channel }}</span></div>
      <div class="dt" *ngIf="order.adminMemo && order.adminMemo.length > 0">관리자 메모 :</div>
      <div class="dd" *ngIf="order.adminMemo && order.adminMemo.length > 0"><span class="copyable" (click)="copyToClipboard($event)">{{ order.adminMemo }}</span></div>
    </div>

    <!-- menu -->
    <app-menu [order]="order"></app-menu>

    <div class="order-actions">
      <div class="buttons">
        <button mat-raised-button (click)="onOk()">닫기</button>
      </div>
    </div>
    <div class="orderId">{{ order.orderNo }}</div>
  </div>

