/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogLoginComponent } from './dialog-login.component';

@Injectable({
  providedIn: 'root'
})
export class DialogLoginService {
  constructor(
    private dialog: MatDialog
  ) { }

  openLoginDialog(): MatDialogRef<DialogLoginComponent, boolean> {
    // 다른 다이얼로그는 닫는다.
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogLoginComponent, {
      width: '400px',
      disableClose: true // dialog의 외부를 클릭하면 닫히게 할 것인가?
    });

    return dialogRef;
  }
}
