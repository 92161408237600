<table class="contact">
  <tbody>
    <!-- <tr>
      <th>*</th>
      <td><span>{{ order.shopName }}</span></td>
    </tr> -->

    <tr>
      <th>⌂</th>
      <td [attr.colspan]="minimalView ? 1 : 3" class="address" (click)="copyToClipboard($event)">
        <span class="copyable" (click)="copyToClipboard($event)"> {{ orderAddress }}</span>&nbsp;<span class="copyable">{{ prefixMsg }}{{ orderAddressDetail }}</span>
      </td>
    </tr>
    <tr *ngIf="roadAddress">
      <th>⌂</th>
      <td [attr.colspan]="minimalView ? 1 : 3" class="roadAddress copyable" (click)="copyToClipboard($event)">
        <span class="copyable2" (click)="copyToClipboard($event)"> {{ roadAddress }}</span>
      </td>
    </tr>

    <tr *ngIf="showTelAndPrice && minimalView">
      <th>{{ order.paymentMethod === '후불현금' ? '💵' : '💳' }}</th>
      <td>
        <span>{{ paymentAmount | number }}원
          <span [class.strong]="order.paymentMethod === '후불현금' || order.paymentMethod === '후불카드'">({{ order.paymentMethod }})</span>
      </span>
      </td>
    </tr>

    <tr *ngIf="showTelAndPrice && !minimalView">
      <th>☎</th>
      <td>
        <span class="copyable" (click)="copyToClipboard($event, order.userTel)">{{ normalizeTel(order.userTel) }}</span>
        <span>&nbsp;&nbsp;&nbsp;{{ order.paymentMethod === '후불현금' ? '💵' : '💳' }} {{ paymentAmount | number }}원
          <span [class.strong]="order.paymentMethod === '후불현금' || order.paymentMethod === '후불카드'">({{ order.paymentMethod === 'NA' ? '모름' : order.paymentMethod }})</span>
        </span>
      </td>
    </tr>
  </tbody>
</table>
