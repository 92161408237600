/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UnifiedOrderDoc } from '../../schema/3/schema';

import { DialogLogOrderComponent } from './dialog-log-order.component';

@Injectable({
  providedIn: 'root'
})
export class DialogLogOrderService {
  constructor(
    private dialog: MatDialog
  ) { }

  openDialog(order: UnifiedOrderDoc) {
    const dialogRef = this.dialog.open(DialogLogOrderComponent, {
      data: {
        order
      },
      width: '80vw',
      height: '80vh',
      maxWidth: '1264px',
    });

    return dialogRef;
  }
}
