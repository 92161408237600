/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { SiteDocs } from '../../schema/3/schema';

import { LogService } from '../../core/3/log.service';
import { SiteService } from '../../core/3/site.service';
import { FirebaseManagerService } from '../../core/4/firebase-manager.service';

@Component({
  selector: 'app-dialog-ghostrider-options-pickup',
  templateUrl: './dialog-ghostrider-options-pickup.component.html',
  styleUrls: ['./dialog-ghostrider-options-pickup.component.scss']
})
export class DialogGhostriderOptionsPickupComponent implements OnInit, OnDestroy {
  public readonly pickupOptions = ['5', '10', '15', '20', '25', '30', '40', '50'];
  private subscripiton: Subscription;
  private latestSite: SiteDocs = {};

  public uiSites: {
    siteKey: string;
    siteName: string;
    minPickupOption: string;
  }[] = [];

  private newPickupOptions: {
    [siteKey: string]: string;
  } = {};

  constructor(
    private dialogRef: MatDialogRef<DialogGhostriderOptionsPickupComponent, boolean>,
    private logService: LogService,
    private siteService: SiteService,
    private firebaseManager: FirebaseManagerService,
  ) {
  }

  ngOnInit() {
    // 1. 일단 업데이트 하고
    this.updateView();

    // this.siteService.latestSubject.subscribe(site => {
    //   this.latestSite = site;

    //   // 2. 변경되면 업데이트 한다.
    //   this.updateView();
    // });
  }

  ngOnDestroy() {
    if (this.subscripiton) {
      this.subscripiton.unsubscribe();
    }
  }

  /**
   * collection의 내용을 UI에 반영한다.
   */
  updateView() {
    const site = this.siteService.sites;

    this.uiSites = Object.values(site).map(doc => ({
      siteKey: doc._id,
      siteName: doc.siteName,
      minPickupOption: doc.ghostrider.minPickupOption
    }));
  }

  onChangePickupOption(site: string, event: MatRadioChange) {
    this.newPickupOptions[site] = event.value;
  }

  async onSubmit() {
    for (const siteKey of Object.keys(this.newPickupOptions)) {
      const pickupOption = this.newPickupOptions[siteKey];

      try {
        await this.firebaseManager.setDoc('site', siteKey, {
          ghostrider: {
            minPickupOption: pickupOption
          }
        });
      } catch (error) {
        this.logService.withToastrCatch(error, 'dialog-ghostrider-options-pickup.component의 onSubmit에서 예외 발생');
      }
    }

    if (this.dialogRef) {
      this.dialogRef.close(true);
      this.dialogRef = undefined;
    }
  }

  onCancel() {
    if (this.dialogRef) {
      this.dialogRef.close(false);
      this.dialogRef = undefined;
    }
  }
}
