<!-- 드래그가 가능하도록 옵션을 추가. cdkDragRootElement으로 드래그가 가능한 root 영역을 지정한다. -->
<div class="container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div class="buttons">
    <button mat-stroked-button color="primary" (click)="openLogOrderDialog()">주문 처리 이력</button>
    <button mat-stroked-button color="primary" (click)="openOrderHistory()">고객 주문 이력</button>
    <button mat-stroked-button color="primary" (click)="openDestMap()">지도 열기</button>
    <button mat-stroked-button color="primary" (click)="openPrintOrderDialog()">인쇄...</button>
    <button *ngIf="order.createdBy === 'manual' || order.orderVendor === 'ghostkitchen'" mat-stroked-button color="primary" color="warn" (click)="modifyOrder()">주문 수정...</button>
    <button *ngIf="isAdmin" mat-stroked-button color="primary" (click)="openRawDataDialog()">관련 raw 주문</button>
  </div>
  <div class="related-delivery">
    <app-related-delivery [order]="order"></app-related-delivery>
  </div>
  <div class="flex-container">
    <div class="orderAction">
      <app-order-action [order]="order" (actionDone)="memo.updatePartialOrder($event); statusActionDone($event);"></app-order-action>
    </div>
    <div class="contact">
      <app-fast-contact [order]="order"></app-fast-contact>
      <app-order-memo #memo [order]="order" (memoActionDone)="memoActionDone($event)"></app-order-memo>
    </div>
  </div>
</div>
