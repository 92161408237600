<table *ngIf="deliveryInfo">
  <thead>
    <tr>
      <th>배달대행사(벤더)</th>
      <td>
        {{ deliveryInfo?.deliveryVendor ? deliveryVendorMappings[deliveryInfo.deliveryVendor] : '' }}
      </td>
    </tr>
    <tr>
      <th>instanceNo</th>
      <td>
        {{ deliveryInfo?.instanceNo }}
      </td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th>대행요청 가능여부</th>
      <td>
        {{ deliveryInfo?.deliveryInfo?.isPossible ? "가능" : "불가" }}
      </td>
    </tr>
    <tr>
      <th>배달거리</th>
      <td>
        {{ deliveryInfo?.deliveryInfo?.distance | number:'1.0-0' }}m
      </td>
    </tr>
    <!-- <tr>
      <th>가능 픽업 시간</th>
      <td>
        {{ deliveryInfo?.deliveryInfo?.pickups.join('|') }}
      </td>
    </tr> -->
    <tr *ngIf="deliveryInfo.deliveryInfo?.extraAvailable">
      <th>기본요금</th>
      <td>
        {{ deliveryInfo?.deliveryInfo?.baseFee | number }}원
      </td>
    </tr>
    <tr>
      <th>전체요금</th>
      <td>
        {{ deliveryInfo?.deliveryInfo?.totalFee | number}}원
      </td>
    </tr>
  </tbody>
</table>
<div>
  <p *ngIf="deliveryInfo?.result === 'warning'">
    [{{ deliveryInfo.reason }}]
  </p>
</div>