/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AngularFireFunctions } from '@angular/fire/functions';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { Module, ColDef, ColGroupDef, RowNode } from '@ag-grid-community/core';

import { UnifiedOrderFood } from '../../../schema/1/schema-common';
import { UnifiedOrderDoc } from '../../../schema/3/schema';
import { CallInputOrderHistory, CallOutputOrderHistory, OrderHistory } from '../../../schema/4/schema-functions-call';

import { addressFormatter, forDynamicPlain, forTime, forPlain, forDynamicDecimal } from '../../../core/1/ag-util';
import { unifiedOrderVendorMappings, unifiedOrderChannelMappings, unifiedOrderDeliveryTypeMappings, unifiedOrderCreatedByMappings } from '../../../core/1/string-map';
import { LogService } from '../../../core/3/log.service';

import { DialogSpinnerService } from '../../dialog-spinner/dialog-spinner.service';
import { CustomTooltipComponent } from '../../custom-tooltip/custom-tooltip.component';
import { DialogSpinnerComponent } from '../../dialog-spinner/dialog-spinner.component';
import { OrderHistoryGroupRendererComponent } from './order-history-group-renderer/order-history-group-renderer.component';

@Component({
  selector: 'app-dialog-order-history',
  templateUrl: './dialog-order-history.component.html',
  styleUrls: ['./dialog-order-history.component.scss']
})
export class DialogOrderHistoryComponent implements OnInit {
  @Input() order: UnifiedOrderDoc;
  public history: string;

  public modules: Module[] = [
    ClientSideRowModelModule,
    RowGroupingModule,
  ];

  defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    tooltipComponentFramework: CustomTooltipComponent, // custom tooltip 사용
  };

  columnDefs: (ColDef | ColGroupDef)[] = [
    forDynamicPlain('업소 분류', 100, params => {
      const order: UnifiedOrderDoc = params.data;

      if (order) {
        return (order.shopName === this.order.shopName) ? '동일 업소명' : '다른 업소명';
      }

      return '';
    }, { headerTooltip: '_ui.currentShop', rowGroup: true, hide: true }),
    forTime('주문 시각', 'orderDate', 148, 'yyyy-MM-dd ccc HH:mm', { sort: 'desc', filter: 'agTextColumnFilter' }),
    forPlain('업소명', 'shopName', 110, { aggFunc: 'count' }),
    forPlain('주소1', 'address_key', 146, { valueFormatter: params => addressFormatter(params.value) }),
    forPlain('주소2', 'address_detail', 90),
    forPlain('요청사항', 'orderMsg', 150, { autoHeight: true, cellClass: 'cell-wrap-text' }),
    forDynamicPlain('메뉴요약', 200, params => {
      const order: OrderHistory = params.data;

      return order?.foods?.map((food: UnifiedOrderFood) => {
        return (food.foodQty > 1) ? `${food.mergedName} x ${food.foodQty}개` : food.mergedName;
      }).join(' , ') ?? '';
    }, { headerTooltip: 'foods.mergedName' }),
    forDynamicDecimal('결제액', 80, params => {
      const order: OrderHistory = params.data;
      if (order) {
        const [{ orderAmount = 0, deliveryTip = 0, discount = 0 } = {}] = [order];
        return orderAmount + deliveryTip - discount;
      }
      return undefined;
    }, 0, { colId: 'orderPrice', enableValue: true }),
    forPlain('채널', 'orderChannel', 71, { refData: unifiedOrderChannelMappings }),
    forPlain('벤더', 'orderVendor', 78, { refData: unifiedOrderVendorMappings }),
    forPlain('배달유형', 'deliveryType', 90, { refData: unifiedOrderDeliveryTypeMappings }),
    forPlain('생성유형', 'createdBy', 90, { refData: unifiedOrderCreatedByMappings, filter: 'agSetColumnFilter' }),
    forPlain('결제방식', 'paymentMethod', 88)
  ];

  frameworkComponents = { groupRowInnerRenderer: OrderHistoryGroupRendererComponent };
  groupRowRendererParams = {
    suppressCount: true,
    innerRenderer: 'groupRowInnerRenderer'
  };

  rowData: OrderHistory[] = [];

  private dialogSpinnerRef: MatDialogRef<DialogSpinnerComponent>;

  constructor(
    private fns: AngularFireFunctions,
    private dialogSpinnerService: DialogSpinnerService,
    private logService: LogService,
    @Inject(MAT_DIALOG_DATA) public data: { order: UnifiedOrderDoc },
    public dialogRef: MatDialogRef<DialogOrderHistoryComponent, boolean>,
  ) { }

  ngOnInit(): void {
    this.order = this.data.order;
    this.getOrderHistory();
  }

  public getRowNodeId(data: UnifiedOrderDoc) {
    return data._id;
  }

  public defaultGroupSortComparator(nodeA: RowNode, nodeB: RowNode) {
    // string을 number로 변환한 후에 비교한다.
    const currentShopA = nodeA.key;
    const currentShopB = nodeB.key;

    const sortKeyA = currentShopA === '현재 업소' ? 1 : 2;
    const sortKeyB = currentShopB === '현재 업소' ? 1 : 2;

    if (sortKeyA < sortKeyB) {
      return -1;
    } else if (sortKeyA > sortKeyB) {
      return 1;
    } else {
      return 0;
    }
  }

  public closeDialog() {
    this.dialogRef?.close();
    this.dialogRef = undefined;
  }

  private async getOrderHistory() {
    this.dialogSpinnerRef = this.dialogSpinnerService.openSpinnerDialog('불러오는 중');
    try {
      const callOrderHistory = this.fns.httpsCallable<CallInputOrderHistory, CallOutputOrderHistory>('callOrderHistory');
      const response = await callOrderHistory({ orderId: this.order._id }).toPromise();

      if (response.result === 'success') {
        const { orderCount, totalOrderAmount } = response.orderHistories.reduce((acc, customerOrder) => {
          acc.orderCount++;

          const orderAmount = customerOrder.orderAmount ? customerOrder.orderAmount : 0;
          const deliveryTip = customerOrder.deliveryTip ? customerOrder.deliveryTip : 0;
          acc.totalOrderAmount += (orderAmount + deliveryTip);
          return acc;
        }, {
          orderCount: 0,
          totalOrderAmount: 0
        });

        const formattedOrderCount = new Intl.NumberFormat().format(orderCount);
        const formattedOrderAmount = new Intl.NumberFormat().format(totalOrderAmount);
        this.history = `주문 수: ${formattedOrderCount}건, 매출: ${formattedOrderAmount}원`;

        this.rowData = response.orderHistories;
      } else {
        this.logService.logRoomWithToastrError(this.order.room, `[getOrderHistory] result: ${response.result}, reason: ${response.reason}`);
      }
    } catch (error) {
      this.logService.logRoomWithToastrCatch(this.order.room, error);
    }

    this.dialogSpinnerRef?.close();
  }
}
