/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import Swal from 'sweetalert2';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/functions';

import { UnifiedOrderDoc, UnifiedOrderStatusCode, UnifiedOrderContextStatusCode } from '../../schema/3/schema';
import { CallInputCancelYogiyoOrder, CallOutputCancelYogiyoOrder } from '../../schema/4/schema-functions-call';

import { LogService } from '../../core/3/log.service';
import { FirebaseManagerService } from '../../core/4/firebase-manager.service';
import { MessageService } from '../../core/5/message.service';

interface CancelDesc {
  cancelCode: string | number;
  cancelReason: string;
}

const cancelCodesMap: {
  [vendor in 'baemin' | 'coupangeats' | 'yogiyo']: {
    [deliveryType: string]: {
      [type in 'REJECT' | 'CANCEL']: CancelDesc[];
    };
  }
} = {
  baemin: {
    DELIVERY: {
      REJECT: [
        { cancelCode: '01', cancelReason: '배달불가지역' },
        { cancelCode: '19', cancelReason: '메뉴및가게정보다름' },
        { cancelCode: '20', cancelReason: '재료소진' },
        { cancelCode: '04', cancelReason: '배달지연' },
        { cancelCode: '05', cancelReason: '고객정보부정확' },
        { cancelCode: '06', cancelReason: '기타' },
      ],
      CANCEL: [
        { cancelCode: '07', cancelReason: '고객요청' },
        { cancelCode: '24', cancelReason: '가게사정' },
        { cancelCode: '25', cancelReason: '배달불가' },
        { cancelCode: '26', cancelReason: '재료소진' },
      ]
    },
    TAKEOUT: {
      REJECT: [
        { cancelCode: '19', cancelReason: '메뉴및가게정보다름' },
        { cancelCode: '20', cancelReason: '재료소진' },
        { cancelCode: '27', cancelReason: '조리지연' },
        { cancelCode: '28', cancelReason: '가게사정' },
        { cancelCode: '29', cancelReason: '요청사항불가' },
        { cancelCode: '06', cancelReason: '기타' },
      ],
      CANCEL: [
        { cancelCode: '07', cancelReason: '고객요청' },
        { cancelCode: '24', cancelReason: '가게사정' },
        { cancelCode: '26', cancelReason: '재료소진' },
      ]
    },
    /** EAT_IN */
    HERE: {
      REJECT: [
        { cancelCode: '19', cancelReason: '메뉴및가게정보다름' },
        { cancelCode: '20', cancelReason: '재료소진' },
        { cancelCode: '27', cancelReason: '조리지연' },
        { cancelCode: '28', cancelReason: '가게사정' },
        { cancelCode: '29', cancelReason: '요청사항불가' },
        { cancelCode: '06', cancelReason: '기타' },
      ],
      CANCEL: [
        { cancelCode: '07', cancelReason: '고객요청' },
        { cancelCode: '24', cancelReason: '가게사정' },
        { cancelCode: '26', cancelReason: '재료소진' },
      ]
    },
    BAERA: {
      REJECT: [
        { cancelCode: '19', cancelReason: '메뉴및가게정보다름' },
        { cancelCode: '20', cancelReason: '재료소진' },
        { cancelCode: '27', cancelReason: '조리지연' },
        { cancelCode: '28', cancelReason: '가게사정' },
        { cancelCode: '29', cancelReason: '요청사항불가' },
        { cancelCode: '06', cancelReason: '기타' },
      ],
      CANCEL: [
        { cancelCode: '07', cancelReason: '고객요청' },
        { cancelCode: '24', cancelReason: '가게사정' },
        { cancelCode: '26', cancelReason: '재료소진' },
      ]
    }
  },
  coupangeats: {
    COUPANG: {
      REJECT: [
        { cancelCode: '1', cancelReason: '품절' },
        { cancelCode: '2', cancelReason: '주문 폭주' },
        { cancelCode: '17', cancelReason: '고객 요청' },
        { cancelCode: '19', cancelReason: '금일 영업 마감' },
        { cancelCode: '4', cancelReason: '금일 휴업' },
        { cancelCode: '5', cancelReason: '메뉴 상이' },
        // { code: '3', name: '기타 사유' },
        // 3의 자식
        { cancelCode: '6', cancelReason: '기타 (주문금액)' },
        { cancelCode: '16', cancelReason: '기타 (폐업·사업자 변경)' },
        { cancelCode: '15', cancelReason: '기타 (사용법 모름)' },
        { cancelCode: '7', cancelReason: '기타 (테스트 주문)' },
        { cancelCode: '18', cancelReason: '기타 (상점 귀책)' },
      ],
      CANCEL: [
        { cancelCode: '1', cancelReason: '품절' },
        { cancelCode: '2', cancelReason: '주문 폭주' },
        { cancelCode: '17', cancelReason: '고객 요청' },
        { cancelCode: '19', cancelReason: '금일 영업 마감' },
        { cancelCode: '4', cancelReason: '금일 휴업' },
        { cancelCode: '5', cancelReason: '메뉴 상이' },
        // { code: '3', name: '기타 사유' },
        // 3의 자식
        { cancelCode: '6', cancelReason: '기타 (주문금액)' },
        { cancelCode: '16', cancelReason: '기타 (폐업·사업자 변경)' },
        { cancelCode: '15', cancelReason: '기타 (사용법 모름)' },
        { cancelCode: '7', cancelReason: '기타 (테스트 주문)' },
        { cancelCode: '18', cancelReason: '기타 (상점 귀책)' },
      ]
    }
  }, yogiyo: {
    DELIVERY: {
      REJECT: [
        { cancelCode: 1, cancelReason: '배달 불가지역' },
        { cancelCode: 2, cancelReason: '배달원 부재' },
        { cancelCode: 3, cancelReason: '주문량 폭주' },
        { cancelCode: 4, cancelReason: '메뉴 품절(재료소진)' },
        { cancelCode: 5, cancelReason: '요청사항 적용불가' },
        { cancelCode: 6, cancelReason: '메뉴/가격변동' },
        { cancelCode: 7, cancelReason: '영업시간아님(휴무/조기마감/늦게오픈)' },
        { cancelCode: 8, cancelReason: '기상악화' },
      ],
      CANCEL: [
        { cancelCode: 1, cancelReason: '배달 불가지역' },
        { cancelCode: 2, cancelReason: '배달원 부재' },
        { cancelCode: 3, cancelReason: '주문량 폭주' },
        { cancelCode: 4, cancelReason: '메뉴 품절(재료소진)' },
        { cancelCode: 5, cancelReason: '요청사항 적용불가' },
        { cancelCode: 6, cancelReason: '메뉴/가격변동' },
        { cancelCode: 7, cancelReason: '영업시간아님(휴무/조기마감/늦게오픈)' },
        { cancelCode: 8, cancelReason: '기상악화' },
      ]
    },
    YOGIYO: {
      REJECT: [
        { cancelCode: 1, cancelReason: '배달 불가지역' },
        { cancelCode: 2, cancelReason: '배달원 부재' },
        { cancelCode: 3, cancelReason: '주문량 폭주' },
        { cancelCode: 4, cancelReason: '메뉴 품절(재료소진)' },
        { cancelCode: 5, cancelReason: '요청사항 적용불가' },
        { cancelCode: 6, cancelReason: '메뉴/가격변동' },
        { cancelCode: 7, cancelReason: '영업시간아님(휴무/조기마감/늦게오픈)' },
        { cancelCode: 8, cancelReason: '기상악화' },
      ],
      CANCEL: [
        { cancelCode: 1, cancelReason: '배달 불가지역' },
        { cancelCode: 2, cancelReason: '배달원 부재' },
        { cancelCode: 3, cancelReason: '주문량 폭주' },
        { cancelCode: 4, cancelReason: '메뉴 품절(재료소진)' },
        { cancelCode: 5, cancelReason: '요청사항 적용불가' },
        { cancelCode: 6, cancelReason: '메뉴/가격변동' },
        { cancelCode: 7, cancelReason: '영업시간아님(휴무/조기마감/늦게오픈)' },
        { cancelCode: 8, cancelReason: '기상악화' },
      ]
    },
    TAKEOUT: {
      REJECT: [
        { cancelCode: 1, cancelReason: '배달 불가지역' },
        { cancelCode: 2, cancelReason: '배달원 부재' },
        { cancelCode: 3, cancelReason: '주문량 폭주' },
        { cancelCode: 4, cancelReason: '메뉴 품절(재료소진)' },
        { cancelCode: 5, cancelReason: '요청사항 적용불가' },
        { cancelCode: 6, cancelReason: '메뉴/가격변동' },
        { cancelCode: 7, cancelReason: '영업시간아님(휴무/조기마감/늦게오픈)' },
        { cancelCode: 8, cancelReason: '기상악화' },
      ],
      CANCEL: [
        { cancelCode: 1, cancelReason: '배달 불가지역' },
        { cancelCode: 2, cancelReason: '배달원 부재' },
        { cancelCode: 3, cancelReason: '주문량 폭주' },
        { cancelCode: 4, cancelReason: '메뉴 품절(재료소진)' },
        { cancelCode: 5, cancelReason: '요청사항 적용불가' },
        { cancelCode: 6, cancelReason: '메뉴/가격변동' },
        { cancelCode: 7, cancelReason: '영업시간아님(휴무/조기마감/늦게오픈)' },
        { cancelCode: 8, cancelReason: '기상악화' },
      ]
    },
  }
};

@Component({
  selector: 'app-dialog-cancel-order',
  templateUrl: './dialog-cancel-order.component.html',
  styleUrls: ['./dialog-cancel-order.component.scss']
})
export class DialogCancelOrderComponent implements OnInit {
  public order: UnifiedOrderDoc;
  public cancelOrderForm: FormGroup;
  public cancelDescs: CancelDesc[] = [];

  private isCancelButtonPressed = false;

  constructor(
    public dialogRef: MatDialogRef<DialogCancelOrderComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: { order: UnifiedOrderDoc },
    private fb: FormBuilder,
    private fns: AngularFireFunctions,
    private messageService: MessageService,
    private logService: LogService,
    private firebaseManager: FirebaseManagerService
  ) { }

  ngOnInit() {
    this.order = this.data.order;

    const order = this.order;
    if (order.orderVendor === 'baemin') {
      this.cancelDescs = cancelCodesMap.baemin[order.deliveryType]?.[order.orderStatusCode === UnifiedOrderStatusCode.NEW ? 'REJECT' : 'CANCEL'];
    } else if (this.order.orderVendor === 'coupangeats') {
      this.cancelDescs = cancelCodesMap.coupangeats[order.deliveryType]?.[order.orderStatusCode === UnifiedOrderStatusCode.NEW ? 'REJECT' : 'CANCEL'];
    } else if (this.order.orderVendor === 'yogiyo') {
      this.cancelDescs = cancelCodesMap.yogiyo[order.deliveryType]?.[order.orderStatusCode === UnifiedOrderStatusCode.NEW ? 'REJECT' : 'CANCEL'];
    } else {
      // manual 주문인 경우에는 일단 배민 코드를 따른다.
      this.cancelDescs = cancelCodesMap.baemin[order.deliveryType]?.[order.orderStatusCode === UnifiedOrderStatusCode.NEW ? 'REJECT' : 'CANCEL'];
    }

    this.cancelOrderForm = this.fb.group({
      cancelReasonCode: this.cancelDescs[0].cancelCode
    });
  }

  onCancel() {
    this.dialogRef?.close(false);
    this.dialogRef = undefined;
  }

  async onSubmit() {
    // console.log(`cancelReasonCode = ${this.cancelOrderForm.value.cancelReasonCode}`);
    const cancelCode = this.cancelOrderForm.value.cancelReasonCode;
    const cancelReason = this.cancelDescs.find(cancelDesc => cancelDesc.cancelCode === cancelCode).cancelReason ?? '??';

    if (this.isCancelButtonPressed === false) {
      this.isCancelButtonPressed = true; // 중복 클릭 방지

      try {
        // 2020-05-16 요기요의 경우에 취소가 목록에서 사라지는 방식이라 수동으로 변경할 수 있도록 한다.
        if (this.order.createdBy === 'manual' ||
          this.order.orderVendor === 'ghostkitchen') {
          await this.firebaseManager.setDoc('unifiedOrder', this.order._id, {
            orderStatusCode: UnifiedOrderStatusCode.CANCELED,
            contextStatusCode: UnifiedOrderContextStatusCode.CANCELED,
            cancelCode,
            cancelReason,
            cancelConfirmed: true // 직접 취소를 하고 있기 때문에 바로 확인 상태로 변경한다.
          });

          this.logService.info(`${this.order._id} 취소 상태로 변경`);
          this.logService.logOrder(this.order, `'주문 ${this.order.orderStatusCode === UnifiedOrderStatusCode.NEW ? '거부' : '취소'}' 버튼을 눌러서 취소(CANCELED) 주문으로 상태를 변경했습니다.`);
        } else if (this.order.orderVendor === 'baemin') {

          // 응답을 받을 때까지 기다린다.
          await this.messageService.requestCancelBaeminOrder(
            this.order.instanceNo,
            this.order.orderNo,
            this.cancelOrderForm.value.cancelReasonCode
          );

          await this.firebaseManager.setDoc('unifiedOrder', this.order._id, {
            cancelConfirmed: true // 직접 취소를 하고 있기 때문에 바로 확인 상태로 변경한다.
          });
          this.logService.logOrder(this.order, `'주문 ${this.order.orderStatusCode === UnifiedOrderStatusCode.NEW ? '거부' : '취소'}' 버튼을 눌러서 취소(CANCELED) 주문으로 상태를 변경했습니다.`);
        } else if (this.order.orderVendor === 'coupangeats') {
          const order = this.order;
          const orderNo = this.order.orderNo;

          // 응답을 받을 때까지 기다린다.
          await this.messageService.requestCancelCoupangeatsOrder(
            order.instanceNo,
            orderNo,
            this.cancelOrderForm.value.cancelReasonCode,
            order.orderStatusCode === UnifiedOrderStatusCode.NEW ? 'DECLINE' : 'CANCEL'
          );

          await this.firebaseManager.setDoc('unifiedOrder', this.order._id, {
            cancelConfirmed: true // 직접 취소를 하고 있기 때문에 바로 확인 상태로 변경한다.
          });
        } else if (this.order.orderVendor === 'yogiyo') {
          const callInput: CallInputCancelYogiyoOrder = {
            orderId: this.order._id,
            declineReasonCode: this.cancelOrderForm.value.cancelReasonCode
          };

          const callable = this.fns.httpsCallable<CallInputCancelYogiyoOrder, CallOutputCancelYogiyoOrder>('callCancelYogiyoOrder');
          const response = await callable(callInput).toPromise();

          if (response.result === 'error') {
            this.logService.logOrder(this.order, `'주문 ${this.order.orderStatusCode === UnifiedOrderStatusCode.NEW ? '거부' : '취소'}' 버튼을 눌러서 취소(CANCELED) 주문으로 상태를 변경하려 했으나 오류가 발생했습니다. : ${response.reason}`);
            return;
          }

          await this.firebaseManager.setDoc('unifiedOrder', this.order._id, {
            cancelConfirmed: true // 직접 취소를 하고 있기 때문에 바로 확인 상태로 변경한다.
          });
          this.logService.logOrder(this.order, `'주문 ${this.order.orderStatusCode === UnifiedOrderStatusCode.NEW ? '거부' : '취소'}' 버튼을 눌러서 취소(CANCELED) 주문으로 상태를 변경했습니다.`);
        } else {
          this.logService.logOrder(this.order, `'주문 ${this.order.orderStatusCode === UnifiedOrderStatusCode.NEW ? '거부' : '취소'}' 버튼을 눌러서 취소(CANCELED) 주문으로 상태를 변경했습니다.`);
          Swal.fire(`배민도 아니고 직접 입력도 아닌 것(${this.order.orderVendor})이 취소를 하려하고 있다. 개발자에게 알려주세요.`);
        }
        if (this.dialogRef) {
          this.dialogRef.close(true);
          this.dialogRef = undefined;
        }
      } catch (error) {
        this.logService.logOrder(this.order, `'주문 ${this.order.orderStatusCode === UnifiedOrderStatusCode.NEW ? '거부' : '취소'}' 버튼을 누른 후 처리 중 오류 발생: ${error.message}`, 'error');
        console.error(error);
        Swal.fire(error.message);
      }
    }
  }
}
