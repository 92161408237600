/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import format from 'date-fns/format';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { WHERE } from '../../schema/1/schema-common';
import { UnifiedOrder, UnifiedOrderDoc, UnifiedOrderContextStatusCode } from '../../schema/3/schema';

import { UserService } from '../2/user.service';

// const collectionPathSuffix = 'Beta';
const collectionPathSuffix = '';
const unifiedOrderCollectionPath = 'unifiedOrder' + collectionPathSuffix;

@Injectable({
  providedIn: 'root'
})
export class UnifiedOrderService {
  constructor(
    private db: AngularFirestore,
    private userService: UserService
  ) { }

  /**
   *
   * @param openHours 영업 시작 시간
   * @param openMinutes 영업 시작 분
   * @param [atDate=0] 0: 오늘(0시 기준이 아니라 영업시간기준), -1: 어제
   */
  // tslint:disable-next-line: max-line-length
  private observe(collectionPath: string, wheres: WHERE[], openHours: number, openMinutes: number, atDate = 0, orderBy: 'asc' | 'desc' = 'asc') {
    const now = new Date();
    const nowHours = now.getHours();
    const nowMinutes = now.getMinutes();

    // 현재 시각이 오픈 이전이라면 이전 날짜에 대한 주문부터 가져온다.
    if (openHours * 60 + openMinutes > nowHours * 60 + nowMinutes) {
      atDate -= 1;
    }

    const openHHMM = String(openHours).padStart(2, '0') + ':' + String(openMinutes).padStart(2, '0');

    // orderDate: string; // "2019-04-09T18:56:20+0900",
    const atDateString = format(new Date(now.getTime() + atDate * 24 * 3600 * 1000), `yyyy-MM-dd'T'${openHHMM}:00+0900`);

    console.log(`${this.constructor.name}::observe ${collectionPath} from ${atDateString}`);

    const organization = this.userService.organization;
    const queryFn: QueryFn = ref => wheres.reduce((q, where: WHERE) => q.where(where[0], where[1] as firestore.WhereFilterOp, where[2]),
      ref.where('organization', '==', organization)
        .orderBy('orderDate', orderBy)[orderBy === 'asc' ? 'startAt' : 'endAt'](atDateString)
    );

    const orderCollection = this.db.collection<UnifiedOrderDoc>(collectionPath, queryFn);

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = orderCollection.valueChanges();

    return observable;
  }

  public observeOrder(wheres: WHERE[], openHours: number, openMinutes: number, atDate = 0, orderBy: 'asc' | 'desc' = 'asc') {
    return this.observe(unifiedOrderCollectionPath, wheres, openHours, openMinutes, atDate, orderBy);
  }

  /**
   * unifiedOrder의 읿부 필드를 직접 변경한다.
   * 주로 manual 주문에 대해서 처리할 때 사용한다.
   */
  public mergeOrder(order: Partial<UnifiedOrder>) {
    const id = order._id;

    if (id == null) {
      throw new Error('_id field must exist');
    }

    const docRef = this.db.firestore.collection(unifiedOrderCollectionPath).doc(id);
    const doc: Partial<UnifiedOrderDoc> = order;

    delete (doc as any)._ui;

    return this.db.doc<Partial<UnifiedOrderDoc>>(docRef).set(doc, {
      merge: true
    });
  }

  public setManualOrder(order: Partial<UnifiedOrder>, isUpdate = false) {
    const doc: Partial<UnifiedOrderDoc> = order;

    if (isUpdate) {
      doc._id = order._id;
    } else {
      const docRef0 = this.db.firestore.collection(unifiedOrderCollectionPath).doc();

      doc._id = `${order.createdBy ? (order.createdBy === 'fingerFace' ? 'finger' : order.createdBy) : order.orderVendor}-${docRef0.id}`;
      doc.orderNo = docRef0.id;
      doc._timeCreate = firestore.FieldValue.serverTimestamp() as firestore.Timestamp;
    }

    // contextStautsCode가 없거나 orderStatusCode가 앞선다면 추가한다.
    if (order.contextStatusCode === undefined || order.orderStatusCode > order.contextStatusCode) {
      doc.contextStatusCode = order.orderStatusCode as unknown as UnifiedOrderContextStatusCode;
    }

    const docRef = this.db.firestore.collection(unifiedOrderCollectionPath).doc(doc._id);
    // console.dir(doc);

    delete (doc as any)._ui;

    return this.db.doc<Partial<UnifiedOrderDoc>>(docRef).set(doc);
  }
}
