/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { environment } from '../environments/environment';

import { SiteDocs } from './schema/3/schema';

import { debugLog } from './core/1/common';
import { IpService } from './core/1/ip.service';
import { UtilService } from './core/1/util.service';
import { SoundService } from './core/1/sound.service';
import { LocalConfigurationService } from './core/1/local-configuration.service';
import { roleMappings } from './core/1/string-map';
import { AuthService } from './core/2/auth.service';
import { sleep, trimOrganization } from './core/2/util';
import { UserService } from './core/2/user.service';
import { AccountService } from './core/3/account.service';
import { UnifiedMenuService } from './core/3/unified-menu.service';
import { LogService } from './core/3/log.service';
import { GhokirunRiderService } from './core/3/ghokirun-rider.service';
import { SiteService } from './core/3/site.service';
import { VersionService } from './core/4/version.service';
import { RoomService } from './core/4/room.service';
import { VroongPosOptionsPickupService } from './core/4/vroong-pos-options-pickup.service';
import { MessageService } from './core/5/message.service';

import { DialogSendSmsService } from './shared/dialog-send-sms/dialog-send-sms.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isDev = false;
  public loggedIn = false;
  public email = '';
  public myVersion: string;
  public latestVersion: string;
  public showSitesFilter = false;

  public sites: {
    _id: string,
    siteName: string
  }[];
  public siteControl = new FormControl([]);
  public currentNavItemName: string;

  public hideSelfDeliveryControl = new FormControl(false);
  public numSelfDeliveries = 0;
  public selfDeliveryRooms = '';
  public showSelfDeliveryFilter = false;

  public title = '발가락 OMC';
  public navItems = [
    { name: '통합 주문', route: '/unified-order/all', siteFilter: true, showSelfDeliveryFilter: true },
    { name: '통합 주문 조회 Live!', route: '/unified-order-range', siteFilter: true },
    { name: '다이렉트 주문', route: '/direct-order', siteFilter: true },
    { name: 'divider' },
    { name: '통합 배차', route: '/unified-delivery', siteFilter: true },
    { name: '통합 배차 조회', route: '/unified-delivery-range', siteFilter: true },
    { name: '통합 배차 업소', route: '/unified-delivery-shop', siteFilter: true },
    { name: 'divider' },
    { name: '통합 메뉴', route: '/unified-menu', siteFilter: true },
    { name: 'divider' },
    { name: '연동 설정', route: '/organization', siteFilter: true },
    { name: '연동 상태', route: '/linkage-state', siteFilter: true },
    { name: '호실 설정', route: '/room', siteFilter: true },
    { name: '지점 설정', route: '/site' },
    { name: '요기요 연동 상태', route: '/yogiyo-restaurant' },
    { name: '배달 권역', route: '/delivery-area' },
    { name: '지점별 법정동', route: '/site-dong-db' },
    { name: '호실 로그', route: '/log-order/0/0' },
    { name: 'POS 기기', route: '/pos-active-device', siteFilter: true },
    { name: 'divider' },
    { name: '주문 입력', route: '/manual-order' },
    { name: 'POS 공지', route: '/notice' },
    // { name: 'POS 공지(사용중지예정)', route: '/simple-notice' },
    { name: '연락처', route: '/contact' },
    { name: 'divider' },
    { name: '배민 업소(사용자)', route: '/baemin-user-detail-shop-v8', siteFilter: true },
    { name: '배민 업소(CEO)', route: '/baemin-ceo-shops-open', siteFilter: true },
    { name: '배민 사장님정보', route: '/baemin-ceo-shop-owner-query', siteFilter: true },
    { name: '배민 배달 지역 백업/복구', route: '/baemin-ceo-delivery-region' },
    { name: '배민 리뷰', route: '/baemin-review', siteFilter: true },
    { name: '배민 광고 현황', route: '/baemin-operating-ad-campaign', siteFilter: true },
    { name: '배민 광고 지도', route: '/baemin-campaign-map/0/0' },
    { name: '배민 메뉴', route: '/baemin-menu/gk-kangnam' },
    { name: '배민 매니저', route: '/baemin-ceo-manager', siteFilter: true },
    { name: 'divider' },
    { name: '문의완료 SMS', route: '/reply-sms' },
    { name: 'divider' },
    { name: '프린터', route: '/printer' },
    { name: 'divider' },
    { name: '최근 주문', route: '/order-action' },
    { name: '현장 주문', route: '/staging-order', siteFilter: true },
    { name: '배차 선별', route: '/cherrypick-delivery', siteFilter: true, showSelfDeliveryFilter: true },
    { name: 'divider' },
    { name: '고키런 관제', route: '/ghokirun-delivery', siteFilter: true },
    { name: '고키런 조회', route: '/ghokirun-delivery-range', siteFilter: true },
    { name: '고키런 라이더', route: '/ghokirun-rider' },
    // { name: 'divider' },
    // {name: '로그인', route: '/auth/login'},
  ];

  private currentSites: string[] = [];
  private destroySignal = new Subject<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private accountService: AccountService,
    private utilService: UtilService,
    private messageService: MessageService,
    private logService: LogService,
    private vroongPosOptionsPickupService: VroongPosOptionsPickupService,
    private soundService: SoundService,
    private versionService: VersionService,
    private userService: UserService,
    private ipService: IpService,
    private siteService: SiteService,
    private roomService: RoomService,
    private localConfigurationService: LocalConfigurationService,
    private unifiedMenuService: UnifiedMenuService,
    private dialogSendSmsService: DialogSendSmsService,
    private ghokirunRiderService: GhokirunRiderService,
  ) {
    this.isDev = environment.firebase?.projectId !== 'toe-prod';

    // 혹시 걸리는 것이 있을까?
    // refer: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
    window.addEventListener('error', (event: Event) => {
      this.logService.withToastrError(`에러(error)가 발생했습니다: ${JSON.stringify(event)}`, 'onerror');
    });

    // 모든 URL의 변화를 감시한다.
    // urlAfterRedirects: "/unified-order-issue"
    // urlAfterRedirects: "/unified-order-issue?sites=&hideSelfDelivery=false"
    // urlAfterRedirects: "/baemin-campaign-map/gk-kangnam-02/13024683"
    // urlAfterRedirects: "/baemin-campaign-map/gk-kangnam-02/13024683?sites=&hideSelfDelivery=false"
    const re = /^(\/[^/?]+)([^?]*)(?:\?(.+))?$/;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.dir(router.routerState);
        console.log(`urlAfterRedirects = ${event.urlAfterRedirects}`);

        // [ '/baemin-campaign-map/gk-kangnam-02/13024683?sites=&hideSelfDelivery=false',
        // '/baemin-campaign-map',
        // '/gk-kangnam-02/13024683',
        // 'sites=&hideSelfDelivery=false',
        // index: 0,
        // input: '/baemin-campaign-map/gk-kangnam-02/13024683?sites=&hideSelfDelivery=false',
        // groups: undefined ]
        const matches = re.exec(event.urlAfterRedirects);

        if (matches) {
          const prefix = matches[1]; // '/baemin-campaign-map',
          // const suffix = matches[2]; // '
          // const queryString = matches[3]; // 'sites=&hideSelfDelivery=false',

          // 서로 시작 URL(prefix)이 같은지 비교핸다.
          const foundNavItem = this.navItems.find(navItem => {
            if (navItem.route) {
              const prefixMatches = navItem.route.match(/^(\/[^/?]+)/);
              if (prefixMatches) {
                const routePrefix = prefixMatches[1];
                return prefix === routePrefix;
              }
            }
            return false;
          });

          if (foundNavItem) {
            // 1. URL에 맞추어 타이틀을 수정한다.
            this.currentNavItemName = foundNavItem.name;
            document.title = foundNavItem.name;

            // 2. UI 요소를 보여줄 지 말 지를 결정한다.
            this.showSitesFilter = foundNavItem.siteFilter === true;
            this.showSelfDeliveryFilter = foundNavItem.showSelfDeliveryFilter === true;

            // 3. QueryString이 있다면 URL에서 상태 복원
            if (foundNavItem.siteFilter === true) {
              const sitesParams = this.route.snapshot.queryParamMap.get('sites');
              if (sitesParams) {
                this.currentSites = sitesParams.split(',');
                this.siteControl.setValue(this.currentSites);
                this.localConfigurationService.siteSubject.next(this.currentSites);
              }
            }
            if (foundNavItem.showSelfDeliveryFilter === true) {
              const hideSelfDeliveryParams = this.route.snapshot.queryParamMap.get('hideSelfDelivery');
              if (hideSelfDeliveryParams) {
                const hideSelfDelivery = hideSelfDeliveryParams === 'true';
                this.hideSelfDeliveryControl.setValue(hideSelfDelivery);
                this.localConfigurationService.hideSelfDeliverySubject.next(hideSelfDelivery);
              }
            }

            // 4. QueryString 없이 이동하는 경우도 있기 때문에 상태로 URL 복원
            if (foundNavItem.siteFilter || foundNavItem.showSelfDeliveryFilter) {
              this.updateURL();
            }
          } else {
            console.error('메뉴에 없는 URL입니다.');
          }
        } else {
          console.error(`Unexpected URL: ${event.urlAfterRedirects}`);
        }
      }
    });
  }

  ngOnInit() {
    this.authService.observeLoggedIn().pipe(
      // 안정화되지 않은 상태인 null 을 제거하기 위함이다.
      filter(value => {
        return value === true || value === false;
      }),
      takeUntil(this.destroySignal)
    ).subscribe(async value => {
      console.log(`@AppComponent loggedIn = ${value}`);
      this.loggedIn = value;
      if (value === true) {
        this.utilService.toastrInfo('로그인 성공!', null, 3000);
        this.email = this.authService.user.email;

        let localAddresses = 'unknown';
        try {
          localAddresses = (await this.ipService.findLocalAddress()).join();
        } catch (err) {
          console.error('Fail to local IP address', err);
        }
        const publicAddress = await this.ipService.findPublicAddress();

        console.log(`localAddress = ${localAddresses}`);
        console.log(`publicAddress = ${publicAddress}`);
        this.logService.info(`로그인 성공. local = ${localAddresses}, remote = ${publicAddress}`, this.email);

        // 아래 3가지는 init-guard의 조건이기도 하다.
        // 여기에서 시작을 하고 init-guard에서는 데이터가 수신 완료되는 것을 기다린다.
        // TODO: 로그아웃 후에 기존의 데이터를 초기화하도록 확인한다.
        this.userService.observe(this.email);
        this.userService.latestUserSubject
          .pipe(takeUntil(this.destroySignal)).subscribe(user => {
            if (user) {
              if (!['admin', 'operator', 'viewer'].includes(user.role)) {
                this.logService.withToastrError(`현재 사용자(${user.role})는 권한이 없습니다. 로그아웃합니다.`);
                this.logout();
              }
              if (user.organization !== 'ghostkitchen') {
                this.title = `[${user.organization}] 발가락 OMC`;
              }
            }
          });

        // await promiseForInit()를 실행한 경우에 UI의 반응이 떨어지는 듯하여 아래와 같이 변경
        // 정밀한 분석 필요
        while (this.userService.user === undefined) {
          debugLog('Waiting for the first sync with Firestore user');
          await sleep(200);
        }
        // message에 기록을 남긴다.
        this.messageService.notificationLogin(this.email, {
          localIPs: localAddresses ? localAddresses : 'N/A',
          publicIP: publicAddress ?? 'N/A'
        });

        this.accountService.observeAccounts();

        this.messageService.observeMessage();

        this.vroongPosOptionsPickupService.observeVroongPosOptionsPickup();

        this.siteService.observe();
        this.roomService.observe();

        this.unifiedMenuService.startObserving();

        this.ghokirunRiderService.observeRider();
      }

      // subscribe로 변경하면서 아래의 문제가 저절로 해결된다.
      // refer: https://stackoverflow.com/questions/35105374/how-to-force-a-components-re-rendering-in-angular-2
      // LoginComponent가 붙을 경우에 별다른 일을 하지 않으니 AppComponent의 뷰가 갱신되지 않았다.
      // this.changeDetectorRef.detectChanges();
    });

    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;
    this.versionService.latestVersionSubject
      .pipe(takeUntil(this.destroySignal)).subscribe(lastesVersion => {
        this.latestVersion = lastesVersion;
      });

    this.siteService.latestSubject
      .pipe(takeUntil(this.destroySignal)).subscribe((sites: SiteDocs) => {
        this.sites = Object.values(sites).sort((a, b) => a.siteNo < b.siteNo ? -1 : 1).map(site => ({
          _id: site._id,
          siteName: trimOrganization(site.siteName)
        }));
      });

    this.roomService.latestSelfDeliveryRoomKeysSubject
      .pipe(takeUntil(this.destroySignal)).subscribe(selfDeliveryRoomKeys => {
        this.numSelfDeliveries = selfDeliveryRoomKeys.length;
        this.selfDeliveryRooms = selfDeliveryRoomKeys.map(roomKey => {
          return `[${trimOrganization(this.roomService.rooms[roomKey]?.name)}] ${this.roomService.rooms[roomKey]?.shopName}`;
        }).join('\n');
      });
  }

  ngOnDestroy() {
    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  /**
   * sites, hideSelfDelivery 값으로부터 URL을 만든다.
   */
  updateURL() {
    const currentPage = this.router.url.split('?')[0];

    const sites = this.siteControl.value as string[];
    const hideSelfDelivery = this.hideSelfDeliveryControl.value as boolean;

    // 동일 URL이라면 여러 번 반복해도 이벤트는 한 번만 발생한다.
    this.router.navigate([currentPage], { queryParams: { sites: sites.join(','), hideSelfDelivery: hideSelfDelivery ? 'true' : 'false' } });
  }

  /**
   * valueChanges를 이용하면 목록 선택이 끝났을 때가 아니라 토글이 일어날 때마다 호출된다.
   * 토글이 닫혔을 때의 상태로 조회할 조건을 확정한다.
   */
  public siteControlOpenedChange(event: boolean) {
    // opened상태를 boolean 값을 파라미터로 받는다. (false: 닫힘 발생)
    if (!event) {
      const sites = this.siteControl.value;
      this.localConfigurationService.siteSubject.next(sites);
      this.updateURL();
    }
  }

  public onSelfDeliveryChange(event: MatCheckboxChange) {
    this.localConfigurationService.hideSelfDeliverySubject.next(event.checked);
    this.updateURL();
  }

  get role() {
    return this.userService.user ? roleMappings[this.userService.user.role] : '...';
  }

  logout() {
    this.logService.info('로그아웃');

    this.authService.signOut().then(value => {
      this.messageService.notificationLogout();
      this.router.navigate(['/auth/login']);

      // 로그아웃을 하면 기존 상태도 초기화해야 한다.
      // 여러 서비스가 갖고 있는 상태를 초기화하기 위해서 일단 reload()한다.
      // TODO : 나중에는 상태를 정리한다 리로드 할 경우에 토스트 메시지가 사라지는 문제가 있다.
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  }

  playSound() {
    this.soundService.playDefault();
  }

  stopSound() {
    this.soundService.stop();
  }

  reload() {
    window.location.reload();
  }

  openDialogSendSms() {
    this.dialogSendSmsService.openDialog({
      telNo: ''
    });
  }

}
