/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { UnifiedOrder } from '../../schema/3/schema';

import { DialogCancelOrderComponent } from './dialog-cancel-order.component';

@Injectable({
  providedIn: 'root'
})
export class DialogCancelOrderService {

  constructor(
    private dialog: MatDialog
  ) { }

  openCancelOrderDialog(order: UnifiedOrder): MatDialogRef<DialogCancelOrderComponent, boolean> {
    // 다른 다일로그는 닫는다.
    // this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogCancelOrderComponent, {
      // 다이얼로그를 열 대 data를 공급해 준다.
      data: {
        order
      }
    });

    return dialogRef;
  }
}
