<ag-grid-angular
  class="ag-theme-balham"
  [modules]="modules"
  [animateRows]="true"
  [immutableData]="true"
  [getRowNodeId]="getRowNodeId"
  [rowData]="rowData"

  [tooltipMouseTrack]="true"
  [tooltipShowDelay]="400"

  [defaultColDef]="defaultColDef"
  [columnDefs]="columnDefs"

  rowSelection="multiple"
></ag-grid-angular>