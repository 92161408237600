<!-- 드래그가 가능하도록 옵션을 추가. cdkDragRootElement으로 드래그가 가능한 root 영역을 지정한다. -->
<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <h2>주문 처리 이력 (orderDate 기준 10분 전부터 3시간 이후까지의 'room' 로그도 포함)</h2>
  <p>주문시각: {{ orderDate }} (주문ID: {{order._id}})</p>
</div>
<ag-grid-angular
  class="ag-theme-balham"
  [modules]="modules"

  [rowData]="rowData"

  [defaultColDef]="defaultColDef"
  [columnDefs]="columnDefs"

  [tooltipMouseTrack]="true"
  [tooltipShowDelay]="400"
>
</ag-grid-angular>
<div class="buttons">
  <button mat-raised-button cdkFocusInitial color="primary" (click)="onCloseDialog()">닫기</button>
</div>
