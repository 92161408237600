/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Input } from '@angular/core';

import { UnifiedOrderDoc } from '../../schema/3/schema';

import { RoomService } from '../../core/4/room.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss', './menu.component.print.scss']
})
export class MenuComponent implements OnInit {
  @Input() isPrint = false;
  @Input() order: UnifiedOrderDoc;
  @Input() showPrice = true; // 주방용에서는 제외한다.

  paymentAmount = 0;

  constructor(
    private roomService: RoomService,
  ) { }

  ngOnInit() {
    const order = this.order;
    const [{ orderAmount = 0, deliveryTip = 0, discount = 0, eventDiscount = 0 } = {}] = [order];

    this.paymentAmount = orderAmount + deliveryTip - eventDiscount - discount;
  }
}
