/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { PrinterDoc } from '../../schema/3/schema';

import { dateFormatter } from '../../core/1/ag-util';
import { UtilService } from '../../core/1/util.service';
import { trimOrganization } from '../../core/2/util';
import { UserService } from '../../core/2/user.service';
import { LogService } from '../../core/3/log.service';
import { MessageService } from '../../core/5/message.service';
import { FirebaseManagerService } from '../../core/4/firebase-manager.service';
import { RoomService } from '../../core/4/room.service';
import { DialogSpinnerService } from '../dialog-spinner/dialog-spinner.service';

@Component({
  selector: 'app-dialog-print-order',
  templateUrl: './dialog-print-order.component.html',
  styleUrls: ['./dialog-print-order.component.scss']
})
export class DialogPrintOrderComponent implements OnInit, OnDestroy {
  public printers: PrinterDoc[];

  public roomName: string;
  public orderDate: string;

  public what: 'cook' | 'customer' | 'all' = 'all';
  public beepControl = new FormControl(false);
  public autoPrintControl = new FormControl(false);
  public whatControl = new FormControl('all');
  public printCookOptionControl = new FormControl('double');
  public selectedPrinter: PrinterDoc = null;

  private printerSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DialogPrintOrderComponent>,
    private firebaseManager: FirebaseManagerService,
    private userService: UserService,
    private roomService: RoomService,
    private dialogSpinnerService: DialogSpinnerService,
    private logService: LogService,
    private utilService: UtilService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.roomName = trimOrganization(this.roomService.rooms[this.data.order.room]?.name);
    this.orderDate = dateFormatter(this.data.order.orderDate, 'yyyy-MM-dd ccc HH:mm:ss');

    this.observePrinter();
  }

  ngOnDestroy() {
    this.printerSubscription?.unsubscribe();
  }

  public closeDialog() {
    this.dialogRef?.close(false);
    this.dialogRef = undefined;
  }

  public onCancel() {
    this.closeDialog();
  }

  public selectedPrinterChanged(event: MatSelectChange) {
    this.selectedPrinter = event.value;
  }

  public async requestPrintOrder() {
    const spinnerRef = this.dialogSpinnerService.openSpinnerDialog('응답 대기 중');
    try {
      const proxyInstanceNo = this.selectedPrinter.proxyInstanceNo;
      const orderId = this.data.order._id;
      const printerKey = this.selectedPrinter._id;
      const what = this.whatControl.value;
      const printCookOption = this.printCookOptionControl.value;
      const beep = this.beepControl.value as boolean;
      const autoPrint = this.autoPrintControl.value as boolean;

      await this.messageService.requestPrintOrder(proxyInstanceNo, orderId, printerKey, what, printCookOption, beep, autoPrint);
      this.utilService.toastrInfo(`${printerKey}로 인쇄 요청 완료.`);
    } catch (error) {
      this.logService.withToastrCatch(error, 'requestPrintOrder에서 예외발생.');
    }
    spinnerRef.close();
  }

  private observePrinter() {
    const collectionPath = 'printer';
    const { organization } = this.userService;

    this.printerSubscription = this.firebaseManager
      .observe<PrinterDoc>(collectionPath, [['organization', '==', organization]])
      .subscribe(printers => {
        // proxy만 사용
        this.printers = printers.filter(v => v.connectionType === 'proxy').sort((a, b) => a._id > b._id ? 1 : -1);
        const defaultPrinterKey = this.roomService.rooms[this.data.order.room]?.printer;
        const defaultPrinter = this.printers.find(printer => printer._id === defaultPrinterKey);

        this.selectedPrinter = defaultPrinter ?? this.printers[0];
      },
      console.error
    );
  }
}
