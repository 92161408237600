/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import Swal from 'sweetalert2';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

import { UnifiedOrderDoc, UnifiedOrderStatusCode, UnifiedOrderContextStatusCode } from '../../schema/3/schema';

import { UtilService } from '../../core/1/util.service';
import { ClipboardService } from '../../core/1/clipboard.service';
import { unifiedOrderStatusMappings, unifiedOrderDeliveryTypeMappings } from '../../core/1/string-map';
import { trimOrganization, weekdayKR, channelVendorDeliveryType } from '../../core/2/util';
import { UserService } from '../../core/2/user.service';
import { LogService } from '../../core/3/log.service';
import { FirebaseManagerService } from '../../core/4/firebase-manager.service';
import { RoomService } from '../../core/4/room.service';
import { DeliveryUtilService } from '../../core/5/delivery-util.service';

import { DialogCancelOrderService } from '../dialog-cancel-order/dialog-cancel-order.service';

@Component({
  selector: 'app-manual-order-action',
  templateUrl: './manual-order-action.component.html',
  styleUrls: ['./order-action.component.scss']
})
export class ManualOrderActionComponent implements OnInit, OnDestroy {
  unifiedDeliveryTypeMappings = unifiedOrderDeliveryTypeMappings;
  trimOrganization = trimOrganization;
  weekdayKR = weekdayKR;

  @Input() order: UnifiedOrderDoc;
  @Input() isDialog = false;
  @Output() actionDone = new EventEmitter<boolean>();

  channel: string;
  statusString = '...';

  buttonPressed = {
    ACCEPT: false,
    COMPLETE: false,
    CANCEL: false,
    DELETE: false,
    STAGING: false,
  };

  // 오늘 주문이냐?
  isTodayOrder = true;

  cssClass = {};

  // TODO: 나중에는 지역별 설정과 연동
  deliveryMinutes = 40;
  // delivery-util.service.ts와 맞춘다.
  deliveryMinutesOptions = [30, 40, 50, 60, 70, 80, 90, 100];

  constructor(
    public roomService: RoomService,
    private dialogCancelOrderService: DialogCancelOrderService,
    private clipboardService: ClipboardService,
    private logService: LogService,
    private utilService: UtilService,
    public userService: UserService,
    private deliveryUtilService: DeliveryUtilService,
    private firebaseManager: FirebaseManagerService,
  ) { }

  ngOnInit() {
    this.orderUpdated();
  }

  ngOnDestroy() {
  }

  orderUpdated() {
    this.updateView();
  }

  updateView() {
    this.cssClass = {
      // 주문 접수는 카드에 옵션을 함께 표시하므로 공간이 더 필요하다.
      newOrder: (this.order.contextStatusCode === UnifiedOrderContextStatusCode.NEW),

      // TODO: 일단은 ACCEPTED 전은 모두 NEW로 처리한다. 나중에는 변경될 수도 있다.
      // tslint:disable-next-line: max-line-length
      NEW: ([UnifiedOrderContextStatusCode.NEW, UnifiedOrderContextStatusCode.CEOACCEPTED, UnifiedOrderContextStatusCode.STAGING].includes(this.order.contextStatusCode)),
      ACCEPTED: this.order.contextStatusCode === UnifiedOrderContextStatusCode.ACCEPTED,
      COOKED: this.order.contextStatusCode === UnifiedOrderContextStatusCode.COOKED,
      PICKEDUP: this.order.contextStatusCode === UnifiedOrderContextStatusCode.PICKEDUP,
      COMPLETED: this.order.contextStatusCode === UnifiedOrderContextStatusCode.COMPLETED,
      CANCELED: this.order.contextStatusCode === UnifiedOrderContextStatusCode.CANCELED,
      DELETED: this.order.contextStatusCode === UnifiedOrderContextStatusCode.DELETED
    };

    // Safari는 +0900 형식의 timezone을 인식하지 못하는 문제가 있어서 new Date(this.order.orderDate)를 사용하지 못한다.
    const orderDate = format(parseISO(this.order.orderDate), 'yyyy-MM-dd');
    const todayDate = format(new Date(), 'yyyy-MM-dd');
    this.isTodayOrder = orderDate === todayDate;
    this.channel = channelVendorDeliveryType(this.order);
    this.statusString = unifiedOrderStatusMappings[this.order.contextStatusCode];

    this.deliveryMinutes = this.order.posDeliveryMinutes ?? 40;
    this.deliveryMinutesOptions = this.deliveryUtilService.calculateDeliveryParams(this.order).deliveryMinutesOptions;
  }

  async onAction(mainActionName: string) {
    if (this.buttonPressed[mainActionName] === true) {
      console.log('이미 눌렸으니 통과');
      return;
    }

    try {
      switch (mainActionName) {
        case 'ACCEPT':
          {
            this.buttonPressed[mainActionName] = true;

            try {
              await this.firebaseManager.setDoc('unifiedOrder', this.order._id, {
                deliveryMinutes: this.deliveryMinutes,
                orderStatusCode: UnifiedOrderStatusCode.ACCEPTED,
                contextStatusCode: UnifiedOrderContextStatusCode.ACCEPTED
              });
              this.logService.logOrder(this.order, `'주문 접수' 버튼을 눌러서 주문접수(ACCEPTED) 주문으로 상태를 변경했습니다.`);
            } catch (error) {
              this.logService.logOrder(this.order, `'주문 접수' 버튼을 눌러서 주문접수(ACCEPTED) 주문으로 상태를 변경하는 과정에서 예외가 발생했습니다.\n${JSON.stringify(error)}`, 'error');
            }
            this.actionDone.emit(true);
          }
          break;

        case 'COMPLETE':
          {
            this.buttonPressed[mainActionName] = true;

            try {
              await this.firebaseManager.setDoc('unifiedOrder', this.order._id, {
                orderStatusCode: UnifiedOrderStatusCode.COMPLETED,
                contextStatusCode: UnifiedOrderContextStatusCode.COMPLETED
              });
              this.logService.logOrder(this.order, `'배달 완료' 버튼을 눌러서 배송완료(COMPLETED) 주문으로 상태를 변경했습니다.`);
            } catch (error) {
              this.logService.logOrder(this.order, `'배달 완료' 버튼을 눌러서 배송완료(COMPLETED) 주문으로 상태를 변경하는 과정에서 예외가 발생했습니다.\n${JSON.stringify(error)}`, 'error');
            }
            this.actionDone.emit(true);
          }
          break;
        case 'CANCEL':
          this.dialogCancelOrderService.openCancelOrderDialog(this.order);
          break;

        case 'DELETE':
          const { isConfirmed } = await Swal.fire({
            title: '주문을 삭제하시겠습니까?',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: '취소',
            confirmButtonText: '확인'
          });
          if (isConfirmed) {
            try {
              await this.firebaseManager.setDoc('unifiedOrder', this.order._id, {
                contextStatusCode: UnifiedOrderStatusCode.DELETED
              });
              this.logService.logOrder(this.order, `'주문 삭제' 버튼을 눌러서 삭제(DELETED) 주문으로 상태를 변경했습니다.`);
            } catch (error) {
              this.logService.logOrder(this.order, `'주문 삭제' 버튼을 눌러서 삭제(DELETED) 주문으로 상태를 변경하는 과정에서 예외가 발생했습니다.\n${JSON.stringify(error)}`, 'error');
            }
            this.actionDone.emit(true);
          }
          break;

        case 'STAGING':
          try {
            await this.firebaseManager.setDoc('unifiedOrder', this.order._id, {
              orderStatusCode: UnifiedOrderStatusCode.STAGING,
              contextStatusCode: UnifiedOrderStatusCode.STAGING
            });
            this.logService.logOrder(this.order, `'결제 대기' 버튼을 눌러서 결제대기(STAGING) 주문으로 상태를 변경했습니다.`);
          } catch (error) {
            this.logService.logOrder(this.order, `'결제 대기' 버튼을 눌러서 결제대기(STAGING) 주문으로 상태를 변경하는 과정에서 예외가 발생했습니다.\n${JSON.stringify(error)}`, 'error');
          }
          this.actionDone.emit(true);
          break;

        case 'CANCEL_CONFIRMED':
          try {
            await this.firebaseManager.setDoc('unifiedOrder', this.order._id, {
              cancelConfirmed: true,
            });
            this.logService.logOrder(this.order, `'취소 사유 확인' 버튼을 눌러서 cancelConfirmed를 true로 변경했습니다.`);
          } catch (error) {
            this.logService.logOrder(this.order, `'취소 사유 확인' 버튼을 눌러서 cancelConfirmed를 true로 변경하는 과정에서 예외가 발생했습니다.\n${JSON.stringify(error)}`, 'error');
          }
          this.actionDone.emit(true);
          break;
        default:
          this.logService.logOrder(this.order, `예기치 않은 액션을 시도했습니다.\nAction: ${mainActionName}`, 'error');
          this.utilService.toastrError('내부 에러', `예기치 않은 액션을 실행했습니다. ${mainActionName}`);
          break;
      }
    } catch (error) {
      this.logService.logOrder(this.order, `매뉴얼 주문 onAction에서 예외가 발생했습니다.\n${JSON.stringify(error)}`, 'error');
      this.utilService.toastrCatch(error, '매뉴얼 주문 onAction에서 예외가 발생했습니다.');
    }
  }

  copyToClipboard(event: MouseEvent, text: string = null) {
    const el = event.target as HTMLElement;
    // nested element의 경우에 상위에서 복사가 또 발생하는 것을 막는다.
    event.stopPropagation();

    if (text) {
      this.clipboardService.copyTextToClipboard(text, el);
    } else {
      this.clipboardService.copyTextToClipboard(el.innerText, el);
    }
  }

  onChangeDeliveryExpTime(event: MatRadioChange) {
    this.deliveryMinutes = event.value;
  }

  onOk() {
    // 취할 액션이 없이 창만 닫을 경우에는 false
    this.actionDone.emit(false);
  }
}
